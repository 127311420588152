














































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {max, maxLength, min, required} from '@/utils/validation';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {buildEmptyUploadRun, IKvkUploadRun, monthOptions} from '@/models/IKvkUploadRun';
import DateInput from '@/components/DateInput.vue';
import KvkUploadRunStepper from '@/views/kvk-upload-run/KvkUploadRunStepper.vue';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';

@Component({
    filters: {},
    components: {
        KvkUploadRunStepper,
        BaseEditComponent,
        SimpleMessageWrapper,
        DateInput,
    },
})
export default class KvkUploadRunNew extends Mixins(ShowsMessages, AuthorizationMixin) {
    public months: Array<{ label: string, value: number }> = monthOptions;

    private uploadRun: IKvkUploadRun = buildEmptyUploadRun();
    private loading = false;

    private registers: IRegister[] = [];

    public get canSave() {
        return this.isAdmin;
    }

    public get monthOptions() {
        return this.months;
    }

    public get rules() {
        return {
            file: [required],
            fileType: [required],
            year: [min(1900), max(2100)],
            register: [required],
        };
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.uploadRun.file != null && result) {
            this.loading = true;
            try {
                this.showMessage('Het bestand wordt geüpload. Je wordt hierna doorgestuurd naar de volgende stap.');
                const response = await this.$api.kvkUploadRun.import(this.uploadRun!);
                const uploadRunId = response.data!.id;
                this.uploadRun.file = null;
                await setTimeout(() => {
                    this.uploadRun = buildEmptyUploadRun();
                    this.$router.push({name: 'kvk_upload_run_view', params: {id: uploadRunId as string}});
                }, 2500);
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loading = false;
            }
        }
    }

    public async beforeMount(): Promise<void> {
        const response = await this.$api.register.list(new Pagination(0, -1), []);
        if (response.success) {
            this.registers = response.data!.content;
        }
    }
}
