












































































































import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {GenericApi} from '@/api/generic';
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import {dateTime, date} from '@/utils/filters';
import {Idable} from '@/models/Idable';

@Component({
    filters: {
        dateTime,
        date,
    },
    components: {
        SimpleMessageWrapper,
        AutoLoadingButton,
        SimpleDeleteDialog,
    },
})
export default class ConstantListBaseComponent extends Mixins(ListsObjects, AuthorizationMixin) {
    @Prop()
    public listHeaders!: any[];

    @Prop()
    public apiPath!: GenericApi<Idable>;

    public drawer: boolean = true;

    public get api() {
        return this.apiPath;
    }

    public getCreateRoute() {
        return {name: this.$route.meta.indexRouteName + 'Create'};
    }

    public getEditRoute(id: number | string) {
        return {
            name: this.$route.meta.indexRouteName + 'Edit',
            params: {id},
        };
    }
}
