import {Idable} from '@/models/Idable';
import {buildEmptyContact, IContact} from '@/models/IContact';
import {IRegister} from '@/models/IRegister';
import {IGemeente} from '@/models/constants/IGemeente';
import {IBuurt} from '@/models/constants/IBuurt';
import {IBedrijventerrein} from '@/models/constants/IBedrijventerrein';
import {IPlaatsnaam} from '@/models/constants/IPlaatsnaam';

export interface ICb extends Idable {
    naam: string;
    cbNummer: string;
    straat: string|null;
    huisnummer: number|null;
    huisletter: string|null;
    toevoeging: string|null;
    postcode: string|null;
    gemeente: IGemeente|null;
    buurt: IBuurt|null;
    bedrijventerrein: IBedrijventerrein|null;
    plaatsnaam: IPlaatsnaam|null;
    contact: IContact | null;
    registers: IRegister[];
}

export function buildEmptyCb(): ICb {
    const cb = {
        id: '',
        naam: '',
        cbNummer: '',
        straat: null,
        huisnummer: null,
        huisletter: null,
        toevoeging: null,
        postcode: null,
        gemeente: null,
        bedrijventerrein: null,
        buurt: null,
        plaatsnaam: null,
        contact: buildEmptyContact(),
        registers: [],
    } as unknown as ICb;
    return cb;
}
