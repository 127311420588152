import {Idable} from '@/models/Idable';
import {IRegister} from '@/models/IRegister';

export interface IMeetperiode extends Idable {
    naam: string;
    peildatumStartOfDay: number;
    peildatumEndOfDay: number;
    bevrorenOp: null|number;
    totalWerknemerstats: number;
    processedWerknemerstats: number;
    inProgress: boolean;
    dateExistingBevroren: number;
    lastFrozenRegister: IRegister|null;
    createWerknemerStatsInProgress: boolean;
    totalVestigingenToProcess: number;
    processedVestigingen: number;
}

export function buildEmptyMeetperiode(): IMeetperiode {
    return {
        naam: null,
        peildatumStartOfDay: null,
        peildatumEndOfDay: null,
        bevrorenOp: null,
    } as unknown as IMeetperiode;
}
