













































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';
import {date, dateTime, uploadStatusText} from '@/utils/filters';

@Component({
})
export default class KvkUploadSUmmary extends Vue {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;
    public statusBeforeReload: KvkUploadStatus | null = null;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun | null;

    @Watch('kvkUploadRun.status', {deep: true, immediate: true})
    public onUploadRunChange() {
        const status = this.kvkUploadRun!.status;
        const inProgress = this.isInProgressStatus(status);
        if (inProgress) {
            this.statusBeforeReload = this.kvkUploadRun!.status;
            setTimeout(() => this.$emit('reload'), 2000);
        } else if (this.isInProgressStatus(this.statusBeforeReload)) {
            this.statusBeforeReload = this.kvkUploadRun!.status;
            window.location.reload();
        }
    }

    public get status() {
        return this.kvkUploadRun!.status;
    }

    public get statusText() {
        return uploadStatusText(this.kvkUploadRun!.status);
    }

    public get processingLabel() {
        if (this.status <= KvkUploadStatus.UPLOAD_DONE) {
            return 'Rijen verwerkt';
        }
        if (this.status <= KvkUploadStatus.CONFIRM_UPLOAD_DONE) {
            return 'Rijen verwerkt';
        }
        if (this.status <= KvkUploadStatus.MUTATIONS_DONE) {
            return 'Mutaties verwerkt';
        }
        if (this.status <= KvkUploadStatus.AUTO_NEW_DONE) {
            return 'Inschrijvingen verwerkt';
        }
        return 'Handmatig verwerkt';
    }

    public get uploadStartDate() {
        const startedOn = this.kvkUploadRun!.runProgressMap!.upload!.startedOn;
        return startedOn ? dateTime(startedOn) : null;
    }

    public get datumMutaties() {
        return this.kvkUploadRun!.datumMutaties ? date(this.kvkUploadRun!.datumMutaties) : null;
    }

    public isInProgressStatus(status: KvkUploadStatus | null) {
        if (status) {
            return status === KvkUploadStatus.AUTO_NEW_IN_PROGRESS
                || status === KvkUploadStatus.UPLOAD_IN_PROGRESS
                || status === KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS
                || status === KvkUploadStatus.MUTATIONS_IN_PROGRESS
                || status === KvkUploadStatus.AUTO_NEW_PREPARE_IN_PROGRESS
                || status === KvkUploadStatus.NEW_LINK_IN_PROGRESS;
        }
        return false;
    }

    public get currentRunProgress() {
        return this.kvkUploadRun!.currentRunProgress;
    }

    public get runProgressForStep() {
        switch (this.kvkUploadRun!.selectedStep) {
            // case KvkStep.UPLOAD:
            //     if (this.status >= KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS) {
            //         return this.kvkUploadRun?.runProgressMap.confirm;
            //     }
            //     return this.kvkUploadRun?.runProgressMap.upload;
            // case KvkStep.MUTATIONS:
            //     if (this.status < KvkUploadStatus.MUTATIONS_IN_PROGRESS) {
            //         return this.kvkUploadRun?.runProgressMap.confirm;
            //     }
            //     return this.kvkUploadRun?.runProgressMap.mutate;
            case KvkStep.NEW:
                return this.kvkUploadRun?.runProgressMap.link;
            case KvkStep.MANUAL_CHECK:
                return this.kvkUploadRun?.runProgressMap.manual_check;

        }
        return this.kvkUploadRun!.currentRunProgress;
    }

    public get getProgress() {
        const runProgress = this.runProgressForStep;

        const total = runProgress!.totalCount; // Math.max(runProgress!.totalCount, this.kvkUploadRun!.totalData);
        const processed = runProgress!.processedCount ? runProgress!.processedCount : 0;
        let percentage = total ? Math.round(processed / total * 10000) / 100 : 0;
        if (processed === 0 && total === 0) {
            return '-';
        }
        if (percentage > 100) {
            console.warn('Percentage exceeded 100%. This might indicate that some rows had warnings ' +
                'and were repeated. Actual percentage =', percentage);
            percentage = 100;
        }
        return processed + '/' + total + ' (' + percentage + '%)';
    }
}
