import { Idable } from '@/models/Idable';

export interface IBagImportRun extends Idable {
    filename: string;
    adresRows: number;
    adresRowsProcessed: number;
    insertRows: number;
    insertRowsProcessed: number;
    status: BagImportStatus;

    filenames: string[];
    currentFilename: string;

    // non-server side; extra helper-property
    selectedStep: BagStep;
}

export enum BagStep {
    UPLOAD = 1,
    PARSING = 2,
    INSERT = 3,
    UPDATE = 4,
}

export enum BagImportStatus {
    CANCELED = -1,
    UPLOAD_DONE = 0,
    PARSING_IN_PROGRESS = 10,
    PARSING_DONE = 20,
    INSERT_IN_PROGRESS = 30,
    INSERT_DONE = 40,
    POST_PROCESS_DONE = 50,
    UPDATE_ADRES_IN_PROGRESS = 60,
    UPDATE_ADRES_DONE = 70,
    DONE = 100,
}
