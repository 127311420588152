


























































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {Idable} from '@/models/Idable';
import {IVestiging} from '@/models/IVestiging';
import {required} from '@/utils/validation';
import {IOverheidsCode} from '@/models/constants/IOverheidsCode';

@Component({
    filters: {},
    components: {},
})
export default class OverigeInfo extends Vue {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({required: false, default: 0})
    public validationErrorsCount!: number;

    public overheidsCodeOptions: IOverheidsCode[] = [];
    public uitzetCodeOptions: Idable[] = [];
    public rechtsvormOptions: Idable[] = [];

    public readonly OVERHEIDSCODE_ONBEKEND = '00';

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    public get rules() {
        return {
            overheidsCode: [required],
        };
    }

    public async created() {
        this.overheidsCodeOptions = (await this.$api.overheidsCode.listAll()).data?.content || [];
        this.rechtsvormOptions = (await this.$api.rechtsvorm.listAll()).data?.content || [];
        this.uitzetCodeOptions = (await this.$api.uitzetCode.listAll()).data?.content || [];
        if (!this.vestiging.overheidsCode) {
            const defaultOverheidsCode = this.overheidsCodeOptions.find(
                    (item) => item.code === this.OVERHEIDSCODE_ONBEKEND);
            if (defaultOverheidsCode) {
                this.vestiging.overheidsCode = defaultOverheidsCode;
            }
        }
    }
}
