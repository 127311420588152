






import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

@Component({
    filters: {},
    components: {},
})
export default class App extends Vue {
}
