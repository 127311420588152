import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, url} from '@/utils/http';
import {IMutatie} from '@/models/IMutatie';

export class MutatieApi extends GenericApi<IMutatie> {

    constructor() {
        super('/api/v1/mutatie');
    }

    public async getByVestiging(id: string | number) {
        const uri = new URL(url(this.path + `/vestiging/${id}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IMutatie[]>;
    }
}
