




















import { Component, Mixins } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/mixins/AuthorizationMixin';
import settings from '@/utils/settings';

@Component({
    filters: {},
    components: {},
})
export default class MfaEnableNag extends Mixins(AuthorizationMixin) {

    public showAlert: boolean = false;

    public created() {
        if (settings.MFA_ENABLE_WARNING) {
            this.showAlert = true;
        }
        setTimeout(() => { this.showAlert = false; }, 8000);
    }

}
