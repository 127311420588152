import {Component, Vue} from 'vue-property-decorator';

export interface ShowsMessagesDataObject {
    done: boolean;
    message: string;
    timeout: number;
    error: boolean;
}

@Component
export class ShowsMessages extends Vue {
    public messages: ShowsMessagesDataObject[] = [];
    public clearMessagesOnCreate: boolean = true;

    public created() {
        if (this.clearMessagesOnCreate) {
            this.clearMessages();
        }
    }

    public showMessage(message: string, timeout: number = -1) {
        this.addMessage(message, timeout, false);
    }

    public showRepeatableMessage(message: string, timeout: number = -1) {
        this.addMessage(message, timeout, false, true);
    }

    public showError(message: string, timeout: number = -1) {
        this.addMessage(message, timeout, true);
    }

    public addMessage(message: string, timeout: number = -1, error: boolean = false, repeatable: boolean = false) {
        const parent = this.findRefBase();
        if (!repeatable && parent.messages.some((m) => m.message === message && !m.done)) {
            return; // Do not add message if exact same message was added before and is yet to be displayed.
        }
        parent.messages.push({done: false, error, message, timeout});
    }

    public showErrorByException(e: any, fallbackMessageKey: string, timeout: number = -1) {
        if ((window as any).webpackHotUpdate) {
            // tslint:disable-next-line:no-console
            console.error(e);
        }
        let errorMessage = this.$t(fallbackMessageKey);
        if (e.response && e.response.data && e.response.status === 400) {
            if (typeof e.response.data.error === 'string' && e.response.data.error.indexOf(' ') === -1) {
                errorMessage = this.$t('validation.' + e.response.data.error);
            } else if (typeof e.response.data.error === 'object' && e.response.data.error.error) {
                errorMessage = this.$t('validation.' + e.response.data.error.error, e.response.data.error.arguments);
            } else if (typeof e.response.data.error === 'object') {
                errorMessage = '';
                for (const prop in e.response.data.error) {
                    if (e.response.data.error.hasOwnProperty(prop)) {
                        errorMessage += `<li>${prop}: ${e.response.data.error[prop]}</li>`;
                    }
                }
                // this.showError(prop + ': ' + e.response.data.error[prop], timeout);
                // return;
            }
        } else if (e.response && e.response.data && e.response.status === 403) {
            errorMessage = this.$t('validation.noAccess');
        } else if (e.response && e.response.data && e.response.status === 409) {
            errorMessage = this.$t('validation.dataIntegrity');
        }
        this.showError(errorMessage as string, timeout);
    }

    public clearMessages() {
        const parent = this.findRefBase();
        parent.messages = [];
    }

    private findRefBase(): ShowsMessages {
        let parent: ShowsMessages = this;
        while (parent != null && !parent.$refs.smw) {
            parent = parent.$parent as ShowsMessages;
        }

        if (parent == null) {
            // back to default ...
            parent = this;
        }

        return parent;
    }
}
