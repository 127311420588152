import {IPagination, Pagination, SortDirection} from '@/utils/api-tools/pagination';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {ISearchCriterion, SearchCriterion} from '@/utils/api-tools/search-criteria';
import {remove, put, get, post, mergeURLSearchParams, url} from '@/utils/http';

export interface IWrapperResponse<T> {
    success: boolean;
    data: T | null;
}

export class GenericApi<T> {
    public path: string;

    constructor(path: string) {
        this.path = path;
    }

    public async list(pagination: IPagination, search: ISearchCriterion[]):
            Promise<IWrapperResponse<IPaginationResponse<T>>> {
        const uri = new URL(url(this.path));
        const paginationParams = Pagination.serialize(pagination);
        const searchParams = search.reduce((prev: URLSearchParams, curr: ISearchCriterion) => {
            return mergeURLSearchParams(prev, SearchCriterion.serialize(curr));
        }, new URLSearchParams());
        mergeURLSearchParams(uri.searchParams, paginationParams, searchParams);

        const result = await get(uri.href);
        return result.data as IWrapperResponse<IPaginationResponse<T>>;
    }

    public async listAll(search: ISearchCriterion[] = [], sort: string = 'id', direction: SortDirection = 'DESC'):
        Promise<IWrapperResponse<IPaginationResponse<T>>> {
        return this.list(new Pagination(0, -1, [sort], [direction]), search);
    }

    public async get(id: string | number) {
        const uri = new URL(url(this.path + `/${id}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<T>;
    }

    public async save(model: T) {
        const uri = new URL(url(this.path + `/${(model as any).id}`));
        const result = await put(uri.href, model);
        return result.data as IWrapperResponse<T>;
    }

    public async create(model: T) {
        const uri = new URL(url(this.path));
        const result = await post(uri.href, model);
        return result.data as IWrapperResponse<T>;
    }

    public async delete(modelId: number| string) {
        const uri = new URL(url(this.path) + `/${modelId}`);
        const response = await remove(uri.href);
        return {success: true}  as IWrapperResponse<never>;
    }
}
