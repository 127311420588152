import {Idable} from '@/models/Idable';
import {buildAndFilter, IFilter} from '@/models/IFilter';
import {ExportJobStatus, ExportType} from '@/models/IExportRun';
import {IGebruiker} from '@/models/IGebruiker';

export interface IExportRunCreate extends Idable {
    description: string;
    fields: string[];
    filters: IFilter;
    type: ExportType;
    totalRecords: number;
    startedOn: number;
    filename: string;
    processedRecords: number;
    status: ExportJobStatus;
    isExcelExport: boolean;
    isTemplate: boolean;
    toegevoegdDoor: IGebruiker;
    explanation: string;
}

export function buildEmptyExport(): IExportRunCreate {
    return {
        description: '',
        fields: [],
        filters: buildAndFilter(),
        isExcelExport: false,
        isTemplate: false,
    } as unknown as IExportRunCreate;
}
