




































































































































































































































































































































































































































































































































import { Component, Mixins, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { required } from '@/utils/validation';
import { Idable } from '@/models/Idable';
import { IVestiging } from '@/models/IVestiging';
import DateInput from '@/components/DateInput.vue';
import {IKvk, IKvkBase, KvkStatus, kvkStatusOptions} from '@/models/IKvk';
import { convertToDate, formatDate } from '@/utils/filters';
import { IGemeente } from '@/models/constants/IGemeente';
import { ISbi } from '@/models/constants/ISbi';
import { IRechtsvorm } from '@/models/constants/IRechtsvorm';
import { IGrootteklasse } from '@/models/constants/IGrootteklasse';
import {IKvkCma} from '@/models/IKvkCma';

@Component({
    filters: {formatDate},
    components: {DateInput},
})
export default class KvkDetails extends Vue {
    @Model('input')
    public inputKvk!: IKvkBase;

    public showOpnemenDialog: boolean = false;

    public datumMutaties: number | null = new Date().getTime();

    public kvk: IKvkBase | null = null;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({type: Boolean, default: false})
    public isKvkView!: boolean;

    @Prop({type: Object, default: null})
    public vestiging!: IVestiging;

    public sbiOptions: Idable[] = [];

    public loading: boolean = false;

    public naamGemeente: string = '';
    public naamSbi: string = '';
    public naamRechsvorm: string = '';
    public naamGrootteklasse: string = '';
    public naamGrootteklasseFt: string = '';

    @Watch('vestiging')
    @Watch('inputKvk')
    public onChange() {
        this.loadData();
    }

    public get kvkCma(): IKvkCma|null {
        return this.kvk?.kvkCma || null;
    }

    public get isKvkMutatie() {
        return this.kvk?.hasOwnProperty('kvkId');
    }

    public get rules() {
        return {
            kvkDossiernummer: [(v: string) => !v || /^[0-9]{2}-?[0-9]{6}$/.test(v) || 'KvK dossiernummer moet voldoen aan formaat XX-XXXXXX'],
            naam: [required],
            straat: [required],
        };
    }

    public get getContactnaam() {
        return this.kvk?.kvkCma.tenaamstelling1x45;
    }

    public get getStartdatum() {
        return formatDate((this.kvk as IKvk).datumStart);
    }

    public get getSbiMutatieDatum() {
        const castKvk = (this.kvk as IKvk);
        if (castKvk && castKvk.datumSbi && castKvk.datumSbi > 0) {
            return convertToDate(castKvk.datumSbi).toISOString().split('T')[0];
        } else {
            return null;
        }
    }

    public get getNaamMutatieDatum() {
        const castKvk = (this.kvk as IKvk);
        if (castKvk && castKvk.datumNaam && castKvk.datumNaam > 0) {
            return convertToDate(castKvk.datumNaam).toISOString().split('T')[0];
        } else {
            return null;
        }
    }

    public get grootteklasseCode() {
        const code = this.getFieldValue('klasseWerkzamePersonenTotaal');
        if (!code) {
            return '';
        }
        return String(code).padStart(2, '0');
    }

    public get rechtsvormCode() {
        const code = this.getFieldValue('rechtsvormFijn');
        if (!code) {
            return '';
        }
        return String(code).padStart(2, '0');
    }

    public get grootteklasseFtCode() {
        const code = this.getFieldValue('klasseWerkzamePersonenFulltime');
        if (!code) {
            return '';
        }
        return String(code).padStart(2, '0');
    }

    public get gemeenteCode() {
        const gemeenteCode = this.getFieldValue('gemnr');
        if (!gemeenteCode) {
            return '';
        }
        return String(gemeenteCode).padStart(4, '0');
    }

    public async loadGemeenteNaam() {
        if (!this.gemeenteCode) {
            return '';
        } else {
            const gemeente = (await this.$api.gemeente.getByCode(this.gemeenteCode)).data;
            if (gemeente) {
                this.naamGemeente = (gemeente as IGemeente).omschrijving;
            } else {
                this.naamGemeente = '';
            }
        }
    }

    public async loadSbiNaam() {
        const sbiCode = this.getFieldValue('sbi08');
        if (!sbiCode) {
            return '-';
        } else {
            const sbi = (await this.$api.sbi.getByCode(sbiCode)).data;
            if (sbi) {
                this.naamSbi = sbiCode + ' - ' + (sbi as ISbi).omschrijving;
            } else {
                this.naamSbi = sbiCode + ' - ' + '(Onbekend)';
            }
        }
    }

    public async loadRechsvormNaam() {
        if (!this.rechtsvormCode) {
            return '-';
        } else {
            const result = (await this.$api.rechtsvorm.getByCode(this.rechtsvormCode)).data;
            if (result) {
                this.naamRechsvorm = (result as IRechtsvorm).omschrijving;
            } else {
                this.naamRechsvorm = '-';
            }
        }
    }

    public async loadGrootteklasseNaam() {
        if (!this.grootteklasseCode) {
            return '-';
        } else {
            const result = (await this.$api.grootteklasse.getByCode(this.grootteklasseCode)).data;
            if (result) {
                this.naamGrootteklasse = (result as IGrootteklasse).omschrijving;
            } else {
                this.naamGrootteklasse = '-';
            }
        }
    }

    public async loadGrootteklasseNaamFt() {
        if (!this.grootteklasseFtCode) {
            return '-';
        } else {
            const result = (await this.$api.grootteklasse.getByCode(this.grootteklasseFtCode)).data;
            if (result) {
                this.naamGrootteklasseFt = (result as IGrootteklasse).omschrijving;
            } else {
                this.naamGrootteklasseFt = '-';
            }
        }
    }

    public get getWpDatum() {
        if (this.getFieldValue('peildatumWerkzamePersonenVestiging')) {
            return this.formatKvkDate(this.getFieldValue('peildatumWerkzamePersonenVestiging'));
        } else {
            return null;
        }
    }

    public get getRsin() {
        const rsin = this.getFieldValue('rsin');
        if (!rsin) {
            return '-';
        } else {
            if (rsin.length >= 7) {
                return rsin.substr(0, 4) + '-' + rsin.substr(4, 2) + '-' + rsin.substr(6);
            }
        }
    }

    public formatKvkDate(date: string) {
        if (!date || date.length < 5) {
            return '-';
        }
        if (date.includes('-')) {
            // Date already formatted
            return date;
        }
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6);
        return [day, month, year].join('-');
    }

    public get getVerhuisDatum() {
        if (this.getFieldValue('datumVestigingHuidigAdres') > 0) {
            return this.formatKvkDate(this.getFieldValue('datumVestigingHuidigAdres'));
        } else {
            return null;
        }
    }

    public get getEinddatum() {
        return formatDate((this.kvk as IKvk).datumEinde);
    }

    public get wijzeEinde() {
        return (this.kvk as IKvk).kvkRedenUit;
    }
    public get wijzeBinnen() {
        return (this.kvk as IKvk).kvkRedenIn;
    }

    public get kvkDossiernummer() {
        if (this.vestiging) {
            const value = this.vestiging.kvkDossiernummer;
            if (value && value.length >= 3) {
                return value.substr(0, 2) + '-' + value.substr(2);
            }
            return this.vestiging.kvkDossiernummer;
        } else if (this.kvk) {
            const value = '' + this.kvk.dossiernummer;
            if (value && value.length >= 3) {
                return value.substr(0, 2) + '-' + value.substr(2);
            }
            return this.kvk.dossiernummer;
        }
    }

    public set kvkDossiernummer(value: any) {
        this.vestiging.kvkDossiernummer = value.replace('-', '');
    }

    public getHuisletter(field: string) {
        const result = this.getFieldValue(field);
        if (!result) {
            return '-';
        } else if (this.isHuisletterOnly(result)) {
            return result;
        } else if (this.isStartingWithHuisletter(result)) {
            return result.charAt(0);
        }
    }

    public getToevoeging(field: string) {
        const result = this.getFieldValue(field);
        if (!result) {
            return '-';
        } else if (this.isHuisletterOnly(result)) {
            return '-';
        } else if (this.isStartingWithHuisletter(result)) {
            return result.slice(2);
        } else {
            // return result;
            return result;
        }
    }

    public isNrvr(kvk: IKvkBase) {
        if ((kvk as IKvk).status) {
            return (kvk as IKvk).status === KvkStatus.NRVR;
        } else {
            return false;
        }
    }

    public isStatusBehoordelen(kvk: IKvkBase) {
        if ((kvk as IKvk).status) {
            return (kvk as IKvk).status === KvkStatus.BEOORDELEN;
        } else {
            return false;
        }
    }

    public kvkStatusValue(kvk: IKvkBase) {
        if ((kvk as IKvk).status || (kvk as IKvk).status === 0) {
            const statusOption = kvkStatusOptions.find((option) => option.value === (kvk as IKvk).status);
            if (!statusOption) {
                return 'Status onbekend';
            }
            switch (statusOption.value) {
                case KvkStatus.RVR:
                    return 'Relevant';
                case KvkStatus.NRVR:
                    return 'Niet relevant';
                case KvkStatus.BEOORDELEN:
                    return 'Te beoordelen';
                default:
                    return 'Status onbekend';
            }
        } else {
            return '-';
        }
    }

    public get isDbfImported() {
        return this.getFieldValue('vsiVestigingsStatusIndicator') === 'DBF';
    }

    public isHuisletterOnly(text: string) {
        const isSingleChar = text.length === 1;
        const isNonNumber = this.isFirsCharNonNumeric(text);
        return isSingleChar && isNonNumber;
    }

    public isFirsCharNonNumeric(text: string) {
        return !/\d/.test(text.charAt(0));
    }

    public isStartingWithHuisletter(text: string) {
        const isLengthLongerThanOne = text.length > 1;
        if (!isLengthLongerThanOne) {
            return false;
        } else {
            const isFirstCharFollowedBySpace = /\s/.test(text.charAt(1));
            if (this.isFirsCharNonNumeric(text) && isFirstCharFollowedBySpace) {
                return true;
            } else {
                return false;
            }
        }
    }

    public zeroPad(num: number, size: number): string | null {
        if (!num) {
            return null;
        }
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    public getFieldValue(fieldName: string, isNumber: boolean = false) {
        for (const item in this.kvkCma!) {
            if (item === fieldName) {
                const result = (this.kvkCma as any)[item];
                if (result && isNumber) {
                    return parseInt(result, 10);
                } else {
                    return result;
                }
            }
        }
        return '';
    }

    public async openOpnemenDialog() {
        this.showOpnemenDialog = true;
    }

    public async closeOpnemenModal() {
        this.showOpnemenDialog = false;
    }

    public async created() {
        await this.loadData();
    }

    public async loadData() {
        this.loading = true;
        if (this.vestiging && this.vestiging.kvkId) {
            const kvkResponse = await this.$api.kvk.get(this.vestiging.kvkId);
            this.kvk = kvkResponse!.data || null;
        } else if (this.inputKvk) {
            this.kvk = this.inputKvk;
        }
        await this.loadGemeenteNaam();
        await this.loadSbiNaam();
        await this.loadRechsvormNaam();
        await this.loadGrootteklasseNaam();
        await this.loadGrootteklasseNaamFt();
        this.loading = false;
    }

}
