
















































































































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {dateTime, importStatusText, timeDiff} from '@/utils/filters';
import {IImportRun} from '@/models/IExportRun';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import {ImportJobStatus} from '@/models/IImportRun';

@Component({
    filters: {
        importStatusText,
        timeDiff,
        dateTime,
    },
    components: {SimpleConfirmDialog, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class ImportRunList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Beschrijving', value: 'description'},
        {text: 'Sjabloon', value: 'importTemplate'},
        {text: 'Bestandsnaam', value: 'filename'},
        {text: 'Aangemaakt', value: 'toegevoegdOp'},
        {text: 'Gestopt', value: 'stoppedOn'},
        {text: 'Rijen in bestand', value: 'totalCount'},
        {text: 'Status', value: 'status'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public importRunDialog: boolean = false;
    public cancelDialog: boolean = false;
    public cancelAllDialog: boolean = false;
    public selectedImportRun: IImportRun | null = null;

    public get pendingStatus() {
        return ImportJobStatus.PENDING;
    }

    public showImportRunInfo(importRun: IImportRun) {
        this.selectedImportRun = importRun;
        this.importRunDialog = true;
    }

    public async cancelImportRun(importRun: IImportRun) {
        await this.api.cancel(importRun);
        this.debouncedLoadData();
        this.selectedImportRun = null;
        this.cancelDialog = false;
    }

    public async cancelAll() {
        await this.api.cancelAll();
        this.debouncedLoadData();
        this.cancelAllDialog = false;
    }

    public getPercentage(item: any) {
        if (!item) {
            return '';
        }
        const percentage = item.processedCount / item.totalCount * 100;
        if (isNaN(percentage)) {
            return '';
        }
        return '(' + (Math.round(percentage * 100) / 100) + '%)';
    }

    public get failedInfo() {
        if (!this.selectedImportRun) {
            return [];
        }
        return Object.entries((this.selectedImportRun as any).failedInfo);
    }

    public get api() {
        return this.$api.import;
    }
}
