



















































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {Component, Mixins} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IGebruiker} from '@/models/IGebruiker';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import {buildEmptyAdres} from '@/models/IAdres';
import {buildEmptyCb, ICb} from '@/models/ICb';
import {buildEmptyContact} from '@/models/IContact';
import {max, maxLength, required} from '@/utils/validation';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';
import {Idable} from '@/models/Idable';

@Component({
    filters: {},
    components: {
        ContactCard,
        AdresInfo,
        SimpleMessageWrapper,
    },
})
export default class CbEdit extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    public loadingData: boolean = true;
    public cb: ICb | null = null;
    public oldCb: ICb | null = null;
    public registers: IRegister[] = [];
    public plaatsnaamOptions: Idable[] = [];

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    private isEditing: boolean = false;

    public async created() {
        await this.loadData();
        this.isEditing = this.$route.name === 'cbEdit';
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];
    }

    public get rules() {
        return {
            naam:  [required],
            cbNummer:  [required, maxLength(10)],
            straat: [],
            huisnummer: [maxLength(5)],
            postcode: [maxLength(6)],
            plaatsnaam: [],
            gemeente: [],
            huisletter: [maxLength(1)],
            toevoeging: [maxLength(15)],
            bagOppervlak: [max(9999999)],
            bagNummeraanduiding: [max(9999999999999999999)],
            bagVerblijfsobject: [max(9999999999999999999)],
            xCoord: [max(999999999)],
            yCoord: [max(999999999)],
        };
    }

    public get api() {
        return this.$api.cb;
    }

    public get canUpdate() {
        return this.isAdmin;
    }

    public get canCreate() {
        return this.isAdmin;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public async loadData() {
        this.loadingData = true;
        try {
            if (this.$route.name === 'cbEdit') {
                const response = await this.$api.cb.get(this.$route.params.id);
                this.cb = response.data! as ICb;

                if (!this.cb!.contact) {
                    this.cb!.contact = buildEmptyContact();
                }

                this.oldCb = this.$_.cloneDeep(this.cb);
            } else {
                this.cb = buildEmptyCb();
            }
            await this.loadRegisters();
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public async save() {
        const valid = (this.$refs.form as any).validate();

        if (!this.cb || !valid) {
            return;
        }
        this.loadingData = true;
        try {
            const valueToPost: ICb = this.getValueToPost();
            if (this.isEditing) {
                const response = await this.$api.cb.save(valueToPost!);
                this.cb = response.data as ICb;
            } else {
                const response = await this.$api.cb.create(valueToPost!);
                this.cb = response.data as ICb;
            }
            this.$router.push({name: 'cbList'});
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het opslaan van de data.');
            console.error('Save error: ', e);
        } finally {
            this.loadingData = false;
        }
    }

    private getValueToPost(): ICb | any {
        // Strip properties that could've been fetched from get-endpoint, but should not be post for saving.
        const postValue = this.$_.cloneDeep(this.$_.omit(this.cb, [
            'toegevoegdOp',
            'gewijzigdOp',
            'toegevoegdDoor',
            'gewijzigdDoor',
        ]));
        if (!postValue.contact) {
            postValue.contact = null;
        }
        return postValue;
    }

    private async loadRegisters() {
        const response = await this.$api.register.list(new Pagination(0, -1), []);
        if (response.success) {
            this.registers = response.data!.content;
        }
    }
}
