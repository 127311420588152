import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#002d57',
        secondary: '#01b5d9',
        anchor: '#01b5d9',
        accent: '#01b5d9',
      },
    },
  },
});
