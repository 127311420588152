import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, post, put, url} from '@/utils/http';
import {IKvk} from '@/models/IKvk';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IKvkLinkOptions} from '@/models/IKvkLinkOptions';
import {IVestiging} from '@/models/IVestiging';
import {IExportKvkCreate} from '@/models/ExportKvkCreate';
import {IKvkExportRun} from '@/models/IKvkExportRun';

export class KvkApi extends GenericApi<IKvk> {

    public async findMatchingVestigingen(kvk: IKvk) {
        const uri = new URL(url(this.path + `/find-matching-vestigingen/` + kvk.id));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IVestiging[]>;
    }

    public async exportStatus() {
        const uri = new URL(url(this.path + `/export-status`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IKvkExportRun>;
    }

    public async exportSchaduwTabel(kvkExportRequest: IExportKvkCreate) {
        const uri = new URL(url(this.path + `/export`));
        const result = await post(uri.href, kvkExportRequest);
        return result.data as IWrapperResponse<IPaginationResponse<string>>;
    }

    public async linkToVestiging(
        kvkMutatie: IKvk,
        koppelOpties: IKvkLinkOptions,
        updateWithResponse: boolean = false,
    ) {
        const item = JSON.parse(JSON.stringify(koppelOpties));

        const uri = new URL(url(this.path + `/koppelen`));
        const result = await put(uri.href, item);
        if (updateWithResponse) {
            // kvkMutatie.handmatigOpgenomen = false;
            this.updateKvkProperties(kvkMutatie, result.data.data);
        }
        return result.data as IWrapperResponse<IKvk>;
    }

    public async addVestigingForKvk(kvk: IKvk, updateWithResponse: boolean = false) {
        const item = JSON.parse(JSON.stringify(kvk));

        const uri = new URL(url(this.path + `/handmatig-opnemen`));
        const result = await put(uri.href, item);
        if (updateWithResponse) {
            this.updateKvkProperties(kvk, result.data.data);
        }
        if (item.kvkMutatie) {
            item.kvkMutatie.handmatigOpgenomen = true;
        }
        return result.data as IWrapperResponse<IKvk>;
    }

    public async unlinkAndMarkNonRelevant(kvk: IKvk, updateWithResponse: boolean = false) {
        const item = JSON.parse(JSON.stringify(kvk));

        const uri = new URL(url(this.path + `/${kvk.id}/ontkoppelen`));
        const result = await put(uri.href, item);
        if (updateWithResponse) {
            this.updateKvkProperties(kvk, result.data.data);
        }
        if (item.kvkMutatie) {
            item.kvkMutatie.handmatigOpgenomen = true;
        }
        return result.data as IWrapperResponse<IKvk>;
    }

    public async createVestigingByKvk(kvk: IKvk, updateWithResponse: boolean = false) {
        const uri = new URL(url(this.path + `/create-vestiging/` + kvk.id));
        const result = await get(uri.href);
        if (updateWithResponse) {
            this.updateKvkProperties(kvk, result.data.data);
        }
        return result.data as IWrapperResponse<IKvk>;
    }

    private updateKvkProperties(kvk: IKvk, data: IKvk|null) {
        for (const i in kvk) {
            if (kvk.hasOwnProperty(i) && data!.hasOwnProperty(i)) {
                (kvk as any)[i] = (data as any)[i];
            }
        }
    }
}
