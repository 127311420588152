export type SortDirection = 'DESC'|'ASC'|true|false;

export interface ISort {
    sort: string;
    direction: SortDirection;
}

export interface IPagination {
    page: number;
    size: number;
    sort: ISort[];
}

export class Pagination implements IPagination {

    public static serialize(pagination: IPagination) {
        const params = new URLSearchParams();
        params.append('page', String(pagination.page));
        params.append('size', String(pagination.size));
        if (pagination.sort && !!pagination.sort.sort) {
            for (const sort of pagination.sort) {
                params.append('sort', `${sort.sort},${sort.direction}`);
            }
            // params.append('sort', `${pagination.sort.sort},${pagination.sort.direction}`);
        }
        return params;
    }

    public page = 0;
    public size = 20;
    public sort: ISort[] = [];

    // constructor(page: number, size: number, sort: string = 'id', direction: SortDirection = 'DESC') {
    constructor(page: number, size: number, sort: string[] = ['id'], direction: SortDirection[] = ['DESC']) {
        this.page = page;
        if (size === -1) {
            // size -1 means infinite size; However, we should not fetch more than 10k.
            // We limit the size to 100k in cause more may be needed at some point.
            size = 100000;
        }
        this.size = size > 0 ? size : 1000; // Limit to 1000.
        this.sort = [];
        for (let i = 0; i < sort.length; i++) {
            this.sort.push({sort: sort[i], direction: direction[i]});
        }
    }

    public serialize(): URLSearchParams {
        return Pagination.serialize(this);
    }
}
