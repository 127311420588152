































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {Pagination} from '@/utils/api-tools/pagination';
import {IRegister} from '@/models/IRegister';



@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class GemeenteEdit extends Mixins(BasicCrudMixin) {

    public registers: IRegister[] = [];

    public getApi(): GenericApi<Idable> {
        return this.$api.gemeente;
    }

    public get rules() {
        return {
            omschrijving: [required],
            werkgebied: [],
            id: [required],
        };
    }

    public buildEmptyModelData(): Idable {
        return {
            omschrijving: null,
            werkgebied: false,
        } as unknown as Idable;
    }

    public async beforeMount(): Promise<void> {
        const response = await this.$api.register.list(new Pagination(0, -1), []);
        if (response.success) {
            this.registers = response.data!.content;
        }
    }
}
