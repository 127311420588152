import {GenericApi} from '@/api/generic';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {Pagination, SortDirection} from '@/utils/api-tools/pagination';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {Component, Mixins, Watch} from 'vue-property-decorator';

@Component
export class ListsObjects<T> extends Mixins(ShowsMessages) {
    public dialog: boolean = false;
    public loadingData: boolean = true;
    public pagination: any = {
        multiSort: false,
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        mustSort: true,
        footerProps: {
            itemsPerPageOptions: [5, 10, 15, 25, 50, 100],
        },
    };
    public totalData: number = 0;
    public headers: any[] = [];
    public objects: Array<{ object: T, deleting: boolean }> = [];

    public searchCriteria?: SearchCriterion[] = [];
    public searchValue: any = {
        'vestiging.id' : 'eq:',
    };
    public allowZeroPadInSearch: string[] = ['cb.cbNummer'];

    protected debouncedLoadData = this.$_.debounce(() => {
        return this.loadListData();
    }, 300);

    public get api(): GenericApi<T> {
        throw new Error('public get api() has not been implemented.');
    }

    public get editRouteName(): string {
        return this.$route.meta.editRouteName;
    }

    public get localStoragePrefix() {
        return (this as any).$options.name + '_' + this.$route.name;
    }

    public toEdit(id: string|number) {
        if (this.$listeners.toEdit) {
            this.$emit('toEdit', id);
        } else {
            const idParam = id.toString();
            this.$router.push({name: this.editRouteName, params: {id: idParam}});
        }
    }

    public created() {
        this.pagination.itemsPerPage = parseInt(localStorage.getItem('itemsPerPage') || '10', 10);

        let pagination: any = localStorage.getItem(this.localStoragePrefix + '_pagination');
        if (pagination) {
            try {
                pagination = JSON.parse(pagination);
                this.pagination.page = pagination.page;
                this.pagination.itemsPerPage = pagination.itemsPerPage;
                this.pagination.itemsPerPage = pagination.itemsPerPage;
                this.pagination.sortBy = pagination.sortBy;
                this.pagination.sortDesc = pagination.sortDesc;
            } catch (e) {
                console.error(e);
            }
        }
        const originalSearchValue = JSON.stringify(this.searchValue);
        try {
            let searchValue: any = localStorage.getItem(this.localStoragePrefix + '_searchValue');
            if (searchValue) {
                this.searchValue = [];
                searchValue = JSON.parse(searchValue);
                for (const key in searchValue) {
                    if (searchValue.hasOwnProperty(key)) {
                        this.searchValue[key] = searchValue[key];
                    }
                }
                this.setSearchCriteria();
            }
        } catch (e) {
            console.error(e);
            this.searchValue = JSON.parse(originalSearchValue);
        }
    }

    @Watch('pagination', {deep: true})
    public watchPagination() {
        localStorage.setItem('itemsPerPage', this.pagination.itemsPerPage);
        localStorage.setItem(this.localStoragePrefix + '_pagination', JSON.stringify(this.pagination));
        return this.debouncedLoadData();
    }

    public async deleteObject(id: number | string) {
        try {
            await this.api.delete(id);
        } catch (e) {
            this.showErrorByException(e, 'message.error.deleteGeneric');
        }
        this.dialog = false;
        await this.loadListData();
    }

    public resetPagination() {
        this.pagination = Object.assign({}, this.pagination, {page: 1});
    }

    protected async search() {
        this.setSearchCriteria();
        this.pagination.page = 1;
        this.debouncedLoadData();
    }

    protected setSearchCriteria() {
        const criteria: SearchCriterion[] = [];
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                let value = this.searchValue[key];
                if (value != null && !(Array.isArray(value) && value.length === 0)) {

                    // when numbers start with zero's, then apply parseInt to strip the zero's .
                    // We want to use this for numbers that are zero-filled, like 0001234, but some variables
                    // have different meaning for values like '001' or '01'. These only occur for variable-constants
                    // that have codes with 5 characters at most.
                    if (/^\d+$/.test(value)
                        && value.length > 5
                        && !this.allowZeroPadInSearch.find((v) => key.startsWith(v))
                    ) {
                        value = parseInt(value, 10);
                    }

                    criteria.push(new SearchCriterion(key, value));
                }
            }
        }

        this.searchCriteria = criteria;

        const searchValue: any = {};
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                searchValue[key] = this.searchValue[key];
            }
        }
        localStorage.setItem(this.localStoragePrefix + '_searchValue', JSON.stringify(searchValue));
    }

    protected async clear() {
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                this.searchValue[key] = null;
            }
        }
        this.search();
    }

    protected async loadListData() {
        this.loadingData = true;
        const sortDesc: SortDirection[] = [];
        for (const desc of this.pagination.sortDesc) {
            sortDesc.push(desc ? 'DESC' : 'ASC');
        }
        const pagination = new Pagination(
            this.pagination.page - 1,
            this.pagination.itemsPerPage,
            this.pagination.sortBy,
            sortDesc,
        );
        try {
            const {data} = await this.api.list(pagination, this.searchCriteria || []);
            this.totalData = data!.total;
            this.objects = data!.content.map((t) => Object({object: t, deleting: false}));
        } catch (e) {
            this.handleException(e);
        } finally {
            this.loadingData = false;
        }
    }

    protected handleException(e: any) {
        if (e.response.status === 401) {
            this.showError('Geen toegang: u bent niet ingelogd.');
        } else if (e.response.status === 405) {
            const errorMessage = this.extractSearchErrorMessage(e.response.data.error);
            const message = errorMessage ? 'Er is een fout opgetreden: ' + errorMessage : 'Er is een fout opgetreden.';
            this.showError(message);
        } else {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        }
    }

    private extractSearchErrorMessage(error: string) {
        if (this.isInvalidSearchError(error)) {
            const matches = Array.from(error.matchAll(/'([^']+)'/g));
            const input = matches[0][0];
            const field = matches[1][0];
            if (input && field) {
                return 'Foutieve invoer \'' + input + '\' voor zoekveld \'' + field + '.';
            }
        }
    }

    private isInvalidSearchError(error: string) {
        return error && error.includes('Invalid search parameter');
    }
}
