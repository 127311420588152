



















import {Component, Prop, Watch, Vue, Model} from 'vue-property-decorator';
import i18n from '@/plugins/i18n';

const $t = i18n.t.bind(i18n);

@Component({
    components: {},
})
export default class SimpleCardDialog extends Vue {
    @Model('input', {type: Boolean})
    public readonly dialog!: boolean;
    public width: number = 600;

    public close() {
        this.$emit('input', false);
        this.$emit('close');
    }
}
