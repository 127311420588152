











































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {buildAndFilter, buildExpressionFilter, buildOrFilter, IFilter} from '@/models/IFilter';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';
import {filterTypeText} from '@/utils/filters';

@Component({
    filters: {filterTypeText},
    components: {},
    name: 'filter-builder',
})
export default class FilterBuilder extends Vue {
    @Prop()
    public filter!: IFilter;
    @Prop({default: null})
    public parentFilter!: IFilter | null;
    @Prop({default: false})
    public isTemplate!: boolean;

    public searchableFields: string[] = [];

    public operators: Array<{ label: string, value: string, noInput?: boolean }> = [
        {label: 'Gelijk aan (=)', value: 'eq'},
        {label: 'Niet gelijk aan (!=)', value: 'ne'},
        {label: 'Bevat', value: 'contains'},
        {label: 'Groter dan (>)', value: 'gt'},
        {label: 'Groter dan of gelijk aan (>=)', value: 'goe'},
        {label: 'Minder dan (<)', value: 'lt'},
        {label: 'Minder dan of gelijk aan (<=)', value: 'loe'},
        {label: 'Is leeg', value: 'isNull' },
        {label: 'Is niet leeg', value: 'isNotNull'},
        {label: 'Is lege verzameling', value: 'isEmptySet'},
        {label: 'In', value: 'in'},
        {label: 'Begint met', value: 'sw'},
    ];

    public get isRoot() {
        return !this.parentFilter;
    }

    public get rules() {
        return {
            field: [],
            operator: [],
            value: [],
        };
    }

    public async created() {
        this.searchableFields = (await this.$api.export.searchableFields()).data! || [];
    }

    public addFilter(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildExpressionFilter());
        }
    }

    public addAndFilterGroup(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildAndFilter());
        }
    }

    public valueInputEnabled(filter: IFilter) {
        return this.isTemplate && !(filter.inputName === '' || !filter.inputName);
    }

    public addOrFilterGroup(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildOrFilter());
        }
    }

    public removeFilter(columnTemplate: IImportColumnTemplate) {
        if (this.filter) {
            const columnTemplateIdx = this.filter.filters.findIndex((t: any) => t === columnTemplate);
            if (columnTemplateIdx >= 0) {
                this.filter.filters.splice(columnTemplateIdx, 1);
            }
        }
    }

    public getInputType(field: string, operator: string) {
        if (['isNull', 'isNotNull', 'isEmptySet'].includes(operator)) {
            return null;
        }
        return 'text';
    }
}
