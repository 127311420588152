





































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {IImportRunCreate} from '@/models/IImportRunCreate';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';
import {ImportPreviewRow} from '@/models/ImportPreviewRow';

@Component({
    filters: {},
    components: {
        SimpleConfirmDialog,
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class ImportPreview extends Mixins(ShowsMessages) {
    @Prop({required: true})
    public importRun!: IImportRunCreate;

    public templateToTextMap = new Map<IImportColumnTemplate, string[]>();
    public tableHeaders: string[] = [];
    public tableRows: ImportPreviewRow[] = [];
    public maxNrOfLines = 10;

    public get showPreview() {
        return this.templateToTextMap && this.importRun.file && this.importRun.importTemplate;
    }

    public get headers() {
        if (this.tableHeaders.length < 2) {
            return [];
        }
        return this.tableHeaders.map((tableHeader) => this.createTableHeader(tableHeader));
    }

    public createTableHeader(name: string) {
        const header = {
            text: name,
            value: name.toLowerCase(),
            sortable: false,
        } as unknown;
        return header;
    }

    @Watch('importRun.file', {deep: true, immediate: true})
    @Watch('importRun.importTemplate', {deep: true})
    public onFileChange() {
        if (this.importRun.file && this.importRun.importTemplate) {
            this.readFile();
        }
    }

    public createTableData() {
        this.tableHeaders = ['RegelNr'];
        this.tableRows = [];
        this.templateToTextMap = this.sortColumns();
        this.templateToTextMap.forEach((value: string[], key: IImportColumnTemplate) => {
            this.tableHeaders.push(key.mappedField);
            let index = 1;
            for (const i of value) {
                let tableRow = this.tableRows.find((tbRow) => tbRow.rowNr === index);
                if (!tableRow) {
                    tableRow = this.buildPreviewRow(index);
                    this.tableRows.push(tableRow);
                }
                tableRow.rowValues.push(i.trim());
                index++;
            }
        });
    }

    public sortColumns() {
        return new Map([...this.templateToTextMap].sort(([k, v], [k2, v2]) => {
            if (k.startPosition && k2.startPosition && k.startPosition > k2.startPosition) {
                return 1;
            }
            if (k.startPosition && k2.startPosition && k.startPosition < k2.startPosition) {
                return -1;
            }
            return 0;
        }));
    }

    public parseLine(line: string) {
        this.templateToTextMap.forEach((value: string[], key: IImportColumnTemplate) => {
            const start = key.startPosition - 1;
            const end = start + key.length;
            value.push(line.slice(start, end));
        });
    }

    public readFile() {
        const file = this.importRun.file;
        if (!file) {
            return;
        }
        this.templateToTextMap = new Map<IImportColumnTemplate, string[]>();
        this.importRun.importTemplate.importColumnTemplates.forEach((template) => {
            this.templateToTextMap.set(template, []);
        });
        const maxlines = this.maxNrOfLines;
        this.readSomeLines(file, maxlines, (line: any) => {
            this.parseLine(line);
        }, () => {
            this.createTableData();
        });
    }

    public readSomeLines(file: any, maxlines: number, forEachLine: any, onComplete: any) {
        const CHUNK_SIZE = 50000; // 50kb, arbitrarily chosen.
        const decoder = new TextDecoder();
        let offset = 0;
        let linecount = 0;
        let results = '';
        const fileReader = new FileReader();
        fileReader.onload = () => {
            results += decoder.decode(fileReader.result as ArrayBuffer, {stream: true});
            const lines = results.split('\n');
            results = lines.pop() as string;
            linecount += lines.length;

            if (linecount > maxlines) {
                lines.length -= linecount - maxlines;
                linecount = maxlines;
            }

            for (const i in lines) {
                if (lines.length > 0) {
                    forEachLine(lines[i] + '\n');
                }
            }
            offset += CHUNK_SIZE;
            seek();
        };
        fileReader.onerror = () => {
            onComplete(fileReader.error);
        };
        seek();

        function seek() {
            if (linecount === maxlines) {
                onComplete();
                return;
            }
            if (offset !== 0 && offset >= file.size) {
                forEachLine(results);
                onComplete();
                return;
            }
            const slice = file.slice(offset, offset + CHUNK_SIZE);
            fileReader.readAsArrayBuffer(slice);
        }
    }

    public buildPreviewRow(index: number): ImportPreviewRow {
        const previewRow = {
            rowNr: index,
            rowValues: [],
        } as unknown as ImportPreviewRow;
        return previewRow;
    }
}
