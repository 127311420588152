import {Component, Vue} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {IGebruiker, Role} from '@/models/IGebruiker';

@Component
export class AuthorizationMixin extends Vue {
    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    get isSuperAdmin(): boolean {
        return this.hasRole(Role.SUPER_ADMIN);
    }

    get isAdmin(): boolean {
        return this.hasRole(Role.ADMIN) || this.isSuperAdmin;
    }

    get isUser(): boolean {
        return this.hasRole(Role.USER) || this.isAdmin;
    }

    get isUserOnly(): boolean {
        return !this.hasAnyRole([Role.SUPER_ADMIN, Role.ADMIN, Role.ANALIST, Role.DATABEHEERDER]);
    }

    get isRoleAnalist(): boolean {
        return this.hasRole(Role.ANALIST) || this.isAdmin;
    }

    get isRoleDatabeheer(): boolean {
        return this.hasRole(Role.DATABEHEERDER) || this.isAdmin;
    }

    protected hasRole(role: Role): boolean {
        return this.currentUser.rollen.includes(role);
    }

    protected userHasId(id: number): boolean {
        return this.currentUser.id === id;
    }

    protected hasAnyRole(roles: Role[]): boolean {
        for (const role of roles) {
            if (this.hasRole(role)) {
                return true;
            }
        }
        return false;
    }
}
