



































import {Component, Vue} from 'vue-property-decorator';
import {State} from 'vuex-class';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {required} from '@/utils/validation';
import {IGebruiker} from '@/models/IGebruiker';
import settings from '@/utils/settings';

@Component({
    components: {AutoLoadingButton},
})
export default class Login extends Vue {
    public username: string = '';
    public password: string = '';
    public showError: boolean = false;
    public error: string = '';

    @State((state: any) => state.authentication.authenticated)
    public isLoggedIn!: boolean;

    public usernameRules = [required];
    private passwordRules = [required];

    public created() {
        if (this.isLoggedIn) {
            this.$router.push({name: 'home'});
        }
    }

    public async login($event: Event, a: boolean) {
        if ((this.$refs.form as any).validate()) {
            try {
                const result = await this.$api.auth.login(this.username, this.password);
                if (result.data?.mfa) {
                    await this.$router.push({name: 'mfa-login'});
                } else {
                    if (settings.MFA_ENABLE_WARNING && !result.data?.mfa && !result.data?.mfaDisabled) {
                        await this.$router.push({name: 'mfa-manage'});
                        return;
                    }
                    await this.$router.push({name: 'home'});
                }
            } catch (e) {
                this.showError = true;
                this.error = (e && e.response && e.response.status === 401) ?
                    'Gebruikersnaam en wachtwoord zijn onbekend.' : 'Er is een fout opgetreden.';
            }
        }
    }
}
