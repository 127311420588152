import axios from 'axios';
import settings from '@/utils/settings';
import _ from 'lodash';

const http = axios.create({withCredentials: true});

function get(url: string) {
    return http.get(url);
}

function post(url: string, body: any) {
    return http.post(url, body);
}

function remove(url: string) {
    return http.delete(url);
}

function put(url: string, body: any) {
    return http.put(url, body);
}

function patch(url: string, body: any) {
    return http.patch(url, body);
}

function makeUrl(path: string, pathVariables?: PathVariableMap) {
    if (!_.isEmpty(pathVariables)) {
        const template = _.template(path, {interpolate: /{([\s\S]+?)}/g});
        path = template(pathVariables);
    }

    return settings.HTTP_URL + '/' + path.replace(/^\/+/, '');
}

interface PathVariableMap {
    [key: string]: string | number;
}


/**
 * Merges search parameters into specific parameter set.
 * @param target target to merge into
 * @param params the source URLSearchParams
 */
function mergeURLSearchParams(target: URLSearchParams, ...params: URLSearchParams[]): URLSearchParams {
    const result = target;
    params.forEach((p: URLSearchParams) => {
        p.forEach((value: string, key: string) => {
            result.append(key, value);
        });
    });
    return result;
}

export {
    http,
    makeUrl as url,
    mergeURLSearchParams,
    get,
    post,
    put,
    patch,
    remove,
    PathVariableMap,
};
