

































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';

@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class KvkRedenInEdit extends Mixins(BasicCrudMixin) {
    public wijzeStartOptions: Idable[] = [];

    public async created() {
        await this.loadDropdownMenuItems();
    }

    public async loadDropdownMenuItems() {
        await this.$api.wijzeStart.listAll().then((result) => {
            if (result.data != null) {
                this.wijzeStartOptions = result.data.content;
            }
        });
    }

    public getApi(): GenericApi<Idable> {
        return this.$api.kvkRedenIn;
    }

    public get rules() {
        return {
            omschrijving: [required],
            wijzeStart: [required],
            id: [required],
        };
    }

    public buildEmptyModelData(): Idable {
        return {
            omschrijving: null,
            wijzeStart: null,
        } as unknown as Idable;
    }
}
