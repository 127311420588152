import {Idable} from '@/models/Idable';
import {IAdres} from '@/models/IAdres';
import {ISbi} from '@/models/constants/ISbi';
import {IWijzeBinnen} from '@/models/constants/IWijzeBinnen';
import {IVestiging} from '@/models/IVestiging';
import {ICb} from '@/models/ICb';
import {IMeetperiode} from '@/models/constants/IMeetperiode';
import {IGrootteklasse} from '@/models/constants/IGrootteklasse';
import {IEnqueteType} from '@/models/constants/IEnqueteType';

export interface IWerknemerStat extends Idable {
    enqueteType: IEnqueteType|null;
    bevroren: boolean;
    uitschieter: boolean;
    meetperiode: IMeetperiode;
    manFulltime: number;
    manParttime: number;
    manFulltimeUitzendkracht: number;
    manParttimeUitzendkracht: number;
    vrouwFulltime: number;
    vrouwParttime: number;
    vrouwFulltimeUitzendkracht: number;
    vrouwParttimeUitzendkracht: number;
    grootteklasse: IGrootteklasse|null;
    cb: ICb;
    vestiging: IVestiging;
    wijzeBinnen: IWijzeBinnen;
    sbi: ISbi;
    adres: IAdres|null;
}

export function buildEmptyWerknemerStatForPeriod(
        meetPeriode: IMeetperiode,
        isBevroren: boolean = false,
        enqueteType: IEnqueteType|null = null,
        grootteklasse: IGrootteklasse|null = null,
        wijzeBinnen: IWijzeBinnen|null = null,
): IWerknemerStat {
    return {
        id: null,
        enqueteType,
        bevroren: isBevroren,
        meetperiode: meetPeriode,
        manFulltime: null,
        manParttime: null,
        manFulltimeUitzendkracht: null,
        manParttimeUitzendkracht: null,
        vrouwFulltime: null,
        vrouwParttime: null,
        vrouwFulltimeUitzendkracht: null,
        vrouwParttimeUitzendkracht: null,
        vestiging: null,
        grootteklasse,
        wijzeBinnen,
        sbi: {id: '', code: '0000', naam: 'Onbekend' },
    } as unknown as IWerknemerStat;
}
