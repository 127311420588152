































































































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {buildEmptyVestiging, IVestiging} from '@/models/IVestiging';
import {IGebruiker} from '@/models/IGebruiker';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import VestigingAlgemeneInfo from '@/views/vestigingen/components/VestigingAlgemeneInfo.vue';
import ContactInfo from '@/views/vestigingen/components/ContactInfo.vue';
import VestigingMutatieInfo from '@/views/vestigingen/components/VestigingMutatieInfo.vue';
import {Idable} from '@/models/Idable';
import DateInput from '@/components/DateInput.vue';
import WerkzamePersonen from '@/views/vestigingen/components/WerkzamePersonen.vue';
import StatusColorChip from '@/components/StatusColorChip.vue';
import Overige from '@/views/vestigingen/components/Overige.vue';
import {buildEmptyAdres} from '@/models/IAdres';
import {buildEmptyCorrespondent} from '@/models/ICorrespondent';
import VestigingKvkInfo from '@/views/vestigingen/components/VestigingKvkInfo.vue';
import VestigingKvkMutaties from '@/views/vestigingen/components/VestigingKvkMutaties.vue';
import {required} from '@/utils/validation';
import {formatDate} from '@/utils/filters';

let changeTriggers = 0;
let leaveWarningAfterNChanges = 1;

@Component({
    methods: {formatDate},
    filters: {},
    components: {
        VestigingKvkMutaties,
      VestigingKvkInfo,
        StatusColorChip,
        Overige,
        VestigingMutatieInfo,
        WerkzamePersonen,
        DateInput,
        ContactInfo,
        VestigingAlgemeneInfo,
        AdresInfo,
        ContactCard,
        SimpleMessageWrapper,
    },
    beforeRouteLeave(to, from, next) {
        if (leaveWarningAfterNChanges !== -1 && changeTriggers > leaveWarningAfterNChanges) {
            if (!confirm('Er zijn mogelijk onopgeslagen wijzigingen. Weet u zeker dat u de pagina wilt verlaten?')) {
                return;
            }
        }
        next();
    },
})
export default class VestigingEdit extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    public loadingData: boolean = true;
    public vestiging: IVestiging | null = null;
    public oldVestiging: IVestiging | null = null;
    public validationErrorsCount: number = 0;

    public tab: string = 'algemeen';

    public tab1Invalid: boolean = false;
    public tab2Invalid: boolean = false;
    public tab3Invalid: boolean = false;

    public inBehandeling: boolean = false;
    public isInBehandelingInitial: boolean = false;

    public behandelingTypes: Idable[] = [];

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    private saveDialog: boolean = false;
    private newAddress: boolean|null = null;
    private refresher = 0;

    public async created() {
        await this.loadData();
        document.title = 'VR - ' + this.vestigingNaam + ' - LISA';
        changeTriggers = 0;
        window.onbeforeunload = () => {
            if (leaveWarningAfterNChanges !== -1 && changeTriggers > leaveWarningAfterNChanges) {
                return 'Er zijn mogelijk onopgeslagen wijzigingen. Weet u zeker dat u de pagina wilt verlaten?';
            }
        };
        document.addEventListener('keydown', this.handleKeyDown);
    }

    public async beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    public handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'F10') {
            this.save();
        }
    }

    @Watch('vestiging', {deep: true})
    public onVestigingChanged() {
        changeTriggers++;
    }

    @Watch('tab')
    public onTabChange() {
        if (this.tab === 'werkzamepersonen') {
            leaveWarningAfterNChanges = 2;
        }
    }

    public get rules() {
        return {
            radioNewAddress: [!this.getAddressHasChanged() || this.newAddress === true || this.newAddress === false],
            newAddressDateInput: [this.newAddress === false || this.vestiging!.datumVestigingHuidigAdres != null],
            newSbiMutationDateInput: [!this.getSbiHasChanged() || this.vestiging!.sbiMutatieDatum != null],
            newNaamMutationDateInput: [!this.getNaamHasChanged() || this.vestiging!.naamMutatieDatum != null],
            datumBehandeling: [required],
            behandelingType: [required],
        };
    }

    public get isInBehandelingSwitched() {
        return this.inBehandeling !== this.isInBehandelingInitial;
    }

    public get vestigingNaam() {
        return !this.isUserOnly ? this.vestiging!.naam : this.vestiging!.vervangendeTitel;
    }

    public get isEditing() {
        return this.$route.name === 'vestigingEdit';
    }

    public get api() {
        return this.$api.vestiging;
    }

    public setTab(tabNr: number) {
        if (tabNr === 1) {
            this.tab = 'algemeen';
            this.tab1Invalid = true;
        } else if (tabNr === 2) {
            this.tab = 'overige';
            this.tab2Invalid = true;
        } else if (tabNr === 3) {
            this.tab = 'werkzamepersonen';
            this.tab3Invalid = true;
        }
    }

    public getAddressHasChanged(): boolean {
        return !!this.oldVestiging?.adres
            && (this.oldVestiging?.adres?.postcode !== this.vestiging?.adres?.postcode
                || this.oldVestiging?.adres?.huisnummer !== this.vestiging?.adres?.huisnummer
                || this.oldVestiging?.adres?.huisletter !== this.vestiging?.adres?.huisletter
                || this.oldVestiging?.adres?.toevoeging !== this.vestiging?.adres?.toevoeging
            );
    }

    public getSbiHasChanged(): boolean {
        return !!this.oldVestiging?.sbi
            && JSON.stringify(this.oldVestiging?.sbi) !== JSON.stringify(this.vestiging?.sbi);
    }

    public getNaamHasChanged(): boolean {
        return !!this.oldVestiging?.naam
            && JSON.stringify(this.oldVestiging?.naam) !== JSON.stringify(this.vestiging?.naam);
    }

    public get canUpdate() {
        return this.isRoleDatabeheer;
    }

    public get canCreate() {
        return this.isRoleDatabeheer;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public async loadData() {
        this.loadingData = true;
        this.behandelingTypes = (await this.$api.behandelingType.listAll()).data?.content || [];
        try {
            if (this.$route.name === 'vestigingEdit') {
                const response = await this.$api.vestiging.get(this.$route.params.id);
                this.vestiging = response.data!;
                this.vestiging.mutatieToelichting = null;

                if (!this.vestiging.correspondent) {
                    this.vestiging.correspondent = buildEmptyCorrespondent();
                }
                if (!this.vestiging.adres) {
                    this.vestiging.adres = buildEmptyAdres();
                }

                this.oldVestiging = this.$_.cloneDeep(this.vestiging);
            } else {
                this.vestiging = buildEmptyVestiging();
            }
            this.isInBehandelingInitial = !!this.vestiging!.datumBehandeling;
            this.inBehandeling = this.isInBehandelingInitial;
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public async save(force: boolean = false) {
        this.tab1Invalid = false;
        this.tab2Invalid = false;
        this.tab3Invalid = false;
        const valid = (this.$refs.form as any).validate();

        if (!this.vestiging || !valid) {
            this.validationErrorsCount = this.validationErrorsCount + 1;
            return;
        }
        if (!force) {
            this.saveDialog = true;
            return;
        }
        if (this.$refs.sbiform) {
            const validSbiMutationForm = (this.$refs.sbiform as any).validate();
            if (!this.vestiging || !validSbiMutationForm) {
                return;
            }
        }
        if (this.$refs.addressChangeform) {
            const validAddresMutationForm = (this.$refs.addressChangeform as any).validate();
            if (!this.vestiging || !validAddresMutationForm) {
                return;
            }
        }

        if (this.$refs.behandelingChangeform) {
            const validBehandelingChangeForm = (this.$refs.behandelingChangeform as any).validate();
            if (!this.vestiging || !validBehandelingChangeForm) {
                return;
            }
        }

        if (this.isInBehandelingInitial && !this.inBehandeling) {
            this.vestiging!.behandelingType = null;
            this.vestiging!.behandelingOpmerking = null;
            this.vestiging!.datumBehandeling = null;
        }
        this.loadingData = true;
        try {
            const valueToPost: IVestiging = this.getValueToPost();
            let response;
            if (this.isEditing) {
                response = await this.$api.vestiging.save(valueToPost!);
                this.vestiging = response.data;
            } else {
                response = await this.$api.vestiging.create(valueToPost!);
                this.vestiging = response.data;
            }
            this.saveDialog = false;
            changeTriggers = 0;
            leaveWarningAfterNChanges = -1;
            if (!this.isEditing) {
                this.showRepeatableMessage('Gegevens opgeslagen.');
                await this.$router.push({name: 'vestigingEdit', params: { id: this.vestiging!.id as string }});
                setTimeout(() => window.location.reload(), 1000);
            } else {
                this.showRepeatableMessage('Gegevens opgeslagen.');
                // use window.reload instead of refresher, because info may not completely reloaded correctly.
                // consequently, the next save would cause unwanted behaviour.
                // with a page-reload all info should be reloaded correctly, but the message won't be displayed.
                // setTimeout(() => this.refresher++, 1000);
                window.location.reload();
            }
        } catch (e) {
            this.saveDialog = false;
            this.showErrorByException(e, 'Er is een fout opgetreden bij het opslaan van de data.');
            console.error('Save error: ', e);
        } finally {
            this.loadingData = false;
        }
    }

    private getValueToPost(): IVestiging | any {
        // Strip properties that could've been fetched from get-endpoint, but should not be post for saving.
        const postValue = this.$_.cloneDeep(this.$_.omit(this.vestiging, [
            'toegevoegdOp',
            'gewijzigdOp',
            'toegevoegdDoor',
            'gewijzigdDoor',
        ]));
        this.filterGeneratedBevrorenWerknemerstats(postValue);
        if (!postValue.cb || !postValue.cb.id) {
            postValue.cb = null;
        }
        if (!postValue.wijzeEinde || !postValue.wijzeEinde.id) {
            postValue.wijzeEinde = null;
        }
        if (!postValue.wijzeStart || !postValue.wijzeStart.id) {
            postValue.wijzeStart = null;
        }
        if (!postValue.uitzetCode || !postValue.uitzetCode.id) {
            postValue.uitzetCode = null;
        }
        if (!postValue.rechtsvorm || !postValue.rechtsvorm.id) {
            postValue.rechtsvorm = null;
        }
        if (!postValue.vestigingType || !postValue.vestigingType.id) {
            postValue.vestigingType = null;
        }
        if (!postValue.overheidsCode || !postValue.overheidsCode.id) {
            postValue.overheidsCode = null;
        }
        if (!postValue.sbi || !postValue.sbi.id) {
            postValue.sbi = null;
        }
        if (!postValue.contact) {
            postValue.contact = null;
        }
        if (!postValue.wpContact) {
            postValue.wpContact = null;
        }
        return postValue;
    }

    private filterGeneratedBevrorenWerknemerstats(postValue: IVestiging) {
        postValue.werknemerStats = postValue.werknemerStats!.filter((ws) => {
            return !(ws.bevroren && !ws.id);
        });
    }
}
