




import {Component, Model, Vue} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import DateInput from '@/components/DateInput.vue';
import {formatDate} from '@/utils/filters';
import KvkDetails from '@/views/kvk/KvkDetails.vue';

@Component({
  filters: { formatDate },
  components: {KvkDetails, VestigingKvkInfo, DateInput},
})
export default class VestigingKvkInfo extends Vue {
  @Model('input')
  public vestiging!: IVestiging;
}
