






















































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {dateTime, numberToMonth, date, timeDiff, uploadStatusText} from '@/utils/filters';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import {IKvkUploadRun, KvkUploadStatus} from '@/models/IKvkUploadRun';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';

@Component({
        filters: {
            timeDiff,
            dateTime,
            date,
            uploadStatusText,
            numberToMonth,
        },
        components: {SimpleConfirmDialog, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class KvkUploadRunList extends Mixins(ListsObjects, AuthorizationMixin) {
        public drawer: boolean = true;
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            // {text: 'Beschrijving', value: 'description'},
            {text: 'Bestandsnaam', value: 'filename'},
            {text: 'Maand', value: 'maand'},
            {text: 'Jaar', value: 'jaar'},
            {text: 'Handmatig verwerkt', value: 'manualRowsProcessed'},
            {text: 'Correctielijst verwerkt', value: 'clRowProcessed'},
            {text: 'Datum KvK', value: 'datumMutaties'},
            {text: 'Status', value: 'status'},
            {text: 'Register', value: 'register'},
            {text: 'Aangemaakt op', value: 'toegevoegdOp'},
            {text: '', value: 'action', sortable: false, searchable: false},
        ];
        public cancelDialog: boolean = false;
        public selectedUploadRun: IKvkUploadRun | null = null;
        public uploadRunDialog: boolean = false;
        public loading = false;
        public registers: IRegister[] = [];

        public get api() {
            return this.$api.kvkUploadRun;
        }

        public manualProgress(uploadRun: IKvkUploadRun) {
            if (uploadRun.manualRowsProcessed !== null && uploadRun.manualRows !== null) {
                return uploadRun.manualRowsProcessed + '/' + uploadRun.manualRows;
            } else if (uploadRun.manualRows !== null) {
                return '0/' + uploadRun.manualRows;
            } else {
                return '-';
            }
        }

        public clProgress(uploadRun: IKvkUploadRun) {
            if (uploadRun.clRowsProcessed !== null && uploadRun.clRows !== null) {
                return uploadRun.clRowsProcessed + '/' + uploadRun.clRows;
            } else if (uploadRun.clRows !== null) {
                return '0/' + uploadRun.clRows;
            } else {
                return '-';
            }
        }

        public async cancelUploadRun(upload: IKvkUploadRun) {
            this.loading = true;
            await this.api.cancel(upload);
            this.debouncedLoadData();
            this.selectedUploadRun = null;
            this.cancelDialog = false;
            this.loading = false;
        }

        public isCancelable(item: any) {
          return !item.object.canceledOn
              && !item.object.stoppedOn
              && item.object.status === KvkUploadStatus.UPLOAD_IN_PROGRESS;
        }

        public getPercentage(item: any) {
            if (!item) {
                return '';
            }
            const percentage = item.processedCount / item.totalCount * 100;
            if (isNaN(percentage)) {
                return '';
            }
            return (Math.round(percentage * 100) / 100) + '%';
        }

        public async beforeMount(): Promise<void> {
            const response = await this.$api.register.list(new Pagination(0, -1), []);
            if (response.success) {
                this.registers = response.data!.content;
            }
        }
    }
