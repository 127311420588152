












































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';

@Component({
})
export default class KvkMutatieCategorieOverzicht extends Vue {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun | null;

    public get status() {
        return this.kvkUploadRun?.status;
    }

    public get uploadInProgress() {
        return this.status === KvkUploadStatus.UPLOAD_IN_PROGRESS;
    }

    public get step() {
        return this.kvkUploadRun?.selectedStep;
    }

    public get mutatieOverzichtObjects() {
        const items  = [];

        items.push({
            category: 'A',
            mutatiebestand: this.getMutatieSoortCount('A'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('A'),
            description: 'De vestiging is nieuw in het register',
        });
        items.push({
            category: 'B',
            mutatiebestand: this.getMutatieSoortCount('B'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('B'),
            description: 'De vestiging is nieuw voor de profielhouder',
        });
        items.push({
            category: 'C',
            mutatiebestand: this.getMutatieSoortCount('C'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('C'),
            description: 'De vestiging is gewijzigd',
        });
        items.push({
            category: 'D',
            mutatiebestand: this.getMutatieSoortCount('D'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('D'),
            description: 'De vestiging vervalt voor de profielhouder, maar blijft bestaan in het register',
        });
        items.push({
            category: 'E',
            mutatiebestand: this.getMutatieSoortCount('E'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('E'),
            description: 'De vestiging heeft de status opgeheven gekregen',
        });
        items.push({
            category: 'F',
            mutatiebestand: this.getMutatieSoortCount('F'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('F'),
            description: 'Correctie; de vestiging is opgeheven geweest, maar deze opheffing is ongedaan gemaakt',
        });
        items.push({
            category: 'H',
            mutatiebestand: this.getMutatieSoortCount('H'),
            schaduwtabel: this.getExistingKvkMutatieSoortCount('H'),
            description: 'De vestiging is opgeheven om ergens anders opnieuw te beginnen',
        });
        return items;
    }

    public getMutatieSoortCount(soort: string): number {
        return (this.kvkUploadRun?.mutatieSoortCountInfo as any)[soort] || 0;
    }

    public getExistingKvkMutatieSoortCount(soort: string): number {
        return (this.kvkUploadRun?.existingKvkMutatieSoortCountInfo as any)[soort] || 0;
    }
}
