


















































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import {IVestiging} from '@/models/IVestiging';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import CorrespondentieAdresInfo from '@/views/vestigingen/components/CorrespondentieAdresInfo.vue';
import StartEindeInfo from '@/views/vestigingen/components/StartEindeInfo.vue';
import {dateTime} from '@/utils/filters';
import {IGebruiker} from '@/models/IGebruiker';
import {getKvkMutatieRules, KvkMutatieRule} from '@/models/KvkMutatieRule';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    filters: {
        dateTime,
    },
    components: {
        StartEindeInfo,
        CorrespondentieAdresInfo,
        AdresInfo,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class VestigingKvkMutatieInfo extends Mixins(AuthorizationMixin) {

    @Prop({required: true})
    public kvkMutatie!: IKvkMutatie;

    public vestigingOld: IVestiging | null = null;

    public loading: boolean = false;

    public expanded = [];

    public userCreate: IGebruiker | null = null;

    public userEdit: IGebruiker | null = null;

    public headers: any[] = [
        {text: '', sortable: false},
        {text: '', sortable: false},
        {text: '', value: 'data-table-expand' },
    ];

    @Watch('kvkMutatie')
    public async onChange() {
        this.loadData();
    }

    public get getBeslisRegel() {
        return this.kvkMutatie.beslisRegel;
    }

    public get getHandmatigeAccordering() {
        return this.kvkMutatie.handmatigOpgenomen ? 'Ja' : 'Nee';
    }

    public get getMutatieInfo() {
        let mutatieText = '';
        const isNewOpgenomen = this.vestigingOld === null;
        if (isNewOpgenomen) {
            mutatieText += 'Vestiging toegevoegd';
        } else if (this.kvkMutatie.executedAt) {
            mutatieText += 'Vestiging gemuteerd';
        }
        return mutatieText;
    }

    public get userCreateValue() {
        if (this.userCreate) {
            return this.userCreate.gebruikersnaam;
        } else {
            return '';
        }
    }

    public get userEditValue() {
        if (this.userEdit && this.kvkMutatie.handmatigOpgenomen) {
            return this.userEdit.gebruikersnaam;
        } else {
            return '';
        }
    }

    public get vestigingCompareRules(): KvkMutatieRule[] {
        return getKvkMutatieRules(this.vestigingOld, this.kvkMutatie.kvkCma);
    }

    public get vestigingCompareRulesOverview() {
        const result = [];
        if (this.vestigingCompareRules) {
            const changesForCorrespondent = !!this.vestigingCompareRules.find((obj: KvkMutatieRule) => {
                return obj.vestigingFieldName.startsWith('correspondent') &&
                    obj.vestigingValue !== obj.newValueIncludeVestigingOpgenomen;
            });
            const changesForContact = !!this.vestigingCompareRules.find((obj: KvkMutatieRule) => {
                return obj.vestigingFieldName.startsWith('contact') &&
                        obj.vestigingValue !== obj.newValueIncludeVestigingOpgenomen;
            });
            const changesForAdres = !!this.vestigingCompareRules.find((obj: KvkMutatieRule) => {
                return obj.vestigingFieldName.startsWith('adres') &&
                        obj.vestigingValue !== obj.newValueIncludeVestigingOpgenomen;
            });
            const changesForVestiging = !!this.vestigingCompareRules.find((obj: KvkMutatieRule) => {
                return !obj.vestigingFieldName.startsWith('adres') &&
                    !obj.vestigingFieldName.startsWith('contact') &&
                    !obj.vestigingFieldName.startsWith('correspondent') &&
                    obj.vestigingValue !== obj.newValueIncludeVestigingOpgenomen;
            });
            if (changesForVestiging) {
                const vestiging = {item: 'Algemeen', value: changesForVestiging, name: 'vestiging'};
                result.push(vestiging);
            }
            if (changesForAdres) {
                const adres = {item: 'Adres', value: changesForAdres, name: 'adres'};
                result.push(adres);
            }
            if (changesForCorrespondent) {
                const correspondent = {item: 'Correspondentie', value: changesForCorrespondent, name: 'correspondent'};
                result.push(correspondent);
            }
            if (changesForContact) {
                const contact = {item: 'Contact', value: changesForContact, name: 'contact'};
                result.push(contact);
            }
        }
        return result;
    }

    public async created() {
        this.loadData();
    }

    public vestigingCompareRulesByName(name: string) {
        let result;
        if (name === 'vestiging') {
            result =  this.vestigingCompareRules.filter((object) => {
                return !object.vestigingFieldName.startsWith('adres')
                && !object.vestigingFieldName.startsWith('contact')
                && !object.vestigingFieldName.startsWith('correspondent')
                    && object.newValueIncludeVestigingOpgenomen !== object.vestigingValue;
            });
        } else {
            result = this.vestigingCompareRules.filter((object) => {
                return object.vestigingFieldName.startsWith(name)
                    && object.newValueIncludeVestigingOpgenomen !== object.vestigingValue;
            });
        }
        return result;
    }

    public async loadData() {
        this.loading = true;
        this.vestigingOld =  this.getOldVestigingFor(this.kvkMutatie);
        const toegevoegdDoor: IGebruiker = this.kvkMutatie.toegevoegdDoor;
        if (toegevoegdDoor) {
            this.userCreate = (await this.$api.user.get(toegevoegdDoor.id)).data;
        }
        const gewijzigdDoor: IGebruiker = this.kvkMutatie.toegevoegdDoor;
        if (gewijzigdDoor) {
            this.userEdit = (await this.$api.user.get(gewijzigdDoor.id)).data;
        }
        this.loading = false;
    }

    public getOldVestigingFor(kvkMutatie: IKvkMutatie): IVestiging | null {
        if (kvkMutatie.oldVestigingData) {
            return JSON.parse(kvkMutatie.oldVestigingData) as IVestiging;
        } else {
            return null;
        }
    }
}
