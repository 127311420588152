import {Idable} from '@/models/Idable';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';

export interface IImportTemplate extends Idable {
    name: string;
    mappedObject: MappedObject;
    importColumnTemplates: IImportColumnTemplate[];
    exampleText: string|null;
}

export enum MappedObject {
    VESTIGING = 1,
    WERKZAME_PERSONEN = 2,
    CB = 4,
    ADRES = 5,
    DBF_ADRES = 100,
    DBF_VESTIGING = 101,
    DBF_ENQUETE = 102,
    DBF_BEVROREN = 103,
    DBF_STRAAT = 104,
    DBF_SBI = 105,
    DBF_GEMEENTE = 106,
    DBF_PLAATSNAAM = 107,
    DBF_CORRESPONDENT = 108,
    DBF_CB = 109,
    DBF_KVK = 110,
    DBF_KVK_NRVR = 111,
}

export function buildEmptyImportTemplate(): IImportTemplate {
    return {
        name: '',
        mappedObject: '',
        importColumnTemplates: [],
        exampleText: 'Plak hier voorbeeld tekst, bijvoorbeeld de eerste paar regels uit de export waarvoor ' +
            'deze sjabloon gemaakt wordt. Bij het opgeven van kolommen hieronder wordt in dit voorbeeld de ' +
            'tekst opgelicht die overeenkomt met de positie\nVoor iedere regel kan worden bekeken wat ' +
            'overeenkomt met een bepaald veld.',
    } as unknown as IImportTemplate;
}
