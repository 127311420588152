




























































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {buildEmptyContact, ContactType, contactTypeOptions, IContact} from '@/models/IContact';
import {email, minLength, required} from '@/utils/validation';

@Component({
    filters: {},
    components: {},
})
export default class ContactCard extends Vue {
    @Model('input')
    private contact!: IContact;

    @Prop({type: Boolean, default: false})
    private canUpdate!: boolean;

    @Prop({type: Boolean, default: false})
    private isContactForWp!: boolean;

    @Prop({type: String, default: 'Contact'})
    private cardTitle!: string;

    @Prop({required: false, default: 0})
    private validationErrorsCount!: number;

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    public get rules() {
        return {
            naam: [],
            telefoon: [],
            mobiel: [],
            toestel: [],
            email: [email],
            website: [],
        };
    }
}
