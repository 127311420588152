import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/lodash';
import './plugins/dayjs';
import axios from 'axios';
import i18n, {loadI18nMessages} from './plugins/i18n';
import settings from '@/utils/settings';
import Api from '@/api';
import './types';
import {hasRole} from '@/utils/auth';

Vue.config.productionTip = false;

/* Prepare the HTTP Endpoint URL */
settings.HTTP_URL = process.env.VUE_APP_API || 'http://localhost:8080/';
settings.HTTP_URL = settings.HTTP_URL.replace(/\/+$/, '');
settings.MFA_NAME = process.env.VUE_APP_MFA_NAME || 'Lisa Test';
settings.MFA_ENABLE_WARNING = process.env.VUE_APP_MFA_ENABLE_WARNING || false;
settings.ENABLE_BAG_MENU_ITEM = process.env.VUE_APP_ENABLE_BAG_MENU_ITEM || false;
settings.ENABLE_SHAPE_FILE_MENU_ITEM = process.env.VUE_APP_ENABLE_SHAPE_FILE_MENU_ITEM || false;
settings.ENABLE_DELETE_MENU_ITEM = process.env.VUE_APP_ENABLE_DELETE_MENU_ITEM || false;

const DEFAULT_TITLE = 'LISA';
/* Set the router to load locales and check if need to be logged in before each navigation */
router.beforeEach((to, from, next) => {
    const lang = (to.query.lang && Array.isArray(to.query.lang) ? to.query.lang[0] : to.query.lang)
        || localStorage.getItem('locale') || 'nl';
    loadI18nMessages(lang).then(() => next());

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiredRoles = to.matched.flatMap((record) => record.meta.roles).filter((t) => !!t);

    if (requiresAuth) {
        const authentication = store.state.authentication;
        const user = authentication.user;
        if (!authentication.authenticated || !user) {
            next('/login');
            return;
        }
        if (!user.mfa && !user.mfaDisabled) {
            next('/mfa-manage');
            return;
        }
        if (requiredRoles && requiredRoles.length > 0) {
            const isAllowed = requiredRoles.filter((role: string) => !!role)
                .reduce((acc, curr) => {
                    return acc || hasRole(user!, curr);
                }, false);
            if (!isAllowed) {
                next({path: 'access-denied', replace: true});
            }
        }
    }
    store.commit('setPublicName', to.meta.publicName);
    document.title = to.meta.title || DEFAULT_TITLE;
    next();
});

/* intercept responses to logout user */
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) { // if the error is 401 and hasn't already been retried
            store.commit('setAuthenticated', false);
            router.push('login');
        }
    });

/* create default Api object */
const api = new Api();
Object.defineProperty(Vue.prototype, '$api', {value: api});

let app: any;
axios.get(settings.HTTP_URL + '/api/v1/current-user', {withCredentials: true})
    .then((response) => {
        const isAuthenticated = (response && response.data && response.data.success) || false;
        store.commit('setAuthenticated', isAuthenticated);
        if (isAuthenticated) {
            store.commit('setUser', response.data.data);
        }

        if (!app) {
            app = new Vue({
                i18n,
                vuetify,
                router,
                store,
                api,
                render: (h) => h(App),
            });
            app.$mount('#app');
        }
    })
;
