import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, url} from '@/utils/http';
import {Idable} from '@/models/Idable';
import {ICb} from '@/models/ICb';

export class BuurtApi extends GenericApi<Idable> {

    constructor() {
        super('/api/v1/buurt');
    }

    public async getLightList(searchValue: string): Promise<IWrapperResponse<ICb[]>> {
        // Replace '/' with '?' (in db ? will allow any character); Slashes will cause bad-uri error.
        searchValue = searchValue.replaceAll('/', '?');
        const uri = new URL(url(this.path + `/light-list/` + searchValue));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<ICb[]>;
    }
}
