export interface IFilter {
    field: string;
    operator: string;
    value: string;
    inputName: string; // For templates
    type: FilterType;
    filters: IFilter[];

}

export enum FilterType {
    AND,
    OR,
    EXPRESSION,
}

export function buildExpressionFilter(): IFilter {
    return {
        field: '',
        operator: '',
        value: '',
        type: FilterType.EXPRESSION,
        filters: [],
    } as unknown as IFilter;
}

export function buildAndFilter(): IFilter {
    return {
        field: '',
        operator: '',
        value: '',
        type: FilterType.AND,
        filters: [],
    } as unknown as IFilter;
}

export function buildOrFilter(): IFilter {
    return {
        field: '',
        operator: '',
        value: '',
        type: FilterType.OR,
        filters: [],
    } as unknown as IFilter;
}
