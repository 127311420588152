import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import AuthenticationModule from '@/store-modules/auth';
import RoutingModule from '@/store-modules/routing';

Vue.use(Vuex);

// tslint:disable-next-line:no-empty-interface
export interface RootState {}

export type RootType = RootState & { authentication: AuthenticationModule };

export function _createNewStore() {
  return new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
      authentication: AuthenticationModule,
      routing: RoutingModule,
    },
  }) as Store<RootType>;
}

export default _createNewStore();
