import {GenericApi, IWrapperResponse} from '@/api/generic';
import {http, url} from '@/utils/http';
import {IImportTemplate} from '@/models/IImportTemplate';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';

export class ImportTemplateApi extends GenericApi<IImportTemplate> {

    constructor() {
        super('/api/v1/import-template');
    }

    public async columnDefinitions(importTemplateId: number) {
        const uri = new URL(url(`/api/v1/import-template/${importTemplateId}/columns`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IImportColumnTemplate>;
    }

    public async mappableObjects() {
        const uri = new URL(url(`/api/v1/import-template/objects`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<string[]>;
    }

    public async mappableFields(mappedObject: string) {
        const uri = new URL(url(`/api/v1/import-template/fields`));
        const params = new URLSearchParams();
        params.append('objectType', String(mappedObject));
        const result = await http.get(uri.href + '?' + params.toString());
        return result.data as IWrapperResponse<string[]>;
    }

}
