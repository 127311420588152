




















import {Component, VModel, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validation';
import {IExportTemplateInputOption} from '@/models/IExportTemplateInputOption';

@Component({
    methods: {required},
    filters: {},
    components: {},
})
export default class TemplateInput extends Vue {
    @VModel()
    public model!: string;
    @Prop({default: 'Waarde'})
    public label!: string;
    @Prop()
    public type!: string;
    @Prop()
    public options!: IExportTemplateInputOption[];

    get sortedOptions() {
        return this.options.sort((a, b) => a.label.localeCompare(b.label));
    }
}
