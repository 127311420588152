



































import {Component, VModel, Prop, Vue, Watch} from 'vue-property-decorator';
import {convertToDate, formatDate} from '@/utils/filters';

@Component({
    filters: {},
    components: {},
})
export default class CardTitle extends Vue {
    @VModel()
    public date!: any;
    @Prop()
    public label!: string;
    @Prop({default: false})
    public disabled!: boolean;
    @Prop({default: () => ([])})
    public rules!: any;
    @Prop({default: false})
    public dense!: boolean;

    public dateFormatted: any;

    public menu: boolean = false;

    public created() {
        this.dateFormatted = this.formatDate(this.date);
    }

    @Watch('date')
    public dateChanged() {
        // a timestamp of 50000000000 in seconds would be the year 3554 so for this application it may be safe to
        // assume that any value above is in milliseconds.
        if (this.date > 50000000000) {
            this.date /= 1000;
        }
        this.dateFormatted = this.formatDate(this.date);
    }

    public get datePickerDate() {
        const dateObj = this.date ? convertToDate(this.date) : new Date();
        return dateObj.toISOString().split('T')[0];
    }

    public set datePickerDate(date: any) {
        this.date = new Date(date.toString()).valueOf();
    }

    public get computedDateFormatted() {
        return this.formatDate(this.date);
    }

    public formatDate(date: any) {
        return formatDate(date);
    }

    // Formats 'd-m-Y' to 'Y-m-d'
    public parseDate(date: any) {
        if (!date) {
            this.date = null;
        } else {
            const [day, month, year] = date.split('-');
            this.date = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`).valueOf();
        }
    }
}
