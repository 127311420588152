


















import {Component, Model, Prop, Vue} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import {ContactType, contactTypeOptions} from '@/models/IContact';

@Component({
    filters: {},
    components: {ContactCard},
})
export default class ContactInfo extends Vue {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;
}
