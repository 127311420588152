



















































import {Component, Mixins, Model, Prop} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IKvkUploadRun} from '@/models/IKvkUploadRun';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import Cl1NietBagConform from '@/views/kvk-upload-run/controlelijst/Cl1NietBagConform.vue';
import Cl4NietDoorgevoerdeAfvoeringGroteVestiging from '@/views/kvk-upload-run/controlelijst/Cl4NietDoorgevoerdeAfvoeringGroteVestiging.vue';
import Cl5GroteVestigingGemeentegrensOvergestoken from '@/views/kvk-upload-run/controlelijst/Cl5GroteVestigingGemeentegrensOvergestoken.vue';
import Cl6NietDoorgevoerdeMutaties from '@/views/kvk-upload-run/controlelijst/Cl6NietDoorgevoerdeMutaties.vue';
import {IKvk} from '@/models/IKvk';
import Cl2SbiMutatieGroteVestiging from '@/views/kvk-upload-run/controlelijst/Cl2SbiMutatieGroteVestiging.vue';
import Cl3NaamswijzigingGroteVestiging from '@/views/kvk-upload-run/controlelijst/Cl3NaamswijzigingGroteVestiging.vue';
import {IVestiging} from '@/models/IVestiging';

@Component({
    components: {
        Cl1NietBagConform,
        Cl6NietDoorgevoerdeMutaties,
        Cl5GroteVestigingGemeentegrensOvergestoken,
        Cl4NietDoorgevoerdeAfvoeringGroteVestiging,
        Cl3NaamswijzigingGroteVestiging,
        Cl2SbiMutatieGroteVestiging,
        NietDoorgevoerdeMutaties: Cl6NietDoorgevoerdeMutaties,
        GroteVestigingGemeentegrensOvergestoken: Cl5GroteVestigingGemeentegrensOvergestoken,
        NietDoorgevoerdeAfvoeringGroteVestiging: Cl4NietDoorgevoerdeAfvoeringGroteVestiging,
        NietBagConform: Cl1NietBagConform,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class ManualSelection extends Mixins(AuthorizationMixin) {

    @Model('input')
    public refresher!: number;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun;

    public tab: string = 'tab';

    public totalCl1: number = 0;
    public totalCl2: number = 0;
    public totalCl3: number = 0;
    public totalCl4: number = 0;
    public totalCl5: number = 0;
    public totalCl6: number = 0;
    public totalManual: number = 0;

    public get api() {
        return this.$api.kvkMutatie;
    }

    // public get manualProcessingHeader() {
    //     return 'Handmatig verwerken (' + this.totalManual + ')';
    // }

    public get cl1TabHeader() {
        return 'CL1 (' + this.totalCl1 + ')';
    }

    public get cl2TabHeader() {
        return 'CL2 (' + this.totalCl2 + ')';
    }

    public get cl3TabHeader() {
        return 'CL3 (' + this.totalCl3 + ')';
    }

    public get cl4TabHeader() {
        return 'CL4 (' + this.totalCl4 + ')';
    }

    public get cl5TabHeader() {
        return 'CL5 (' + this.totalCl5 + ')';
    }

    public get cl6TabHeader() {
        return 'CL6 (' + this.totalCl6 + ')';
    }

    public async updateCl1Size() {
        this.totalCl1 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 1)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateCl2Size() {
        this.totalCl2 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 2)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateCl3Size() {
        this.totalCl3 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 3)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateCl4Size() {
        this.totalCl4 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 4)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateCl5Size() {
        this.totalCl5 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 5)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateCl6Size() {
        this.totalCl6 = (await this.$api.kvkMutatie.correctielijstSize(this.kvkUploadRun.id, 6)).data || 0;
        this.$emit('clUpdate');
    }

    public async updateManualProcessingSize() {
        this.totalManual = (await this.$api.kvkMutatie.manualListSize(this.kvkUploadRun.id)).data || 0;
        this.$emit('clUpdate');
    }

    public openInfoModal(id: number) {
        this.$emit('openInfoModal', id);
    }

    public async created() {
        if (this.kvkUploadRun.id) {
            await this.updateCl1Size();
            await this.updateCl2Size();
            await this.updateCl3Size();
            await this.updateCl4Size();
            await this.updateCl5Size();
            await this.updateCl6Size();
            await this.updateManualProcessingSize();
        }
    }
}
