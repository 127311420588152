import { GenericApi, IWrapperResponse } from '@/api/generic';
import { get, post, url } from '@/utils/http';
import { Idable } from '@/models/Idable';
import { IImportRun } from '@/models/IImportRun';
import { IBagImportRun } from '@/models/IBagImportRun';
import { IShapefileImportRunCreate } from '@/models/IShapefileImportRunCreate';
import { IShapefileImportRun } from '@/models/IShapefileImportRun';

export class ShapeApi extends GenericApi<IShapefileImportRun> {

    constructor() {
        super('/api/v1/shape');
    }

    public async import(model: IShapefileImportRunCreate) {
        const uri = new URL(url(this.path + '/import'));
        const formData = new FormData();

        for (const file of (model as any).files) {
            formData.append('files', file);
        }

        formData.append('type', (model as any).type);
        formData.append('updateOnly', (model as any).updateOnly);
        const result = await post(uri.href, formData);
        return result.data as IWrapperResponse<IShapefileImportRun>;
    }

    public async abortImport() {
        const uri = new URL(url(this.path + '/import/abort'));
        const result = await post(uri.href, {});
        return result.data as IWrapperResponse<IShapefileImportRun>;
    }

    public async getImport() {
        const uri = new URL(url(this.path + '/import'));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IShapefileImportRun>;
    }
}
