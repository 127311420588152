



















































































































































































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IGebruiker} from '@/models/IGebruiker';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import VestigingAlgemeneInfo from '@/views/vestigingen/components/VestigingAlgemeneInfo.vue';
import ContactInfo from '@/views/vestigingen/components/ContactInfo.vue';
import VestigingMutatieInfo from '@/views/vestigingen/components/VestigingMutatieInfo.vue';
import DateInput from '@/components/DateInput.vue';
import WerkzamePersonen from '@/views/vestigingen/components/WerkzamePersonen.vue';
import StatusColorChip from '@/components/StatusColorChip.vue';
import Overige from '@/views/vestigingen/components/Overige.vue';
import {IKvk, IKvkBase, KvkStatus, MutatieCategorie} from '@/models/IKvk';
import {IVestiging} from '@/models/IVestiging';
import {formatDate} from '@/utils/filters';
import {getKvkMutatieRules, KvkMutatieRule} from '@/models/KvkMutatieRule';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';
import {BagKoppelingKwaliteit} from '@/models/IAdres';
import KvkDetails from '@/views/kvk/KvkDetails.vue';
import KvkLinkToVestigingTable from '@/views/kvk/KvkLinkToVestigingTable.vue';
import {IKvkLinkOptions} from '@/models/IKvkLinkOptions';
import {IKvkMutatie} from '@/models/IKvkMutatie';
import {IKvkCma} from '@/models/IKvkCma';

@Component({
    filters: {
        formatDate,
    },
    components: {
        KvkDetails,
        KvkLinkToVestigingTable,
        StatusColorChip,
        Overige,
        VestigingMutatieInfo,
        WerkzamePersonen,
        DateInput,
        ContactInfo,
        VestigingAlgemeneInfo,
        AdresInfo,
        ContactCard,
        SimpleMessageWrapper,
    },
})
export default class KvkInfo extends Mixins(ShowsMessages, AuthorizationMixin) {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;
    public KvkStatus = KvkStatus;

    public loadingData: boolean = false;
    public showNrvrReasonDialog: boolean = false;
    public reasonNrvr: string = '';
    public kvkBase: IKvkBase | null = null;
    public kvk: IKvk | null = null;
    public vestiging: IVestiging | null = null;
    public kvkMutatie: IKvkMutatie | null = null;
    // public kvkCma = null;
    public showAllKvkMutationValues: boolean = false;

    // Fields that exist in CMA kvkCma, but should be ignored.
    public ignoreJsonCmaFields = [
        'opmerkingen', 'derivedHuisletter', 'dbfDatumSbi', 'dbfDatumOpnam', 'dbfDatumNaam',
    ];

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    @Prop({required: false, default: null})
    public kvkUploadRun!: IKvkUploadRun|null;

    @Prop({default: null})
    public kvkProp!: IKvk | null;

    @Prop({default: null})
    public kvkMutatieProp!: IKvkMutatie | null;

    @Prop({type: Boolean, default: false})
    public useKvkShadowTabel!: boolean;

    @Prop({type: Boolean, default: false})
    public showManualButtons!: boolean;

    public get kvkCma(): IKvkCma {
        if (this.kvk) {
            return this.kvk.kvkCma;
        }
        if (this.kvkMutatie) {
            return this.kvkMutatie.kvkCma;
        }
        throw new Error('No kvk or kvkMutatie');
    }

    public async created() {
        if (this.kvkProp) {
            this.changeKvk();
        } else {
            this.changeKvkMutatie();
        }
    }

    public get bagKoppelKwaliteit() {
        switch (this.kvkMutatie?.bagKoppelingKwaliteit) {
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_HUISLETTER_TOEVOEGING:
                return 'Sterk: op basis van postcode, huisnummer, huisletter en toevoeging';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_HUISLETTER:
                return 'Matig: toevoeging genegeerd';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_TOEVOEGING:
                return 'Matig:  huisletter genegeerd';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER:
                return 'Zwak: huisletter en toevoeging genegeerd.';
            case BagKoppelingKwaliteit.GEEN_KOPPELING_MEER:
                return 'Geen BAG koppeling meer voor dit adres.';
            default:
                return 'Geen BAG koppeling voor dit adres';
        }
    }

    public get showManualProcessingButtons() {
        return this.showManualButtons || (this.kvkUploadRun?.selectedStep === KvkStep.MANUAL_CHECK
            && this.vestiging)// || this.kvk?.correctielijstType == null
            ;
    }

    public get alertInfo() {
        return this.kvkBase && this.kvkBase.info
            ? this.kvkBase.info.trim().replaceAll('\n', '<br />')
            : ''
            ;
    }

    public get isAddManualStep() {
        return this.kvkUploadRun?.selectedStep === KvkStep.MANUAL_CHECK
            && this.vestiging// || this.kvk?.correctielijstType == null
            ;
    }

    private get vestigingOld(): IVestiging|null {
        if (this.kvkMutatie?.oldVestigingData) {
            return JSON.parse(this.kvkMutatie?.oldVestigingData) as IVestiging;
        }
        return this.vestiging;
    }

    public get kvkMutatieRules(): KvkMutatieRule[] {
        if (this.kvkCma) {
            return getKvkMutatieRules(this.vestigingOld, this.kvkCma);
        }
        return [];
    }

    @Watch('kvkProp')
    public async changeKvk() {
        this.kvk = this.kvkProp;
        this.kvkBase = this.kvkProp;
        this.kvkMutatie = this.kvk?.kvkMutatie || null;
    }

    @Watch('kvkMutatieProp')
    public async changeKvkMutatie() {
        this.kvkMutatie = this.kvkMutatieProp;
        this.kvkBase = this.kvkMutatieProp;
    }

    @Watch('kvkBase')
    public async loadVestiging() {
        if (this.kvkBase?.vestigingId) {
            const response = await this.$api.vestiging.get(this.kvkBase?.vestigingId);
            this.vestiging = response.data!;
        } else {
            this.vestiging = null;
        }
    }

    public get getHeaderAlleGegevens() {
        if (this.useKvkShadowTabel) {
            return 'Alle KvK gegevens';
        } else {
            return 'Alle gegevens in KvK-mutatieregel';
        }
    }

    public getMutatieCategoryAndBeslisRegelText(kvkMutatie: IKvkMutatie) {
        const regel = kvkMutatie.beslisRegel ? kvkMutatie.beslisRegel : '?';
        return this.getMutatieCategory(kvkMutatie) + ' / uit regel: ' + regel;
    }

    public getMutatieCategory(kvkMutatie: IKvkMutatie) {
        switch (kvkMutatie.mutatieCategorie) {
            case MutatieCategorie.A_NR:
                return 'A_NR'; // niet relevant
            case MutatieCategorie.B_HM:
                return 'B_HM'; // handmatig beoordelen
            case MutatieCategorie.C_WOPN:
                return 'C_WOPN'; // handmatig beoordelen, waarschijnlijk opnemen
            case MutatieCategorie.D_OPN:
                return 'D_OPN'; // opnemen
            default:
                return '?';
        }
    }

    public getStartDateAddress() {
        const datumFieldName = 'datumVestigingHuidigAdres';
        if (!this.kvkCma[datumFieldName] || this.kvkCma[datumFieldName]!.length < 8) {
            return '-';
        }
        const dateNonFormatted = this.kvkCma![datumFieldName] as string;
        return [
            dateNonFormatted.slice(0, 4),
            dateNonFormatted.slice(4, 6),
            dateNonFormatted.slice(6),
        ].join('-');
    }

    public getHandmatigeMutatieCategory(kvkMutatie: IKvkMutatie) {
        if (!kvkMutatie.kvkHandmatigCodeSet) {
            return '-';
        }
        const categoriesTxt = [];
        for (const kvkHandmatigCode of kvkMutatie.kvkHandmatigCodeSet) {
            categoriesTxt.push(kvkHandmatigCode.code);
        }
        return categoriesTxt.join(', ');
    }

    private async linkKvk(koppelOpties: IKvkLinkOptions) {
        try {
            this.loadingData = true;
            await this.$api.kvk.linkToVestiging(this.kvk!, koppelOpties, true);
            this.loadingData = false;
            const id = koppelOpties.vestigingId;
            const vestigingNummer = koppelOpties.vestigingNummer;
            this.$emit('manualAction', `
                ${this.kvk!.bedrijfsnaam} is gekoppeld aan vestiging
                <a href="/vestigingen/${id}" target="_blank">${vestigingNummer}</a>.
            `);
        } catch (error) {
            // TODO: it'd be better to have a clear response (e.g: 'Gemeente X niet gevonden')
            this.addMessage('Er ging iets mis bij het opslaan/verwerken van de gegevens.', 1000, true);
            throw error;
        }
    }

    private async undoKvkLink(kvk: IKvk, comfirmed: boolean) {
        if (!comfirmed) {
            this.showNrvrReasonDialog = true;
            this.reasonNrvr = '';
            return;
        }
        kvk.kvkOpmerkingen = this.reasonNrvr;
        const vestiging = this.vestiging;

        this.loadingData = true;
        await this.$api.kvk.unlinkAndMarkNonRelevant(kvk, true);
        this.loadingData = false;

        if (vestiging) {
            this.$emit('manualAction', `
                De KvK-regel is gemarkeerd als niet-relevant en de koppeling met  ${vestiging.naam} is
                ongedaan gemaakt. <a href="/vestigingen/${vestiging.id}" target="_blank">Bekijk vestiging</a>
            `);
        } else {
            this.$emit('manualAction', `De KvK-regel is gemarkeerd als niet-relevant`);
        }
    }

    private async nietRelevanteKvkMutatie(kvkMutatie: IKvkMutatie, comfirmed: boolean) {
        if (!comfirmed) {
            this.reasonNrvr = '';
            this.showNrvrReasonDialog = true;
            return;
        } else {
            this.showNrvrReasonDialog = false;
            kvkMutatie.kvkOpmerkingen = this.reasonNrvr;
            this.reasonNrvr = '';
        }
        if (this.loadingData) {
            console.warn('Ignored "niet relevant voor register"; still loading data');
            return;
        }
        if (kvkMutatie.manualCompletedAt && kvkMutatie.handmatigOpgenomen) {
            if (!confirm('Deze mutatie-regel is al opgenomen. Door als niet-relevant te markeren wordt deze opname ongedaan gemaakt.')) {
                return;
            }
        }

        try {
            this.loadingData = true;
            await this.$api.kvkMutatie.rejectHandmatigVerwerkenNietRelevant(kvkMutatie, true);
            this.loadingData = false;

            this.$emit('manualAction', kvkMutatie.bedrijfsnaam + ' is gemarkeerd als niet-relevant-voor-register.');
        } catch (error) {
            this.addMessage('Er ging iets mis bij het opslaan/verwerken van de gegevens.', 1000, true);
            throw error;
        }
    }

    private async approveCurrentLinkWithVestiging(kvk: IKvkBase) {
        if (this.loadingData) {
            console.warn('Ignored "opnemen"; still loading data');
            return;
        }
        this.kvk!.status = KvkStatus.RVR;
        this.loadingData = true;
        await this.$api.kvk.save(this.kvk!);
        this.loadingData = false;

        this.$emit('manualAction', `
            Link met ${this.kvk!.bedrijfsnaam} is goedgekeurd. De kvk-regel is nu markeerd als relevant.
            <a href="/vestigingen/${kvk.vestigingId}" target="_blank">Bekijk vestiging</a>
        `);
    }

    private async addKvkAsNewVestigingToRegister(kvk: IKvkBase) {
        if (this.loadingData) {
            console.warn('Ignored "opnemen"; still loading data');
            return;
        }

        try {
            let newVestigingId = null;

            if (this.kvkMutatie) {
                if (this.kvkMutatie.manualCompletedAt && !this.kvkMutatie.handmatigOpgenomen) {
                    if (!confirm('Deze mutatie-regel is al afgewezen. Wil je deze regel alsnog opnemen?')) {
                        return;
                    }
                }
                this.loadingData = true;
                await this.$api.kvkMutatie.approveHandmatigVerwerkenVestigingOpnemen(this.kvkMutatie, true);
                newVestigingId = this.kvkMutatie.vestigingId;
                this.loadingData = false;
            } else {
                if ((kvk as IKvk).status === KvkStatus.RVR) {
                    if (!confirm('Deze KvK is gemarkeerd als NRVR. Met het opnemen wordt de status gewijzigd '
                        + 'naar Relevant. Wil je doorgaan?')
                    ) {
                        return;
                    }
                }
                this.loadingData = true;
                await this.$api.kvk.addVestigingForKvk(kvk as IKvk, true);
                newVestigingId = kvk.vestigingId;
                this.loadingData = false;
            }

            this.$emit('manualAction', `
                    ${this.kvk!.bedrijfsnaam} is opgenomen als vestiging in het register:
                    <a href="/vestigingen/${newVestigingId}" target="_blank">Bekijk vestiging</a>
                `);
        } catch (error) {
            // TODO: it'd be better to have a clear response (e.g: 'Gemeente X niet gevonden')
            this.addMessage('Er ging iets mis bij het opslaan/verwerken van de gegevens.', 1000, true);
            throw error;
        }
    }
}
