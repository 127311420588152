




import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import ConstantListBaseComponent from '@/components/ConstantListBaseComponent.vue';

@Component({
    components: {ConstantListBaseComponent, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class GemeenteList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public listHeaders: any[] = [
        {text: 'Code', value: 'code', searchable: true},
        {text: 'Naam', value: 'omschrijving', searchable: true},
        {text: 'Werkgebied', value: 'werkgebied', searchable: false, type: 'boolean'},
        {text: 'Register', value: 'register', type: 'objectWithName'},
        {text: 'Actief', value: 'active', searchable: false, type: 'boolean'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public get api() {
        return this.$api.gemeente;
    }
}
