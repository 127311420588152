import {Idable} from '@/models/Idable';
import {IGebruiker} from '@/models/IGebruiker';

export interface IOpmerking extends Idable {
    opmerking: string | null;
    // vestiging: IVestiging;
    toegevoegdOp: number;
    gewijzigdOp: number;
    isManual: boolean;
    toegevoegdDoor: IGebruiker;
    gewijzigdDoor: IGebruiker;
    toegevoegdDoorNaam: string | null;
}

export function buildEmptyOpmerking(): IOpmerking {
    return {
        id: '',
        opmerking: '',
        toegevoegdOp: new Date().getTime() / 1000,
        toegevoegdDoorNaam: null,
        isManual: true,
    } as IOpmerking;
}
