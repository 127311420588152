













    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {Component, Mixins} from 'vue-property-decorator';
    import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
    import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
    import ConstantListBaseComponent from '@/components/ConstantListBaseComponent.vue';
    import {sectionLetterOptions} from '@/models/constants/ISbi';

    @Component({
    components: {ConstantListBaseComponent, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class SbiList extends Mixins(ListsObjects, AuthorizationMixin) {
    public sectionLetterOptions: string[] = sectionLetterOptions;

    public drawer: boolean = true;
    public variableName: string = 'sbi';
    public listHeaders: any[] = [
        {text: 'Code', value: 'code', searchable: true},
        {text: 'Naam', value: 'naam', searchable: true},
        {text: 'Omschrijving', value: 'omschrijving', searchable: true},
        {text: 'Sectie', value: 'sectie', searchable: true},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public get api() {
        return this.$api.sbi;
    }
}
