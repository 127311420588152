
































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import {IKvk} from '@/models/IKvk';
import SbiMutatieGroteVestigingView from '@/views/kvk-upload-run/controlelijst/MutatieCompareView.vue';
import MutatieCompareView from '@/views/kvk-upload-run/controlelijst/MutatieCompareView.vue';
import {IKvkMutatie} from '@/models/IKvkMutatie';
import {IVestiging} from '@/models/IVestiging';
import {ISbi} from '@/models/constants/ISbi';

@Component({
    components: {
        MutatieCompareView,
        SbiMutatieGroteVestigingView,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class SbiMutatieGroteVestigingDialog extends Mixins(AuthorizationMixin) {

    @Prop({required: true})
    public kvkMutatie!: IKvkMutatie;

    public vestiging: IVestiging | null = null;

    public async created() {
        if (this.kvkMutatie.vestigingId) {
            this.vestiging = (await this.$api.vestiging.get(this.kvkMutatie.vestigingId)).data;
        }
    }

    public closeModal() {
        this.$emit('close');
    }

    public async rewind() {
        if (this.kvkMutatie.manualCompletedAt && this.kvkMutatie.handmatigOpgenomen) {
            if (!confirm('Deze SBI mutatie is al goedgekeurd. Wil je deze mutatie alsnog terugdraaien?')) {
                return;
            }
        }
        await this.$api.kvkMutatie.rewind(this.kvkMutatie, true);
        this.$emit('save', true);
    }

    public async approve() {
        if (this.kvkMutatie.manualCompletedAt && !this.kvkMutatie.handmatigOpgenomen) {
            if (!confirm('Deze SBI mutatie is al afgewezen. Wil je deze mutatie alsnog goedkeuren?')) {
                return;
            }
        }
        await this.$api.kvkMutatie.approve(this.kvkMutatie, true);
        this.$emit('save', false);
    }
}
