















































































































































































































































import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Model, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Idable} from '@/models/Idable';
import {IKvk, KvkStatus, MutatieCategorie} from '@/models/IKvk';
import {IKvkHandmatigCode} from '@/models/constants/IKvkHandmatigCode';
import KvkInfo from '@/views/kvk/KvkInfo.vue';
import {monthOptions} from '@/models/IKvkUploadRun';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';

@Component({
    components: {
        KvkInfo,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class KvkManualProcessing extends Mixins(ListsObjects, AuthorizationMixin) {
    public searchValue: any = {};

    public months: Array<{ label: string, value: number }> = monthOptions;

    public headers: any[] =  [
        {text: 'KvK-Vestigingsnummer', value: 'vestigingsnummer'},
        {text: 'Dossiernummer', value: 'dossiernummer'},
        {text: 'Bedrijfsnaam', value: 'bedrijfsnaam'},
        {text: 'Straatnaam', value: 'straatnaam'},
        {text: 'Huisnummer', value: 'huisnummer'},
        {text: 'Postcode', value: 'postcode'},
        {text: 'Woonplaats', value: 'woonplaats'},
        {text: 'Mutatie categorie', value: 'mutatieCategorie'},
        {text: 'Categorie Handmatig', value: 'kvkHandmatigCodeSet', sortable: false},
        {text: 'Actie', value: '', sortable: false, searchable: false},
    ];
    public drawer: boolean = true;
    public selectedKvk: IKvk|null = null;
    public showInfoModal: boolean = false;

    public refresher: number = 0;

    public toggleValue: boolean = true;

    public kvkHandmatigCodeOptions: Idable[] = [];
    public selectedMutationCategory: MutatieCategorie[] =  [];
    private registers: IRegister[] = [];

    public get api() {
        return this.$api.kvk;
    }

    public get monthOptions() {
        return this.months;
    }

    public get mutationCategoryOptions() {
        return Object.keys(MutatieCategorie).filter((item) => {
            return isNaN(Number(item));
        });
    }

    public async created() {
        this.kvkHandmatigCodeOptions = (await this.$api.kvkHandmatigCode.listAll()).data?.content || [];
        const response = await this.$api.register.list(new Pagination(0, -1), []);
        if (response.success) {
            this.registers = response.data!.content;
        }

        if (window.location.hash.toString().includes('linked')) {
            this.searchValue.vestigingId = 'isNotNull';
        }

        await this.search();
    }

    @Watch('selectedMutationCategory', { deep: true } )
    public onSelectionMutationCategoryChanged() {
        const key = 'kvkMutatie.mutatieCategorie:in';
        if (this.selectedMutationCategory!.length !== 0) {
            this.searchValue[key] = this.selectedMutationCategory
                .map((category) => this.mutatieCategory(category))
                .join(',');
        } else {
            this.searchValue[key] = null;
        }
    }

    public async toKvk() {
        await this.$router.push({name: 'kvk_list'});
    }

    public openInfoModal(kvk: IKvk) {
        this.showInfoModal = true;
        this.selectedKvk = kvk;
        // Show id in hash of url. This isn't needed for any functionality, but for testing/debugging:
        // we need the kvk-id to lookup info, but this id isn't displayed anywhere else.
        window.location.hash = kvk.id.toString();
    }

    public getCategorieHandmatigList(kvkHandmatigCodes: IKvkHandmatigCode[]) {
        return kvkHandmatigCodes ? kvkHandmatigCodes.map((kvkHandmatigCode) => kvkHandmatigCode.code).join(', ') : '';
    }

    public mutatieCategory(category: MutatieCategorie) {
        return MutatieCategorie[category];
    }

    public manualAction(message: null|string) {
        this.refresher++;
        this.showInfoModal = false;
        if (message) {
            this.addMessage(message, -1, false);
        }
    }

    protected async search() {
        this.searchValue.status = KvkStatus.BEOORDELEN;
        const criteria: SearchCriterion[] = [];
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                const value = this.searchValue[key];
                if (value != null) {
                    criteria.push(new SearchCriterion(key, value));
                }
            }
        }
        this.searchCriteria = criteria;
        this.debouncedLoadData();
    }

}
