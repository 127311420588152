








































































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {IVestiging} from '@/models/IVestiging';
import DateInput from '@/components/DateInput.vue';
import {convertToDate, formatDate} from '@/utils/filters';

@Component({
    filters: {},
    components: {DateInput},
})
export default class IdentificatieInfo extends Vue {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({type: Boolean, default: false})
    public readonly!: boolean;

    @Prop({required: false, default: 0})
    public validationErrorsCount!: number;

    public wijzeStartOptions: Idable[] = [];
    public wijzeEindeOptions: Idable[] = [];
    public uitzetCodeOptions: Idable[] = [];

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    @Watch('vestiging.datumStart')
    public datumStartChanged() {
        if (!this.vestiging.datumVestigingHuidigAdres) {
            this.vestiging.datumVestigingHuidigAdres = this.vestiging.datumStart;
            return;
        }
    }

    public get rules() {
        return {
            naam: [required],
            straat: [required],
            wijzeStart: [required],
            datumOpname: [],
            datumStart: [() => {
                if (this.vestiging.datumStart && this.vestiging.datumEinde) {
                    if (Date.parse(this.vestiging?.datumStart.toString())
                        > Date.parse(this.vestiging?.datumEinde.toString())) {
                        return 'Startdatum mag niet later zijn dan de einddatum.';
                    }
                } else {
                    if (!this.vestiging.datumStart) {
                        return 'Dit veld is verplicht';
                    }
                }
                return true;
            }],
            datumEinde: [() => {
                if (this.vestiging.datumStart && this.vestiging.datumEinde) {
                    if (convertToDate(this.vestiging?.datumStart.toString()).getTime()
                        > convertToDate(this.vestiging?.datumEinde.toString()).getTime()) {
                        return 'Einddatum mag niet eerder zijn dan de startdatum.';
                    }
                }
                if (!this.vestiging.datumEinde && this.vestiging.wijzeEinde?.id) {
                    return 'Verplicht omdat Wijze Einde is ingevuld.';
                }
                return true;
            }],
            wijzeEinde: [() => {
                if (this.vestiging.datumEinde && !this.vestiging.wijzeEinde?.id) {
                    return 'Verplicht omdat einddatum is ingevuld.';
                }
                return true;
            }],
        };
    }

    public get formattedMutatieDatum() {
        if (!this.vestiging?.gewijzigdOp) {
            return null;
        }
        return Intl.DateTimeFormat('nl', { dateStyle: 'short', timeStyle: 'short' })
            .format(new Date(this.vestiging.gewijzigdOp * 1000));
    }

    public async created() {
        this.wijzeStartOptions = (await this.$api.wijzeStart.listAll()).data?.content || [];
        this.wijzeEindeOptions = (await this.$api.wijzeEinde.listAll()).data?.content || [];
        this.uitzetCodeOptions = (await this.$api.uitzetCode.listAll()).data?.content || [];
    }
}
