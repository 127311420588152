






































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {Idable} from '@/models/Idable';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {number} from '@/utils/validation';

@Component({
    components: {StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class CbList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'Naam', value: 'naam'},
        {text: 'Nummer', value: 'cbNummer'},
        {text: 'Straatnaam', value: 'adres.straat'},
        {text: 'Huisnummer', value: 'adres.huisnummer'},
        {text: 'Postcode', value: 'adres.postcode'},
        {text: 'Plaats', value: 'adres.plaatsnaam.omschrijving'},
        {text: '', value: 'action', sortable: false},
    ];

    public selectedPlaatsnaam: string[] =  [];

    public plaatsnaamOptions: Idable[] = [];
    public searchValue: any = {};

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.cb;
    }

    public get rules() {
        return {
            huisnummer: [number],
        };
    }

    public async created() {
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];
        this.search();
    }

    @Watch('selectedPlaatsnaam', { deep: true } )
    public onSelectionPlaatsnaamChanged() {
        if (this.selectedPlaatsnaam!.length !== 0) {
            this.searchValue['plaatsnaam.id:in'] = this.selectedPlaatsnaam.join('|');
        } else {
            this.searchValue['plaatsnaam.id:in'] = null;
        }
    }

    public async clear() {
        this.selectedPlaatsnaam = [];
        this.searchValue = {};
        this.search();
    }
}
