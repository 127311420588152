























import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {dateTime, exportStatusText, numberFormat, timeDiff} from '@/utils/filters';
import { url } from '@/utils/http';
import {IExportRunCreate} from '@/models/IExportRunCreate';
import Overige from '@/views/vestigingen/components/Overige.vue';
import VestigingAlgemeneInfo from '@/views/vestigingen/components/VestigingAlgemeneInfo.vue';
import ExportRunExportsList from '@/views/exports/ExportList.vue';

@Component({
    methods: {exportStatusText},
    filters: {
        exportStatusText,
        dateTime,
        timeDiff,
        numberFormat,
    },
    components: {
        ExportRunExportsList,
        VestigingAlgemeneInfo, Overige, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class ExportRunList extends Mixins(ListsObjects, AuthorizationMixin) {

    public tab: string = 'algemeen';

    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Beschrijving', value: 'description'},
        {text: 'Gestart', value: 'startedOn'},
        {text: 'Gestopt', value: 'stoppedOn'},
        {text: 'Aantal rijen', value: 'totalRecords'},
        {text: 'Status', value: 'status'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public exportTemplates: IExportRunCreate[] = [];

    public async created() {
        this.exportTemplates = (await this.$api.export.getExportTemplates()).data || [];
    }

    public get templates() {
        return this.exportTemplates;
    }

    public get api() {
        return this.$api.export;
    }

    public get hasAccess() {
        return this.isRoleDatabeheer || this.isRoleAnalist;
    }

    public url(path: string) {
        return url(path);
    }
}
