

























































































































































import {Component, Model, Prop, Vue} from 'vue-property-decorator';
import {IVestiging, MutatieCode, MutatieSoort} from '@/models/IVestiging';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import {IMutatie, MUTATIE_SOORT_ADRES, MUTATIE_SOORT_NAAM, MUTATIE_SOORT_SBI} from '@/models/IMutatie';
import {date, dateTime, time} from '@/utils/filters';

@Component({
    methods: {dateTime},
    filters: {dateTime, date, time},
    components: {ContactCard},
})
export default class VestigingMutatieInfo extends Vue {
    public MutatieCode = MutatieCode;
    public MutatieSoort = MutatieSoort;
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    public mutaties: IMutatie[] = [];

    public showVerhuisDatum(mutatie: IMutatie) {
        return mutatie.datumVestigingHuidigAdres && mutatie.mutatieSoort?.includes(MUTATIE_SOORT_ADRES);
    }

    public showNaamMutatieDatum(mutatie: IMutatie) {
        return mutatie.naamMutatieDatum && mutatie.mutatieSoort?.includes(MUTATIE_SOORT_NAAM);
    }

    public showSbiMutatieDatum(mutatie: IMutatie) {
        return mutatie.sbiMutatieDatum && mutatie.mutatieSoort?.includes(MUTATIE_SOORT_SBI);
    }

    public async created() {
        const id = this.$route.params.id;
        if (id) {
            this.mutaties = (await this.$api.mutatie.getByVestiging(id)).data || [];
        }
    }

    public hasChangedName(index: number) {
        return this.mutaties[index + 1] && this.mutaties[index].naam !== this.mutaties[index + 1].naam;
    }

    public hasChangedSbi(index: number) {
        return this.mutaties[index + 1] && this.mutaties[index].sbi?.code !== this.mutaties[index + 1].sbi?.code;
    }

    public hasChangedAdres(index: number) {
        return this.mutaties[index + 1] && this.mutaties[index].adres?.id !== this.mutaties[index + 1].adres?.id;
    }

    public hasChangedWijzeStart(index: number) {
        return this.mutaties[index + 1]
            && this.mutaties[index].wijzeStart?.id !== this.mutaties[index + 1].wijzeStart?.id;
    }

    public hasChangedDatumStart(index: number) {
        return this.mutaties[index + 1] && this.mutaties[index].datumStart !== this.mutaties[index + 1].datumStart;
    }

    public hasChangedWijzeEinde(index: number) {
        return this.mutaties[index + 1]
            && this.mutaties[index].wijzeEinde?.id !== this.mutaties[index + 1].wijzeEinde?.id;
    }

    public hasChangedDatumEinde(index: number) {
        return this.mutaties[index + 1] && this.mutaties[index].datumEinde !== this.mutaties[index + 1].datumEinde;
    }

    public hasChangedDatumBehandeling(index: number) {
        return this.mutaties[index + 1]
            && this.mutaties[index].datumBehandeling !== this.mutaties[index + 1].datumBehandeling;
    }

    public hasChangedKvk(index: number) {
        return this.mutaties[index + 1]
            && (
                this.mutaties[index].kvkDossiernummer !== this.mutaties[index + 1].kvkDossiernummer
                || this.mutaties[index].kvkVestigingNummer !== this.mutaties[index + 1].kvkVestigingNummer
            );
    }

    public getMutatieCodeString(mutatieCode: MutatieCode) {
        return MutatieCode[mutatieCode];
    }
}
