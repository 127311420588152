


































































































































































































































































import {Component, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {GrootteklasseBron, IVestiging} from '@/models/IVestiging';
import DateInput from '@/components/DateInput.vue';
import {IKvk} from '@/models/IKvk';
import KvkInfo from '@/views/kvk/KvkInfo.vue';
import {ICb} from '@/models/ICb';
import {formatDate, grootteklasseBronText} from '@/utils/filters';
import {Pagination} from '@/utils/api-tools/pagination';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {buildEmptyOpmerking, IOpmerking} from '@/models/IOpmerking';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';

@Component({
    filters: {},
    components: {KvkInfo, DateInput},
})
export default class IdentificatieInfo extends Mixins(AuthorizationMixin) {
    @Model('input')
    public vestiging!: IVestiging;

    public kvk: IKvk | null = null;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({default: null})
    public oldVestiging!: IVestiging | null;

    @Prop({required: false, default: 0})
    public validationErrorsCount!: number;

    public sbiOptions: Idable[] = [];
    public cbOptions: ICb[] = [];
    public grootteklasseOptions: Idable[] = [];
    public vestigingTypeOptions: Idable[] = [];
    public opmerkingEdit: IOpmerking | null = null;
    public oldOpmerking: string | null = null;

    public searchCbValue: string | null = null;
    public loadingCb: boolean = false;
    protected debouncedSearchCb = this.$_.debounce(() => {
        return this.searchCb();
    }, 300);

    @Watch('vestiging.grootteklasse', {deep: true})
    public onGrootteklasseManualChange() {
        if (this.oldVestiging != null && this.vestiging.grootteklasse != null &&
            (this.oldVestiging.grootteklasse === null ||
                this.vestiging.grootteklasse.id !== this.oldVestiging!.grootteklasse!.id)) {
            this.vestiging.grootteklasseBron = GrootteklasseBron.HM;
        }
    }

    @Watch('searchCbValue')
    public searchCbChanged() {
        if (this.searchCbValue && this.searchCbValue !== this.vestiging.cb?.cbNummer) {
            this.debouncedSearchCb();
        }
    }

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    public get rules() {
        return {
            rsin: [(v: string) => !v || /^[0-9]{4}-?[0-9]{2}-?[0-9]{3}$/.test(v) || 'RSIN nummer moet voldoen aan formaat XXXX-XX-XXX'],
            kvkDossiernummer: [(v: string) => !v || /^[0-9]{2}-?[0-9]{6}$/.test(v) || 'KvK dossiernummer moet voldoen aan formaat XX-XXXXXX'],
            naam: [required],
            straat: [required],
        };
    }

    public get opmerkingen() {
        if (!this.vestiging.opmerkingen) {
            return [];
        }
        return this.vestiging.opmerkingen.sort(
            (a, b) => a.toegevoegdOp > b.toegevoegdOp ? -1 : a.toegevoegdOp < b.toegevoegdOp ? 1 : 0);
    }

    public get kvkDossiernummer() {
        const value = this.vestiging.kvkDossiernummer;
        if (value && value.length >= 3) {
            return value.substr(0, 2) + '-' + value.substr(2);
        }
        return this.vestiging.kvkDossiernummer;
    }

    public set kvkDossiernummer(value: any) {
        this.vestiging.kvkDossiernummer = value.replace('-', '');
    }

    public get allowEditGrootteklasse() {
        return this.vestiging.grootteklasseBron !== GrootteklasseBron.ENQ && this.canUpdate;
    }

    public get grootteklasseLabel() {
        if (this.vestiging.grootteklasse && this.vestiging.grootteklasseBron) {
            return '  (' + grootteklasseBronText(this.vestiging.grootteklasseBron) + ')';
        } else {
            return null;
        }
    }

    public set rsin(value: any) {
        this.vestiging.rsin = value.replaceAll('-', '');
    }

    public get rsin() {
        const value = this.vestiging.rsin;
        if (value && value.length >= 7) {
            return value.substr(0, 4) + '-' + value.substr(4, 2) + '-' + value.substr(6);
        }
        return this.vestiging.rsin;
    }

    // use a method instead of inline-function, because inline-function causes issues with .sync option.
    // (see https://github.com/vuetifyjs/vuetify/issues/6635#issuecomment-528444479)
    public cbItemText(cb: ICb) {
        return cb.cbNummer + ' - ' + cb.naam;
    }

    public addOpmerking() {
        const newOpmerking = buildEmptyOpmerking();
        this.opmerkingEdit = newOpmerking;
        this.openOpmerkingEdit(newOpmerking);
    }

    public openOpmerkingEdit(opmerkingObject: IOpmerking) {
        this.opmerkingEdit = opmerkingObject;
        this.oldOpmerking = opmerkingObject.opmerking;
    }

    public deleteOpmerking(opmerkingObject: IOpmerking) {
        const index = this.vestiging!.opmerkingen!.indexOf(opmerkingObject, 0);
        if (index > -1) {
            this.vestiging!.opmerkingen!.splice(index, 1);
        }
    }

    public cancelOpmerking() {
        if (this.oldOpmerking) {
            this.opmerkingEdit!.opmerking = this.oldOpmerking;
        }
        this.opmerkingEdit = null;
        this.oldOpmerking = null;
    }

    public saveOpmerking() {
        if (this.opmerkingEdit && !this.oldOpmerking) {
            if (!this.vestiging.opmerkingen) {
                this.vestiging.opmerkingen = [];
            }
            this.vestiging.opmerkingen.unshift(this.opmerkingEdit);
        }
        this.opmerkingEdit = null;
        this.oldOpmerking = null;
    }

    public getLabel(vestigingOpmerking: IOpmerking) {
        const date = vestigingOpmerking.toegevoegdOp ? vestigingOpmerking.toegevoegdOp : '';
        if (vestigingOpmerking.toegevoegdDoorNaam) {
            return 'Opmerking (door ' + vestigingOpmerking.toegevoegdDoorNaam + ') ' + formatDate(date);
        } else if (date) {
            return 'Opmerking ' + formatDate(date);
        } else {
            return 'Opmerking';
        }
    }

        public async created() {
            this.sbiOptions = (await this.$api.sbi.listAll()).data?.content || [];
            this.grootteklasseOptions = (await this.$api.grootteklasse.listAll()).data?.content || [];
            this.vestigingTypeOptions = (await this.$api.vestigingType.listAll()).data?.content || [];
            this.kvk = this.vestiging.kvkId ? ((await this.$api.kvk.get(this.vestiging.kvkId)).data || null) : null;
            this.searchCbValue = this.vestiging.cb?.cbNummer?.toString() ?? '';
            if (this.searchCbValue) {
                this.searchCb();
            }
        }

        public async searchCb() {
            const searchValue = (this.searchCbValue || '').replace(/\D/g, '');
            if (searchValue.length > 3) {
                this.loadingCb = true;
                this.cbOptions = (await this.$api.cb.getLightList(searchValue)).data as ICb[] || [];
                this.loadingCb = false;
            }
        }
    }
