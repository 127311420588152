






































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import VestigingAlgemeneInfo from '@/views/vestigingen/components/VestigingAlgemeneInfo.vue';
import ContactInfo from '@/views/vestigingen/components/ContactInfo.vue';
import VestigingMutatieInfo from '@/views/vestigingen/components/VestigingMutatieInfo.vue';
import DateInput from '@/components/DateInput.vue';
import WerkzamePersonen from '@/views/vestigingen/components/WerkzamePersonen.vue';
import StatusColorChip from '@/components/StatusColorChip.vue';
import Overige from '@/views/vestigingen/components/Overige.vue';
import {IKvk} from '@/models/IKvk';
import {IVestiging} from '@/models/IVestiging';
import {formatDate} from '@/utils/filters';
import KvkLinkToVestigingCompareInfo from '@/views/kvk/KvkLinkToVestigingCompareInfo.vue';
import {buildEmptyKvkLinkOptions, IKvkLinkOptions} from '@/models/IKvkLinkOptions';
import ChangesByManualKvkLink from '@/views/kvk/KvkLinkToVestigingChanges.vue';
import {IGebruiksfunctie} from '@/models/constants/IGebruiksfunctie';
import {IAdres} from '@/models/IAdres';

@Component({
    filters: {
        formatDate,
    },
    components: {
        ChangesByManualKvkLink,
        KvkLinkToVestigingCompareInfo,
        StatusColorChip,
        Overige,
        VestigingMutatieInfo,
        WerkzamePersonen,
        DateInput,
        ContactInfo,
        VestigingAlgemeneInfo,
        AdresInfo,
        ContactCard,
        SimpleMessageWrapper,
    },
})
export default class KvkLinkToVestigingTable extends Mixins(ShowsMessages, AuthorizationMixin) {
    @Prop({required: true})
    private kvk!: IKvk;
    private matchingVestigingen: IVestiging[] | null = [];
    private selectedVestiging: IVestiging | null = null;
    private showConfirmLinkDialog: boolean = false;
    private showLinkChangesDialog: boolean = false;
    private kvkLinkOptions: IKvkLinkOptions | null = null;
    private searchVestigingNummer: string = '';
    private searchedVestiging: IVestiging| null = null;

    private headers: any[] = [
        {text: 'Vestigingnummer', value: 'vestigingNummer'},
        {text: 'Bedrijfsnaam', value: 'naam'},
        {text: 'Straat', value: 'adres.straat'},
        {text: 'Nr', value: 'adres.huisnummer'},
        {text: 'Ltr', value: 'adres.huisletter'},
        {text: 'Toev', value: 'adres.toevoeging'},
        {text: 'Postcode', value: 'adres.postcode'},
        {text: 'Plaats', value: 'adres.plaatsnaam.omschrijving'},
        {text: 'Functie', value: '', sortable: false},
        {text: 'Afgevoerd', value: 'datumEinde'},
        {text: 'Extra', value: 'extra'},
        {text: 'Actie', value: '', sortable: false, searchable: false},
    ];

    private debouncedLoadData = this.$_.debounce(() => {
        return this.loadData();
    }, 300);


    @Watch('kvk', {deep: true, immediate: true})
    public async onChangeKvk() {
        await this.debouncedLoadData();
    }

    public getGebruiksfunctieCodes(adres: IAdres) {
        return adres.gebruiksfunctieSet?.map((functie) => functie.code).join(', ') || '';
    }

    public getGebruiksfunctieOmschrijvingen(adres: IAdres) {
        return adres.gebruiksfunctieSet
            ?.map((functie) => functie.code + ' = ' + functie.omschrijving)
            .join('<br />') || '';
    }

    public async created() {
        await this.debouncedLoadData();
    }

    public async loadData() {
        this.searchedVestiging = null;
        this.searchVestigingNummer = '';
        this.matchingVestigingen = (await this.$api.kvk.findMatchingVestigingen(this.kvk)).data;
    }

    public onSelectVestiging(vestiging: IVestiging) {
        this.selectedVestiging = vestiging;
        this.kvkLinkOptions = buildEmptyKvkLinkOptions(
            vestiging.id as string,
            this.kvk.id as unknown as string,
            vestiging.vestigingNummer ?? '',
        );
        this.showConfirmLinkDialog = true;
    }

    public search() {
        if (this.searchVestigingNummer) {
            this.$api.vestiging.getByVestigingNummer(this.searchVestigingNummer).then((response) => {
                if (!this.matchingVestigingen) {
                    this.matchingVestigingen = [];
                }
                if (!response.data) {
                    alert('Er is geen vestiging gevonden voor dit vestigingnummer');
                }
                if (response.data) {
                    this.searchedVestiging = response.data;
                    if (!this.matchingVestigingen.find((vestiging) => vestiging.id === this.searchedVestiging?.id)) {
                        this.matchingVestigingen.push(this.searchedVestiging);
                    }
                }
            });
        }
    }

    public closeChangesDialog() {
        this.selectedVestiging = null;
        this.kvkLinkOptions = null;
        this.showConfirmLinkDialog = false;
        this.showLinkChangesDialog = false;
    }

    public closeConfirmLinkKvkDialog() {
        this.showConfirmLinkDialog = true;
        this.showLinkChangesDialog = false;
    }

    public linkKvk() {
        this.showConfirmLinkDialog = false;
        this.showLinkChangesDialog = true;
    }

    public confirmLinkKvk() {
        this.showConfirmLinkDialog = false;
        this.showLinkChangesDialog = false;
        this.$emit('linkToKvk', this.kvkLinkOptions);
    }
}
