import {Idable} from '@/models/Idable';
import {IVestiging} from '@/models/IVestiging';

export interface IContact extends Idable {
    id: string;
    naam: string|null;
    telefoon: string|null;
    mobiel: string|null;
    toestel: string|null;
    email: string|null;
    website: string|null;
    type: ContactType|null;
}

export enum ContactType {
    STANDAARD = 0,
    VESTIGING_GEBOUW = 1,
    CONTACT_PERSOON = 2,
}

export const contactTypeOptions: Array<{ label: string, value: ContactType }> = [
    {label: 'STANDAARD', value: ContactType.STANDAARD},
    {label: 'VESTIGING_GEBOUW', value: ContactType.VESTIGING_GEBOUW},
    {label: 'CONTACT_PERSOON', value: ContactType.CONTACT_PERSOON},
];

export function buildEmptyContact(type: ContactType = ContactType.STANDAARD): IContact {
    return {
        id: '',
        naam: '',
        telefoon: '',
        mobiel: '',
        toestel: '',
        email: '',
        website: '',
        type,
    };
}
