import {Idable} from '@/models/Idable';

export interface ISbi extends Idable {
    code: string;
    naam: string;
    omschrijving: string;
    malePercentage: number;
    sectionLetter: string;
}

export const sectionLetterOptions: string[] = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U',
];
