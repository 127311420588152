

















































import {Component, Mixins, Model, Prop} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import StartEindeInfo from '@/views/vestigingen/components/StartEindeInfo.vue';
import {IKvkLinkOptions, kvkLinkOptionChoices} from '@/models/IKvkLinkOptions';
import IdentificatieInfo from '@/views/vestigingen/components/IdentificatieInfo.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import KvkDetails from '@/views/kvk/KvkDetails.vue';
import {IKvk} from '@/models/IKvk';

@Component({
    filters: {},
    components: {KvkDetails, IdentificatieInfo, StartEindeInfo, ContactCard, AdresInfo,
        // Unsolved mystery: the vestiging-algemene-info is not found when imported normally.
        VestigingAlgemeneInfo: () => import('@/views/vestigingen/components/VestigingAlgemeneInfo.vue'),
    },
})
export default class KvkLinkToVestigingCompareInfo extends Mixins(ShowsMessages, AuthorizationMixin) {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({required: true})
    public kvk!: IKvk;

    @Prop({required: true})
    public kvkLinkOptions!: IKvkLinkOptions;

    public kvkLinkOptionChoices: Array<{ label: string, value: string }> = kvkLinkOptionChoices;

    public canUpdate: boolean = false;
    //
    // public refresher: number = 0;
    //
    // @Watch('vestiging' , {immediate: true, deep: true})
    // public onChange() {
    //     this.refresher++;
    // }
}
