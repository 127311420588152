





















import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import NietBagConformDialog from '@/views/kvk-upload-run/controlelijst/NietBagConformDialog.vue';
import {IVestiging} from '@/models/IVestiging';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {IKvk} from '@/models/IKvk';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    components: {
        StatusColorChip,
        NietBagConformDialog,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class Cl1NietBagConform extends Mixins(ListsObjects, AuthorizationMixin) {
    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun;

    public openModal: boolean = false;

    public refresher: number = 0;

    public drawer: boolean = true;

    public headers: any[] = [
        {text: '', value: 'action', sortable: false},
        {text: 'KvK-Vestigingsnummer', value: 'vestigingsnummer'},
        {text: 'Dossiernummer', value: 'dossiernummer'},
        // {text: 'Subdossier-nummer', value: 'subdossiernummer'},
        {text: 'Bedrijfsnaam', value: 'bedrijfsnaam'},
        {text: 'Straatnaam', value: 'straatnaam'},
        {text: 'Huisnummer', value: 'huisnummer'},
        {text: 'Postcode', value: 'postcode'},
        {text: 'Woonplaats', value: 'woonplaats'},
        // {text: 'Website', value: 'website'},
        {text: 'Mutatie categorie', value: 'mutatieCategorie'},
    ];

    public selectedKvkMutatie: IKvkMutatie | null = null;

    public get api() {
        return this.$api.kvkMutatie;
    }

    protected async search() {
        const kvkUploadRunIdKey = 'upload_run_id';
        this.searchValue[kvkUploadRunIdKey] = this.kvkUploadRun.id;
        const correctieType = 'correctielijst_type';
        this.searchValue[correctieType] = '0';
        const criteria: SearchCriterion[] = [];
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                const value = this.searchValue[key];
                if (value != null) {
                    criteria.push(new SearchCriterion(key, value));
                }
            }
        }
        this.searchCriteria = criteria;
        this.debouncedLoadData();
    }

    private openSelectBagAdresModal(kvkMutatie: IKvkMutatie) {
        if (!kvkMutatie?.vestigingId) {
            alert('Geen vestiging gevonden voor selectie.');
        }
        this.selectedKvkMutatie = kvkMutatie;
        this.openModal = true;
    }

    private closeSelectBagAdresModal() {
        this.selectedKvkMutatie = null;
        this.openModal = false;
    }

    private async completeSelectBagAdresModal() {
        this.selectedKvkMutatie!.manualCompletedAt = Math.floor((new Date()).getTime() / 1000);
        await this.$api.kvkMutatie.save(this.selectedKvkMutatie!);
        await this.updateClLists();
    }

    private async updateClLists() {
        this.closeSelectBagAdresModal();
        this.refresher++;
        this.$emit('updateSize');
    }

}
