import {Idable} from '@/models/Idable';
import {$t} from '@/plugins/i18n';
import {IRegister} from '@/models/IRegister';
import {IGemeente} from '@/models/constants/IGemeente';

export interface IGebruiker extends Idable {
    gebruikersnaam: string;
    email: string;
    naam: string;
    wachtwoord?: string;
    geblokkeerd: boolean;
    rollen: Role[];
    register: IRegister;
    gemeentes: IGemeente[];
    mfa: boolean;
    mfaDisabled: boolean;
}

export enum Role {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    DATABEHEERDER = 'DATABEHEERDER',
    ANALIST = 'ANALIST',
    USER = 'USER',
}

export const roleOptions: Array<{ label: string, value: Role }> = [
    // note that SUPER_ADMIN should not be a selectable choice.
    {label: $t('roles.ADMIN').toString(), value: Role.ADMIN},
    {label: $t('roles.DATABEHEERDER').toString(), value: Role.DATABEHEERDER},
    {label: $t('roles.ANALIST').toString(), value: Role.ANALIST},
    {label: $t('roles.USER').toString(), value: Role.USER},
];

export function buildEmptyUser(): IGebruiker {
    return {
        gebruikersnaam: '',
        geblokkeerd: false,
        naam: '',
        rollen: [],
        gemeentes: [],
    } as unknown as IGebruiker;
}
