import {ImportJobStatus} from '@/models/IImportRun';
import {MappedObject} from '@/models/IImportTemplate';
import {ExportJobStatus} from '@/models/IExportRun';
import {FilterType} from '@/models/IFilter';
import {KvkUploadStatus, monthOptions} from '@/models/IKvkUploadRun';
import {GrootteklasseBron} from '@/models/IVestiging';

export function zeropad(value: number|string|null, length: number) {
    if (value == null || value === '') {
        return '';
    }
    return String(value).padStart(length, '0');
}

export function importStatusText(status: ImportJobStatus) {
    switch (status) {
        case ImportJobStatus.CREATED:
            return 'Aangemaakt';
        case ImportJobStatus.BUSY:
            return 'In behandeling';
        case ImportJobStatus.ERROR:
            return 'Fout';
        case ImportJobStatus.HALTED:
            return 'Gestopt';
        case ImportJobStatus.FINISHED_ERROR:
            return 'Voltooid met fouten';
        case ImportJobStatus.FINISHED_SUCCESS:
            return 'Voltooid';
        case ImportJobStatus.CANCELED:
            return 'Afgebroken';
        case ImportJobStatus.PENDING:
            return 'In wachtrij';
        case ImportJobStatus.CANCELED_SEQUENCE:
            return 'Afgebroken door vorige import';
    }
}

export function grootteklasseBronText(bron: GrootteklasseBron) {
    switch (bron) {
        case GrootteklasseBron.HM:
            return 'Handmatig';
        case GrootteklasseBron.KVK:
            return 'KvK';
        case GrootteklasseBron.ENQ:
            return 'Enquete';
    }
}

export function exportStatusText(status: ExportJobStatus) {
    switch (status) {
        case ExportJobStatus.CREATED:
            return 'Aangemaakt';
        case ExportJobStatus.BUSY:
            return 'In behandeling';
        case ExportJobStatus.ERROR:
            return 'Fout';
        case ExportJobStatus.HALTED:
            return 'Gestopt';
        case ExportJobStatus.FINISHED_ERROR:
            return 'Voltooid met fouten';
        case ExportJobStatus.FINISHED_SUCCESS:
            return 'Voltooid';
    }
}

export function numberFormat(value: number) {
    return Intl.NumberFormat('nl-NL').format(value);
}

export function filterTypeText(filterType: FilterType) {
    switch (filterType) {
        case FilterType.EXPRESSION:
            return 'EXPR';
        case FilterType.AND:
            return 'EN';
        case FilterType.OR:
            return 'OF';
    }
}

export function timeDiff(dateString: string|number, compareDateString: string|number|null = null) {
    const dateOjb = convertToDate(dateString);
    const compareDateObj = compareDateString ? convertToDate(compareDateString) : new Date();
    const timeAgoInSeconds = Math.round((compareDateObj.getTime() - dateOjb.getTime()) / 1000);
    const days = Math.floor(timeAgoInSeconds / 86400);
    const daysString = days + (days === 1 ? ' dag' : ' dagen');
    const hours = Math.floor((timeAgoInSeconds % 86400) / 3600);
    const hoursString = hours + ' uur';
    if (days > 0) {
        return days > 30 ? daysString : daysString + ', ' + hoursString;
    }
    const minutes = Math.floor((timeAgoInSeconds % 3600) / 60);
    const minutesString = minutes + (minutes === 1 ? ' minuut' : ' minuten');
    if (hours > 0) {
        return hoursString + ', ' + minutesString;
    }
    const seconds = timeAgoInSeconds % 60;
    const secondsString = seconds + (seconds === 1 ? ' seconde' : ' seconden');
    if (minutes > 0) {
        return minutesString + ', ' + secondsString;
    }
    return secondsString;
}

export function mappedObjectText(mappedObject: MappedObject) {
    switch (mappedObject) {
        case MappedObject.VESTIGING:
            return 'Vestiging';
        case MappedObject.WERKZAME_PERSONEN:
            return 'Werkzame personen';
        case MappedObject.CB:
            return 'Centrale berichtgever';
        case MappedObject.ADRES:
            return 'Adres';
    }
}

export function dateTime(dateString: string|number) {
    if (dateString != null) {
        return Intl.DateTimeFormat('nl', { dateStyle: 'short', timeStyle: 'short' })
            .format(convertToDate(dateString));
    }
    return '';
}

export function time(dateString: string|number) {
    if (dateString != null) {
        return Intl.DateTimeFormat('nl', { timeStyle: 'short' })
            .format(convertToDate(dateString));
    }
    return '';
}

export function date(dateString: string|number) {
    if (dateString != null) {
        return Intl.DateTimeFormat('nl', { dateStyle: 'short'})
            .format(convertToDate(dateString));
    }
    return '';
}

export function convertToDate(dateString: string|number): Date {
    if (isNaN(dateString as any)) {
        return new Date(dateString);
    }
    const dateNumber: number = parseInt(dateString.toString(), 10);
    // If timestamp in milliseconds
    // - any timestamp above 5000000000 would be past the year 2128; we assume this never happens
    // - any date blow -30000000000 would be before the year 1000; we assume this never happens
    if (dateNumber > 5000000000 || dateNumber < -30000000000) {
        return new Date(dateNumber);
    }

    // if timestamp in seconds
    return new Date(dateNumber * 1000);
}

export function formatDate(dateInput: any) {
    if (!dateInput) {
        return null;
    }
    const dateObj = convertToDate(dateInput);
    return Intl.DateTimeFormat('nl', { timeZone: 'Europe/Amsterdam' }).format(dateObj);
}

export function uploadStatusText(status: KvkUploadStatus) {
    switch (status) {
        case KvkUploadStatus.UPLOAD_IN_PROGRESS:
            return 'Upload in behandeling';
        case KvkUploadStatus.UPLOAD_DONE:
            return 'Upload voltooid';
        case KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS:
            return 'Upload goedkeuren in behandeling';
        case KvkUploadStatus.CONFIRM_UPLOAD_DONE:
            return 'Upload goedgekeuren voltooid';
        case KvkUploadStatus.MUTATIONS_IN_PROGRESS:
            return 'Mutaties verwerken';
        case KvkUploadStatus.MUTATIONS_DONE:
            return 'Mutaties voltooid';
        case KvkUploadStatus.NEW_LINK_IN_PROGRESS:
            return 'Verwerking mogelijke opnames register';
        case KvkUploadStatus.NEW_LINK_DONE:
            return 'Nieuwe koppelingen voltooid';
        case KvkUploadStatus.AUTO_NEW_PREPARE_IN_PROGRESS:
            return 'Voorbereiden nieuwe inschrijvingen';
        case KvkUploadStatus.AUTO_NEW_PREPARE_DONE:
            return 'Voorbereiden nieuwe inschrijvingen voltooid';
        case KvkUploadStatus.AUTO_NEW_IN_PROGRESS:
            return 'Nieuwe inschrijvingen';
        case KvkUploadStatus.AUTO_NEW_DONE:
            return 'Nieuwe inschrijvingen verwerkt';
        case KvkUploadStatus.MANUAL_SELECTION:
            return 'Handmatige controle';
        case KvkUploadStatus.MANUAL_DONE:
            return 'Handmatige controle voltooid';
        case KvkUploadStatus.REVERTED:
            return 'Geannuleerd';
    }
}

export function numberToMonth(monthNumber: number) {
    if (!monthNumber || monthNumber < 0 || monthNumber > 12) {
        return '';
    }
    const month = monthOptions.find((m) => m.value === monthNumber);
    return month!.label;
}

