import {Pagination, SortDirection} from '@/utils/api-tools/pagination';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, url} from '@/utils/http';

export class GenericConstantApi<T> extends GenericApi<T> {
    public async listAll(search: ISearchCriterion[] = [], sort: string = 'code', direction: SortDirection = 'ASC'):
        Promise<IWrapperResponse<IPaginationResponse<T>>> {
        return this.list(new Pagination(0, -1, [sort], [direction]), search);
    }

    public async getByCode(code: string) {
        const uri = new URL(url(this.path + `/by-code/${code}`));
        const result = await get(uri.href);
        return result.data as unknown as IWrapperResponse<T>;
    }
}
