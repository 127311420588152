export interface IKvkLinkOptions {
    kvkId: string | null;
    vestigingId: string | null;
    options: string[];
    vestigingNummer: string | number | null;
}

export const kvkLinkOptionChoices: Array<{ label: string, value: string }> = [
    { value: 'name', label: 'Naam' },
    { value: 'sbi', label: 'SBI' },
    { value: 'grootteklasse', label: 'Grootteklasse' },
    { value: 'address', label: 'Adres' },
    { value: 'phone', label: 'Telefoon' },
    { value: 'mobile', label: 'Mobiel' },
    { value: 'website', label: 'Website' },
];

export function buildEmptyKvkLinkOptions(
    vestigingObjectId: string | null = null,
    kvkObjectId: string | null = null,
    vestigingNummer: string | number | null = null,
): IKvkLinkOptions {
    return {
        kvkId: kvkObjectId,
        vestigingId: vestigingObjectId,
        vestigingNummer,
        options: ['name', 'sbi', 'grootteklasse', 'address', 'phone', 'mobile', 'website'],
    } as IKvkLinkOptions;
}
