

































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import Cl1NietBagConform from '@/views/kvk-upload-run/controlelijst/Cl1NietBagConform.vue';
import {IVestiging} from '@/models/IVestiging';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import CorrespondentieAdresInfo from '@/views/vestigingen/components/CorrespondentieAdresInfo.vue';
import StartEindeInfo from '@/views/vestigingen/components/StartEindeInfo.vue';
import {getKvkMutatieRules, KvkMutatieRule} from '@/models/KvkMutatieRule';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    components: {
        StartEindeInfo,
        CorrespondentieAdresInfo,
        AdresInfo,
        NietBagConform: Cl1NietBagConform,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class MutatieCompareView extends Mixins(AuthorizationMixin) {
    @Prop({required: true})
    public kvkMutatie!: IKvkMutatie;

    @Prop({default: null})
    public vestiging!: IVestiging|null;

    @Prop({type: String, default: 'Wijzigingen'})
    private cardTitle!: string;

    @Prop()
    private selected!: boolean;

    private vestigingNew: IVestiging | null = null;

    private get vestigingOld(): IVestiging {
        return JSON.parse(this.kvkMutatie.oldVestigingData) as IVestiging;
    }

    private created() {
        // TODO/FIXME: ipv op vestiging, zou dit mee-moeten in unforme approve/revert api-callback voor mutatie
        if (this.vestigingNew) {
            this.vestigingNew.mutatieToelichting = '';
        }
        this.loadVestigingNew();
    }

    @Watch('kvkMutatie')
    private async loadVestigingNew() {
        if (this.kvkMutatie?.vestigingId) {
            const response = await this.$api.vestiging.get(this.kvkMutatie?.vestigingId);
            this.vestigingNew = response.data!;
        } else {
            this.vestigingNew = null;
        }
    }

    //
    // public get vestigingCompareRules(): VestigingMutatieChange[] {
    //     if (this.vestigingNew) {
    //         // TODO/FIXME: wanneer een mutatie is teruggedraaid klopt de 'vestigingNew' waarde niet meer.
    //         return getVestigingMutatieChanges(this.vestigingOld, this.vestigingNew);
    //     }
    //     return [];
    // }

    public get kvkMutatieRules(): KvkMutatieRule[] {
        return getKvkMutatieRules(this.vestiging, this.kvkMutatie.kvkCma);
    }
}
