import { IWrapperResponse } from '@/api/generic';
import { IGebruiker } from '@/models/IGebruiker';
import store from '@/store';
import { post, url } from '@/utils/http';
import { IMfaGenerate } from '@/models/IMfaGenerate';
import { IMfaManage } from '@/models/IMfManage';

/**
 * Api Accessor for Authentication
 */
export class AuthenticationApi {
    public async login(username: string, password: string) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        // If workstation parameter is provided, then certain roles can log in without passwords
        // params.append('workstationKey', "SomeIdentifier");
        // params.append('workstationName', "SomeWorkstationName");
        const result = await post(url('login'), params);
        if (result && result.status === 200) {
            if (!result.data.data.mfa) {
                store.commit('setAuthenticated', true);
                store.commit('setUser', result.data.data);
            }
        }
        return result.data as IWrapperResponse<IGebruiker>;
    }

    public async mfaLogin(token: string) {
        const params = new URLSearchParams();
        params.append('token', token);

        const result = await post(url('mfa/login'), params);
        if (result && result.status === 200) {
            store.commit('setAuthenticated', true);
            store.commit('setUser', result.data.data);
        }
    }

    public async mfaGenerate(reset: boolean = false) {
        const params = new URLSearchParams();
        if (reset) {
            params.append('reset', String(reset));
        }

        const result = await post(url('mfa/generate'), params);
        return result.data as IWrapperResponse<IMfaGenerate>;
    }

    public async mfaManage(enabled: boolean, token: string) {
        const result = await post(url('mfa/manage'), {
            token,
            mfaEnabled: enabled,
        });
        return result.data as IWrapperResponse<IMfaManage>;
    }

    public async logout() {
        const result = store.dispatch('logout');
    }
}
