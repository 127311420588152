import _ from 'lodash';
import i18n from '@/plugins/i18n';


const $t = i18n.t.bind(i18n);

export function minLength(size: number) {
    return (value: string) => (value && value.length < size) ?
        $t('validation.minLength', {size}) : true;
}

export function min(minValue: number) {
    return (value: string) => (value != null && (parseInt(value, 10)) < minValue) ?
        $t('validation.min', {minValue}) : true;
}

export function max(maxValue: number) {
    return (value: string) => (value != null && (parseInt(value, 10)) > maxValue) ?
        $t('validation.max', {minValue: maxValue}) : true;
}

export function maxDouble(maxValue: number) {
    return (value: string) => (value != null && (parseFloat(value)) > maxValue) ?
        $t('validation.max', {minValue: maxValue}) : true;
}

export function minDouble(minValue: number) {
    return (value: string) => (value != null && (parseFloat(value)) < minValue) ?
        $t('validation.min', {minValue}) : true;
}

export function maxLength(size: number) {
    return (value: string) => (value && value.length > size) ?
        $t('validation.maxLength', {size}) : true;
}

export function required(value: string) {
    let empty = false;
    if (value == null) {
        empty = true;
    } else if (_.isNumber(value)) {
        empty = (value as unknown as number) === 0;
    } else if (_.isEmpty(value)) {
        empty = true;
    }
    return empty ? $t('validation.required') : true;
}

export function number(value: string) {
    let noNumber = false;
    if (value == null) {
        noNumber = false;
    } else if (_.isEmpty(value)) {
        noNumber = false;
    } else if (!/^\d+$/.test(value)) {
        noNumber = true;
    }
    return noNumber ? $t('validation.number') : true;
}

export function email(value: string) {
    let valid = false;
    if (/.+@.+/.test(value) || !value) {
        valid = true;
    }
    return valid ? true : $t('validation.email');
}
