import {Idable} from '@/models/Idable';
import {IVestiging, MutatieCode} from '@/models/IVestiging';
import {IGebruiker} from '@/models/IGebruiker';
import {IMutatieSoort} from '@/models/IMutatieSoort';
import {IAdres} from '@/models/IAdres';
import {ISbi} from '@/models/constants/ISbi';
import {IWijzeStart} from '@/models/constants/IWijzeStart';
import {IWijzeEinde} from '@/models/constants/IWijzeEinde';
import {IBehandelingType} from '@/models/constants/IBehandelingType';

export interface IMutatie extends Idable {
    vestiging: IVestiging;
    kvkDossiernummer: string|null;
    kvkVestigingNummer: string|null;
    mutatieCode: null|MutatieCode;
    mutatieSoortSet: null|IMutatieSoort[];
    mutatieSoort: string|null;
    toegevoegdOp: number;
    gewijzigdOp: number;
    toegevoegdDoor: IGebruiker;
    gewijzigdDoor: IGebruiker;
    naam: string|null;

    adres: IAdres|null;
    sbi: ISbi|null;
    wijzeStart: IWijzeStart|null;
    datumStart: number|null;
    wijzeEinde: IWijzeEinde|null;
    datumEinde: number|null;
    mutatieToelichting: string|null;

    datumBehandeling: number|null;
    behandelingType: IBehandelingType|null;

    sbiMutatieDatum: number|null;
    naamMutatieDatum: number|null;
    datumVestigingHuidigAdres: number|null;
}

export const MUTATIE_SOORT_ADRES = 'ADRES';
export const MUTATIE_SOORT_NAAM = 'NAAM';
export const MUTATIE_SOORT_SBI = 'SBI';
