


















    import {Component, Model, Prop, Vue} from 'vue-property-decorator';
    import {IVestiging} from '@/models/IVestiging';
    import CorrespondentieAdresInfo from '@/views/vestigingen/components/CorrespondentieAdresInfo.vue';
    import OverigeInfo from '@/views/vestigingen/components/OverigeInfo.vue';

    @Component({
    filters: {},
    components: {OverigeInfo, CorrespondentieAdresInfo},
})
export default class Overige extends Vue {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({type: Boolean, default: false})
    public isAdmin!: boolean;

    @Prop()
    public validationErrorsCount!: number;

    public handleInvalidInput() {
        this.$emit('invalidInput');
    }
}
