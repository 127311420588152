








































































































































































































































































































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {max, maxLength, required} from '@/utils/validation';
import {BagKoppelingKwaliteit, IAdres} from '@/models/IAdres';
import {Idable} from '@/models/Idable';
import DateInput from '@/components/DateInput.vue';
import {GEBRUIKSFUNCTIE_MEERDERE_CODE, IGebruiksfunctie} from '@/models/constants/IGebruiksfunctie';
import {IBagAdres} from '@/models/IBagAdres';

@Component({
    components: {DateInput},
})
export default class AdresInfo extends Vue {
    public GEBRUIKSFUNCTIE_MEERDERE_CODE = GEBRUIKSFUNCTIE_MEERDERE_CODE;
    @Model('input')
    public adres!: IAdres;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({type: Boolean, default: false})
    public readonly!: boolean;

    @Prop({type: Boolean, default: false})
    public hideBagFields!: boolean;

    @Prop()
    public validationErrorsCount!: number;

    public gebruiksfunctieOptions: IGebruiksfunctie[] = [];
    public gemeenteOptions: Idable[] = [];
    public typePandOptions: Idable[] = [];
    public xyKwaliteitOptions: Idable[] = [];
    public plaatsnaamOptions: Idable[] = [];
    public buurtOptions: Idable[] = [];
    public bedrijventerreinOptions: Idable[] = [];

    public bagAdres: IBagAdres|null = null;

    public searchBuurtValue: string | null = null;
    public loadingBuurt: boolean = false;
    public searchBedrijventerreinValue: string | null = null;
    public loadingBedrijventerrein: boolean = false;

    protected debouncedLoadCheckBagAdres = this.$_.debounce(() => {
        return this.checkBagAdres();
    }, 200);

    protected debouncedSearchBedrijventerrein = this.$_.debounce(() => {
        return this.searchBedrijventerrein();
    }, 300);

    protected debouncedSearchBuurt = this.$_.debounce(() => {
        return this.searchBuurt();
    }, 300);

    public get rules() {
        return {
            naam: [required],
            straat: [required],
            huisnummer: [required, maxLength(5)],
            postcode: [required, maxLength(6)],
            plaatsnaam: [required],
            gemeente: [required],
            huisletter: [maxLength(1)],
            toevoeging: [maxLength(15)],
            bagOppervlak: [max(9999999)],
            bagNummeraanduiding: [max(9999999999999999999)],
            bagVerblijfsobject: [max(9999999999999999999)],
            xCoord: [max(999999999)],
            yCoord: [max(999999999)],
        };
    }

    public get hasMatchingBagAdres() {
        return (!!this.bagAdres && !!this.bagAdres.id);
    }

    @Watch('searchBuurtValue')
    public searchBuurtChanged() {
        if (this.searchBuurtValue && this.searchBuurtValue !== this.adres.buurt?.omschrijving) {
            this.debouncedSearchBuurt();
        }
    }

    @Watch('searchBedrijventerreinValue')
    public searchBedrijventerreinChanged() {
        if (this.searchBedrijventerreinValue &&
            this.searchBedrijventerreinValue !== this.adres.bedrijventerrein?.omschrijving) {
            this.debouncedSearchBedrijventerrein();
        }
    }

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    @Watch('adres.huisnummer')
    @Watch('adres.postcode')
    @Watch('adres.huisletter')
    @Watch('adres.toevoeging')
    public async adresChange() {
        await this.debouncedLoadCheckBagAdres();
    }

    public async checkBagAdres() {
        if (this.adres.postcode) {
            this.adres.postcode = this.adres.postcode?.toUpperCase();
        }
        const bag = await this.$api.bagAdres.findByAdres(this.adres);
        if (bag && bag.data) {
            this.bagAdres = bag.data;
            if (this.bagAdres.gemeente) {
                this.adres.gemeente = this.bagAdres.gemeente;
            }
            if (this.bagAdres.plaatsnaam) {
                this.adres.plaatsnaam = this.bagAdres.plaatsnaam;
            }
            if (this.bagAdres.buurt) {
                this.adres.buurt = this.bagAdres.buurt;
            }
            if (this.bagAdres.bedrijventerrein) {
                this.adres.bedrijventerrein = this.bagAdres.bedrijventerrein;
            }
            if (this.bagAdres.straatnaam) {
                this.adres.straat = this.bagAdres.straatnaam;
            }
            this.adres.gebruiksfunctieSet = this.bagAdres.gebruiksfunctieSet;
            this.adres.bagNummeraanduiding = this.bagAdres.nummeraanduidingIdentificatie?.toString();
            this.adres.bagVerblijfsobject = this.bagAdres.verblijfsobject;
            this.adres.xCoord = this.bagAdres.xCoord;
            this.adres.yCoord = this.bagAdres.yCoord;
            this.adres.bagOppervlak = this.bagAdres.oppervlak;
            this.adres.bagKoppelingKwaliteit = this.bagAdres.bagKoppelingKwaliteit;
        } else if (this.adres.bagKoppelingKwaliteit
            && this.adres.bagKoppelingKwaliteit !== BagKoppelingKwaliteit.GEEN_KOPPELING_MEER
        ) {
            this.adres.gebruiksfunctieSet = null;
            this.adres.bagNummeraanduiding = null;
            this.adres.bagVerblijfsobject = null;
            this.adres.xCoord = null;
            this.adres.yCoord = null;
            this.adres.bagKoppelingKwaliteit = null;
            this.adres.bagOppervlak = null;
            this.bagAdres = null;
        }
    }

    public get canUpdateBag() {
        return this.adres.bagKoppelingKwaliteit === null && this.adres.id;
    }

    public get gebruiksfunctie() {
        let gebruiksfunctie = this.adres.gebruiksfunctieSet?.find(
            (gebruiksfunctieItem) => gebruiksfunctieItem.code === GEBRUIKSFUNCTIE_MEERDERE_CODE,
        ) || null;
        if (!gebruiksfunctie) {
            gebruiksfunctie = this.adres.gebruiksfunctieSet ? this.adres.gebruiksfunctieSet[0] : null;
        }
        return gebruiksfunctie;
    }

    public constantText(item: any) {
        return item.code + ' - ' + item.omschrijving;
    }

    public get bagKoppelKwaliteit() {
        switch (this.adres.bagKoppelingKwaliteit) {
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_HUISLETTER_TOEVOEGING:
                return 'Sterk: op basis van postcode, huisnummer, huisletter en toevoeging';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_HUISLETTER:
                return 'Matig: toevoeging genegeerd';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER_TOEVOEGING:
                return 'Matig:  huisletter genegeerd';
            case BagKoppelingKwaliteit.MATCH_POSTCODE_HUISNUMMER:
                return 'Zwak: huisletter en toevoeging genegeerd.';
            case BagKoppelingKwaliteit.GEEN_KOPPELING_MEER:
                return 'Geen BAG koppeling meer voor dit adres.';
            default:
                return 'Geen BAG koppeling voor dit adres';
        }
    }

    public async created() {
        this.gebruiksfunctieOptions = (
            (await this.$api.gebruiksfunctie.listAll()).data?.content || []
        ) as IGebruiksfunctie[];
        this.gemeenteOptions = (await this.$api.gemeente.listAll()).data?.content || [];
        this.typePandOptions = (await this.$api.typePand.listAll()).data?.content || [];
        this.xyKwaliteitOptions = (await this.$api.xyKwaliteit.listAll()).data?.content || [];
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];

        this.searchBuurtValue = this.adres.buurt?.omschrijving ?? '';
        if (this.searchBuurtValue) {
            this.searchBuurt();
        }
        this.searchBedrijventerreinValue = this.adres.bedrijventerrein?.omschrijving ?? '';
        if (this.searchBedrijventerreinValue) {
            this.searchBedrijventerrein();
        }
        await this.debouncedLoadCheckBagAdres();
    }

    public async searchBuurt() {
        const searchValue = (this.searchBuurtValue || '');
        if (searchValue.length > 1) {
            this.loadingBuurt = true;
            this.buurtOptions = (await this.$api.buurt.getLightList(searchValue)).data as Idable[] || [];
            this.loadingBuurt = false;
        }
    }

    public async searchBedrijventerrein() {
        const searchValue = (this.searchBedrijventerreinValue || '');
        if (searchValue.length > 1) {
            this.loadingBedrijventerrein = true;
            this.bedrijventerreinOptions =
                (await this.$api.bedrijventerrein.getLightList(searchValue)).data as Idable[] || [];
            this.loadingBedrijventerrein = false;
        }
    }
}
