import {Idable} from '@/models/Idable';
import {IGebruiksfunctie} from '@/models/constants/IGebruiksfunctie';
import {ITypePand} from '@/models/constants/ITypePand';
import {IGemeente} from '@/models/constants/IGemeente';
import {IXyKwaliteit} from '@/models/constants/IXyKwaliteit';
import {IPlaatsnaam} from '@/models/constants/IPlaatsnaam';
import {IBuurt} from '@/models/constants/IBuurt';
import {IBedrijventerrein} from '@/models/constants/IBedrijventerrein';

export interface IAdres extends Idable {
    id: string;
    straat: string|null;
    huisnummer: number|null;
    huisletter: string|null;
    toevoeging: string|null;
    postcode: string|null;
    bagNummeraanduiding: string|null;
    bagVerblijfsobject: string|null;
    bagOppervlak: number|null;
    actief: boolean;
    geblokkeerd: boolean;
    gebruiksfunctieSet: IGebruiksfunctie[]|null;
    gemeente: IGemeente|null;
    buurt: IBuurt|null;
    bedrijventerrein: IBedrijventerrein|null;
    typePand: ITypePand|null;
    xyKwaliteit: IXyKwaliteit|null;
    plaatsnaam: IPlaatsnaam|null;
    verhuisdatum: number|null;
    bagKoppelingKwaliteit: BagKoppelingKwaliteit|null;
    xCoord: number|null;
    yCoord: number|null;
}

export function buildEmptyAdres(): IAdres {
    return {
        id: '',
        straat: null,
        huisnummer: null,
        huisletter: null,
        toevoeging: null,
        postcode: null,
        bagNummeraanduiding: null,
        bagVerblijfsobject: null,
        bagOppervlak: null,
        actief: true,
        geblokkeerd: false,
        gebruiksfunctieSet: null,
        gemeente: null,
        bedrijventerrein: null,
        buurt: null,
        typePand: null,
        xyKwaliteit: null,
        plaatsnaam: null,
        verhuisdatum: null,
        bagKoppelingKwaliteit: null,
        xCoord: null,
        yCoord: null,
    };
}

export enum BagKoppelingKwaliteit {
    MATCH_POSTCODE_HUISNUMMER_HUISLETTER_TOEVOEGING = 1,
    MATCH_POSTCODE_HUISNUMMER_HUISLETTER = 2,
    MATCH_POSTCODE_HUISNUMMER_TOEVOEGING = 3,
    MATCH_POSTCODE_HUISNUMMER = 4,
    GEEN_KOPPELING_MEER = 5,
}
