import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, post, url} from '@/utils/http';
import {IBagAdres} from '@/models/IBagAdres';
import {IAdres} from '@/models/IAdres';

export class BagAdresApi extends GenericApi<IBagAdres> {
    public async getBagAdresOptionsKvkByAdres(object: IAdres) {
        const uri = new URL(url(this.path + `/kvk-bag-by-adres`));
        const result = await post(uri.href, object);
        return result.data as IWrapperResponse<IBagAdres[]>;
    }

    public async findByAdres(object: IAdres) {
        const uri = new URL(url(this.path + `/find-by-adres`));
        const result = await post(uri.href, object);
        return result.data as IWrapperResponse<IBagAdres>;
    }
}
