import {RootState} from '@/store';
import {get, http, url} from '@/utils/http';
import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import {IGebruiker} from '@/models/IGebruiker';

export interface AuthenticationState {
    authenticated: boolean;
}

@Module
export default class AuthenticationModule extends VuexModule<AuthenticationState, RootState> {
    public authenticated: boolean = false;
    public user: IGebruiker|null = null;

    @Mutation
    public setAuthenticated(value: boolean) {
        this.authenticated = value;
    }

    @Mutation
    public setUser(user: IGebruiker|null) {
        this.user = user;
    }

    @MutationAction({mutate: ['authenticated', 'user']})
    public async logout() {
        await get(url('logout'));
        return {authenticated: false, user: null};
    }
}
