import {Component, Mixins} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {required} from '@/utils/validation';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {ListsObjects} from '@/mixins/ListsObjects';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {GenericApi} from '@/api/generic';
import {Idable} from '@/models/Idable';

@Component
export class BasicCrudMixin extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    public loadingData: boolean = true;
    public modelObject: Idable | null = null;
    public oldModelObject: Idable | null = null;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    private isEditing: boolean = false;

    public get rules() {
        return {};
    }

    public toEdit(id: string|number) {
        const idParam = id.toString();
        this.$router.push({name: this.editRouteName, params: { id: idParam }});
    }

    public get indexRouteName(): string {
        return this.$route.meta.indexRouteName;
    }

    public get editRouteName(): string {
        return this.$route.meta.editRouteName;
    }

    public get canUpdate() {
        return this.isAdmin;
    }

    public get canCreate() {
        return this.isAdmin;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public getApi(): GenericApi<Idable> {
        throw new TypeError('Method not overridden');
    }

    public buildEmptyModelData(): Idable {
        return {} as unknown as Idable;
    }

    public async loadData() {
        this.loadingData = true;
        try {
            if (this.$route.name?.endsWith('Edit')) {
                const response = await this.getApi().get((this.$route.params.id));
                this.modelObject = response.data!;

                this.oldModelObject = this.$_.cloneDeep(this.modelObject);
            } else {
                this.modelObject = this.buildEmptyModelData();
            }
            this.searchCriteria = [new SearchCriterion('id', this.$route.params.id || String(-1))];
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public created() {
        this.isEditing = this.$route.name?.endsWith('Edit') || false;
    }

    public beforeMount() {
        return this.loadData();
    }

    public load() {
        this.isEditing = this.$route.name?.endsWith('Edit') || false;
        return this.loadData();
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.modelObject != null && result) {
            this.loadingData = true;
            try {
                const valueToPost: Idable = this.getValueToPost();

                if (this.isEditing) {
                    const response = await this.getApi().save(valueToPost!);
                    this.modelObject = response.data;
                } else {
                    const response = await this.getApi().create(valueToPost!);
                    this.modelObject = response.data;
                }
                this.$router.push({name: this.indexRouteName});
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    private getValueToPost(): Idable | any {
        // Strip properties that could've been fetched from get-endpoint, but should not be post for saving.
        return this.$_.cloneDeep(this.$_.omit(this.modelObject, [
            'toegevoegdOp',
            'gewijzigdOp',
            'verwijderdOp',
            'toegevoegdDoor',
            'gewijzigdDoor',
        ]));
    }

}
