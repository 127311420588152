






















import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SimpleConfirmDialog extends Vue {
    @Model('input', {type: Boolean}) public readonly dialog!: boolean;
    @Prop({default: ''}) public message!: string;

    public onCancel() {
        this.$emit('cancel');
        this.$emit('input', false);
    }

    public onConfirm() {
        this.$emit('confirm');
        this.$emit('input', false);
    }
}
