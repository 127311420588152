




















































































































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import { Component, Mixins } from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import { ShowsMessages } from '@/mixins/ShowsMessages';
import { AuthorizationMixin } from '@/mixins/AuthorizationMixin';
import { IImportTemplate } from '@/models/IImportTemplate';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import { dateTime } from '@/utils/filters';
import { IShapefileImportRun, ShapefileImportStatus, ShapeStep, ShapeType } from '@/models/IShapefileImportRun';
import { IShapefileImportRunCreate } from '@/models/IShapefileImportRunCreate';
import {required} from '@/utils/validation';

@Component({
    filters: {
        dateTime,
    },
    components: {
        AutoLoadingButton,
        SimpleConfirmDialog,
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class ShapeImport extends Mixins(ShowsMessages, AuthorizationMixin) {
    public files: any = null;
    public type: ShapeType | null = null;
    public updateOnly: boolean = true;
    public importTemplates: IImportTemplate[] = [];
    public loadingData: boolean = true;
    public ShapefileImportStatus = ShapefileImportStatus;
    public ShapeStep = ShapeStep;
    public ShapeType = ShapeType;

    public shapeTypes = [
        { label: 'Gemeentes', value: ShapeType.GEMEENTE },
        { label: 'Wijken', value: ShapeType.WIJK },
        { label: 'Buurten', value: ShapeType.BUURT },
        { label: 'Bedrijventerreinen', value: ShapeType.BEDRIJVENTERREIN },
    ];

    public model: IShapefileImportRun | null = null;

    public inProgress: boolean = true;
    public adresProgress: number = 0.0;
    public insertProgress: number = 0.0;

    public get rules() {
        return {
            files: [
                required,
                (value: string) => (value && value.length < 3) ? 'Er moeten tenminste 3 bestanden gekozen zijn.' : true,
            ],
            type: [required],
        };
    }

    public get mimeTypes() {
        return [
            '.dbf', '.shp', '.shx',
        ];
    }

    public get showLastRunInfo() {
        return !!this.model && this.model.status === ShapefileImportStatus.DONE && !this.inProgress;
    }

    public get showLastRunError() {
        return !!this.model && this.model.status === ShapefileImportStatus.CANCELED;
    }

    public get canSave() {
        return this.isAdmin && !this.inProgress;
    }

    public get canAbort() {
        return this.inProgress && this.model?.selectedStep === ShapeStep.UPDATE;
    }

    public async beforeMount(): Promise<void> {
        await this.loadData();
    }

    public async abort() {
        await this.$api.shape.abortImport();
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.files != null && result) {
            this.loadingData = true;
            try {
                const response = await this.$api.shape.import({
                    files: this.files,
                    type: this.type,
                    updateOnly: this.updateOnly,
                } as IShapefileImportRunCreate);

                await this.loadData();
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    public async loadData() {
        this.loadingData = true;
        try {
            const response = await this.$api.shape.getImport();
            this.model = response.data;

            this.setStepByStatus();

            this.inProgress = !!this.model && this.model?.status !== null
                && (this.model?.status < 100 && this.model.status !== ShapefileImportStatus.CANCELED);
            if (!!this.model) {
                if (this.model?.selectedStep === ShapeStep.UPDATE) {
                    this.adresProgress = (this.model.adresRowsProcessed / this.model.adresRows) * 100;
                }
            }

            if (this.inProgress) {
                setTimeout(() => this.loadData(), 5000);
            }

        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public get status() {
        return this.model!.status;
    }

    public setStepByStatus() {
        switch (this.status) {
            case ShapefileImportStatus.UPLOAD_DONE:
                this.model!.selectedStep = ShapeStep.UPLOAD;
                break;
            case ShapefileImportStatus.PARSING_IN_PROGRESS:
            case ShapefileImportStatus.PARSING_DONE:
            case ShapefileImportStatus.POST_PROCESS_DONE:
                this.model!.selectedStep = ShapeStep.PARSING;
                break;
            case ShapefileImportStatus.INSERT_IN_PROGRESS:
            case ShapefileImportStatus.INSERT_DONE:
                this.model!.selectedStep = ShapeStep.INSERT;
                break;
            case ShapefileImportStatus.UPDATE_ADRES_IN_PROGRESS:
            case ShapefileImportStatus.UPDATE_ADRES_DONE:
                this.model!.selectedStep = ShapeStep.UPDATE;
                break;
        }
    }
}
