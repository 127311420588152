






















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';

@Component({
})
export default class KvkUploadRunStepper extends Vue {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;
    public showStatusStepper: boolean = false;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun | null;

    public get status() {
        return this.kvkUploadRun?.status;
    }
}
