import { GenericApi, IWrapperResponse } from '@/api/generic';
import { get, post, url } from '@/utils/http';
import { Idable } from '@/models/Idable';
import { IImportRun } from '@/models/IImportRun';
import { IBagImportRun } from '@/models/IBagImportRun';

export class BagApi extends GenericApi<IBagImportRun> {

    constructor() {
        super('/api/v1/bag');
    }

    public async import(model: Idable) {
        const uri = new URL(url(this.path + '/import'));
        const formData = new FormData();
        formData.append('file', (model as any).file);

        // Unset file as this is received separately in the backend (not in the model)
        delete (model as any).file;

        formData.append('bagImportCreateRequest', new Blob([JSON.stringify(model)], {type: 'application/json'}));
        const result = await post(uri.href, formData);
        return result.data as IWrapperResponse<IBagImportRun>;
    }

    public async getImport() {
        const uri = new URL(url(this.path + '/import'));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IBagImportRun>;
    }
}
