


































































































































































































































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {Idable} from '@/models/Idable';
import {State} from 'vuex-class';
import {number} from '@/utils/validation';
import {IGebruiker} from '@/models/IGebruiker';

@Component({
    components: {StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class VestigingList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'Vestigingsnummer', value: 'vestigingNummer'},
        {text: 'Naam', value: 'naam'},
        {text: 'Straatnaam', value: 'adres.straat'},
        {text: 'Huisnummer', value: 'huisnummer'},
        {text: 'Huisletter', value: 'huisletter'},
        {text: 'Toevoeging', value: 'toevoeging'},
        {text: 'Postcode', value: 'postcode'},
        {text: 'Plaats', value: 'adres.plaatsnaam.omschrijving'},
        {text: 'SBI', value: 'sbi.code'},
        {text: 'KvK dossiernummer', value: 'kvkDossiernummer'},
        {text: 'Actief', value: 'datumEinde'},
        {text: 'Extra', value: 'extra'},
        {text: '', value: 'action', sortable: false},
    ];
    public registerOptions: Idable[] = [];
    public sbiOptions: Idable[] = [];
    public plaatsnaamOptions: Idable[] = [];
    public gemeenteOptions: Idable[] = [];
    public rechtsvormOptions: Idable[] = [];
    public meetperiodeOptions: Idable[] = [];
    public selectedMeetperiode: number[] =  [];
    public werkzamePersonenIngevuld: boolean = true;
    public hasUitgeslotenWerkzamePersonenOptions: Array<{ label: string, value: boolean | null }> =  [
        { label: 'Bevat uitgesloten werkzame personen', value: true },
        { label: 'Bevat niet uitgesloten werkzame personen', value: false },
        { label: 'Alle', value: null },
    ];

    public isExtraOptions: Array<{ label: string, value: boolean | null }> =  [
        { label: 'Ja', value: true },
        { label: 'Nee', value: false },
        { label: 'Alle', value: null },
    ];

    public searchValue: any = {
        datumEinde: 'isNull',
    };

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.vestiging;
    }

    public get rules() {
        return {
            huisnummer: [number],
            vestigingNummer: [number],
        };
    }

    public async created() {
        this.sbiOptions = (await this.$api.sbi.listAll()).data?.content || [];
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];
        this.gemeenteOptions = (await this.$api.gemeente.listAll()).data?.content || [];
        this.registerOptions = (await this.$api.register.listAll()).data?.content || [];
        this.rechtsvormOptions = (await this.$api.rechtsvorm.listAll()).data?.content || [];
        this.meetperiodeOptions = (await this.$api.meetperiode.listAll(
                [], 'peildatumEndOfDay', 'DESC')).data?.content || [];
        this.setSearchCriteria();
    }

    public async clear() {
        this.selectedMeetperiode = [];
        this.searchValue = {};
        this.searchValue.datumEinde = 'isNull';
        this.pagination.sortBy = ['postcode', 'huisnummer', 'huisletter', 'toevoeging'];
        this.pagination.sortDesc = [false, false, false, false];
        await this.search();
    }

    public cbSearchChange(input: any) {
        let val = this.searchValue.cb || null;
        if (typeof input === 'object' && input.srcElement) {
            val = input.srcElement.value;
        }
        if (val === null || val === '') {
            this.searchValue['cb.cbNummer:eq'] = null;
            this.searchValue['cb.id'] = null;
            return;
        }
        if (val === 'Geen' || val.replaceAll('0', '') === '') {
            this.searchValue['cb.cbNummer:eq'] = null;
            this.searchValue['cb.id'] = 'isNull';
        } else {
            this.searchValue['cb.cbNummer:eq'] = val;
            this.searchValue['cb.id'] = null;
        }
    }

    @Watch('searchValue', {deep: true, immediate: true})
    public onSearchValueChanged() {
        const wsMeetperiodeInValue = this.searchValue['werknemerStats.meetperiode.id:in'];
        const wsMeetperiodeNotInValue = this.searchValue['werknemerStats.meetperiode.id:notIn'];
        if (wsMeetperiodeInValue) {
            this.selectedMeetperiode = wsMeetperiodeInValue.split('|').map(Number);
            this.werkzamePersonenIngevuld = true;
        } else if (wsMeetperiodeNotInValue) {
            this.selectedMeetperiode = wsMeetperiodeNotInValue.split('|').map(Number);
            this.werkzamePersonenIngevuld = false;
        } else {
            this.werkzamePersonenIngevuld = true;
            this.selectedMeetperiode = [];
        }
    }

    @Watch('pagination.sortBy.0', {immediate: true})
    public sortChanged(newValue: string) {
        if (['postcode', 'huisnummer', 'huisletter', 'toevoeging'].includes(newValue)) {
            this.pagination.sortBy = ['postcode', 'huisnummer', 'huisletter', 'toevoeging'];
            const desc = this.pagination.sortDesc[0];
            this.pagination.sortDesc = [desc, desc, desc, desc];
        }
    }

    @Watch('selectedMeetperiode', { deep: true } )
    @Watch('werkzamePersonenIngevuld')
    public onSelectionMeetperiodeChanged() {
        if (this.selectedMeetperiode!.length !== 0) {
            const result = this.selectedMeetperiode.join('|');
            if (this.werkzamePersonenIngevuld) {
                this.searchValue['werknemerStats.meetperiode.id:in'] = result;
                this.searchValue['werknemerStats.meetperiode.id:notIn'] = null;
            } else {
                this.searchValue['werknemerStats.meetperiode.id:in'] = null;
                this.searchValue['werknemerStats.meetperiode.id:notIn'] = result;
            }
        } else {
            this.searchValue['werknemerStats.meetperiode.id:notIn'] = null;
            this.searchValue['werknemerStats.meetperiode.id:in'] = null;
        }
    }
}
