import {GenericApi, IWrapperResponse} from '@/api/generic';
import {get, http, put, url} from '@/utils/http';
import {IVestiging} from '@/models/IVestiging';
import {IKvkMutatie} from '@/models/IKvkMutatie';

export class KvkMutatieApi extends GenericApi<IKvkMutatie> {

    constructor() {
        super('/api/v1/kvk-mutatie');
    }

    public async approve(kvkMutatie: IKvkMutatie, updateKvkWithResponse: boolean = false) {
        const uri = new URL(url(this.path + `/cl/approve/` + kvkMutatie.id));
        const result = await put(uri.href, kvkMutatie);
        if (updateKvkWithResponse) {
            this.updateKvkProperties(kvkMutatie, result.data.data);
        }
        return result.data as IWrapperResponse<IKvkMutatie>;
    }

    public async rewind(kvkMutatie: IKvkMutatie, updateKvkWithResponse: boolean = false) {
        const uri = new URL(url(this.path + `/cl/rewind/` + kvkMutatie.id));
        const result = await put(uri.href, kvkMutatie);
        if (updateKvkWithResponse) {
            this.updateKvkProperties(kvkMutatie, result.data.data);
        }
        return result.data as IWrapperResponse<IKvkMutatie>;
    }

    public async rejectHandmatigVerwerkenNietRelevant(kvkMutatie: IKvkMutatie, updateKvkWithResponse: boolean = false) {
        const item = JSON.parse(JSON.stringify(kvkMutatie));
        item.handmatigOpgenomen = false;

        const uri = new URL(url(this.path + `/handmatig-niet-relevant/` + kvkMutatie.id));
        const result = await put(uri.href, item);
        if (updateKvkWithResponse) {
            this.updateKvkProperties(kvkMutatie, result.data.data);
        }
        return result.data as IWrapperResponse<IKvkMutatie>;
    }

    public async approveHandmatigVerwerkenVestigingOpnemen(
        kvkMutatie: IKvkMutatie,
        updateWithResponse: boolean = false,
    ) {
        const item = JSON.parse(JSON.stringify(kvkMutatie));
        item.handmatigOpgenomen = true;

        const uri = new URL(url(this.path + `/handmatig-opnemen/` + kvkMutatie.id));
        const result = await put(uri.href, item);
        if (updateWithResponse) {
            this.updateKvkProperties(kvkMutatie, result.data.data);
        }
        return result.data as IWrapperResponse<IKvkMutatie>;
    }

    public async acceptWithoutBag(kvkMutatie: IKvkMutatie) {
        const item = JSON.parse(JSON.stringify(kvkMutatie));
        item.handmatigOpgenomen = true;

        const uri = new URL(url(this.path + `/accepteren-zonder-bag/` + kvkMutatie.id));
        const result = await put(uri.href, item);
        return result.data as IWrapperResponse<IKvkMutatie>;
    }

    public async updateProcessedCount(kvkMutatie: IKvkMutatie) {
        const uri = new URL(url(this.path + `/update-manual-processed-count/` + kvkMutatie.id));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async getByVestiging(nr: string | number) {
        const uri = new URL(url(this.path + `/kvk-mutaties-by-vestiging/${nr}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IKvkMutatie[]>;
    }

    public async getMutatedOldVestiging(kvkMutatie: IKvkMutatie) {
        const uri = new URL(url(this.path + `/get-mutate-vestiging`));
        const result = await put(uri.href, kvkMutatie);
        return result.data as IWrapperResponse<IVestiging>;
    }

    public async correctielijstSize(id: string | number, type: number) {
        const uri = new URL(url(this.path + `/${id}/cl-size/${type}`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<number>;
    }

    public async manualListSize(id: string | number) {
        const uri = new URL(url(this.path + `/${id}/manual-processing-list-size`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<number>;
    }

    private updateKvkProperties(kvk: IKvkMutatie, data: IKvkMutatie|null) {
        for (const i in kvk) {
            if (kvk.hasOwnProperty(i) && data!.hasOwnProperty(i)) {
                (kvk as any)[i] = (data as any)[i];
            }
        }
    }
}
