import {Idable} from '@/models/Idable';
import {buildEmptyVestiging, IVestiging} from '@/models/IVestiging';
import {buildEmptyAdres, IAdres} from '@/models/IAdres';
import {buildEmptyContact, IContact} from '@/models/IContact';
import {IGemeente} from '@/models/constants/IGemeente';

export interface ICorrespondent extends Idable {
    straat: string|null;
    huisnummer: number|null;
    huisletter: string|null;
    toevoeging: string|null;
    postcode: string|null;
    plaats: string|null;
    gemeente: IGemeente|null;
    contact: IContact;
}

export function buildEmptyCorrespondent(): ICorrespondent {
    return {
        id: '',
        straat: null,
        huisnummer: null,
        huisletter: null,
        toevoeging: null,
        gemeente: null,
        postcode: null,
        plaats: null,
        contact: buildEmptyContact(),
    };
}
