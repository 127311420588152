

































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {buildEmptyUser, IGebruiker, Role, roleOptions} from '@/models/IGebruiker';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {minLength, required, email} from '@/utils/validation';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';
import {Idable} from '@/models/Idable';

@Component({
    filters: {},
    components: {
        SimpleMessageWrapper,
    },
})
export default class UserEdit extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    public loadingData: boolean = true;
    public user: IGebruiker | null = null;
    public oldUser: IGebruiker | null = null;

    public password: string | null = null;
    public passwordConfirm: string | null = null;

    public registers: IRegister[] = [];

    public roles: Array<{ label: string, value: Role }> = roleOptions;

    public gemeenteOptions: Idable[] = [];

    public isEditing: boolean = false;

    public is2FaEnabled: boolean = false;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    @Watch('user.register', {deep: true, immediate: true})
    public async loadGemeentes() {
        if (this.user && this.user.register && this.user.register.id) {
            this.gemeenteOptions = (await this.$api.gemeente.listAll(
                [new SearchCriterion('register.id', '' + this.user.register.id)])).data?.content || [];
        } else {
            this.gemeenteOptions = (await this.$api.gemeente.listAll()).data?.content || [];
        }
    }

    public get rules() {
        return {
            username: [required],
            email: [email, required],
            name: [required],
            password: [minLength(4), (value: string) => {
                return !this.isEditing && this.user && value == null ? 'Dit veld is verplicht' : true;
            }],
            passwordConfirm: [
                (value: string) => (this.password || value)
                    && this.password !== value ? 'Wachtwoorden zijn niet gelijk' : true,
            ],
        };
    }

    public get api() {
        return this.$api.user;
    }

    public get canUpdate() {
        return this.isAdmin;
    }

    public get canCreate() {
        return this.isAdmin;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public async loadData() {
        this.loadingData = true;
        try {
            if (this.$route.name === 'userEdit') {
                const response = await this.$api.user.get(Number(this.$route.params.id));
                this.user = response.data!;

                this.oldUser = this.$_.cloneDeep(this.user);
            } else {
                this.user = buildEmptyUser();
            }
            this.is2FaEnabled = this.user && this.user.mfa;

            const registerResponse = await this.$api.register.list(new Pagination(0, -1), []);
            this.registers = registerResponse.data!.content;

        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public async created() {
        this.isEditing = this.$route.name === 'userEdit';
        await this.loadGemeentes();
    }

    public beforeMount() {
        return this.loadData();
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.user != null && result) {
            this.loadingData = true;
            try {
                const valueToPost: IGebruiker = this.getValueToPost();

                // if password is set, lets set it on the model
                if (this.password) {
                    valueToPost.wachtwoord = this.password;
                }

                if (this.isEditing) {
                    const response = await this.$api.user.save(valueToPost!);
                    this.user = response.data;
                } else {
                    const response = await this.$api.user.create(valueToPost!);
                    this.user = response.data;
                }
                this.$router.push({name: 'users'});
            } catch (e) {
                if (e.response && e.response.data && e.response.data.error) {
                    switch (e.response.data.error) {
                        case 'usernameNotUnique':
                            this.showError('De gebruikersnaam is al in gebruik.');
                            break;
                        case 'emailNotUnique':
                            this.showError('Het e-mailadres is al in gebruik.');
                            break;
                        default:
                            this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                            console.error('Save error: ', e);
                    }
                }
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    @Watch('password')
    private revalidatePasswordConfirm() {
        (this.$refs.passwordConfirm as any).validate();
    }

    private getValueToPost(): IGebruiker|any {
        // Strip properties that could've been fetched from get-endpoint, but should not be post for saving.
        return this.$_.cloneDeep(this.$_.omit(this.user, [
            'toegevoegdOp',
            'gewijzigdOp',
            'verwijderdOp',
            'ingelogdOp',
            'toegevoegdDoor',
            'gewijzigdDoor',
        ]));
    }

    private setEmailAsUsername() {
        if (this.user && !this.user.gebruikersnaam) {
            this.user.gebruikersnaam = this.user.email;
        }
    }
}
