import {IGebruiker, Role} from '@/models/IGebruiker';

export function hasRole(user: IGebruiker, role: Role): boolean {
    return user.rollen.includes(role);
}

export function hasAnyRole(user: IGebruiker, roles: Role[]): boolean {
    for (const role of roles) {
        if (hasRole(user, role)) {
            return true;
        }
    }
    return false;
}
