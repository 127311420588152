import {Idable} from '@/models/Idable';
import {IKvkRedenUit} from '@/models/constants/IKvkRedenUit';
import {IKvkRedenIn} from '@/models/constants/IKvkRedenIn';
import {IRegister} from '@/models/IRegister';
import {IKvkMutatie} from '@/models/IKvkMutatie';
import {IKvkCma} from '@/models/IKvkCma';

export interface IKvkBase extends Idable {
    vestigingId: number|null; // id of the actual vestiging (id instead of object is used to prevent recursion issues)
    dossiernummer: number;
    subdossiernummer: number;
    vestigingsnummer: number; // note that this kvk_vestigingnummer may NOT be the same the vestiging.vestigingNummer
    kvkRedenIn: IKvkRedenIn;
    kvkRedenUit: IKvkRedenUit;
    registerletter: string; // not the same as 'register'

    data: string; // TODO: veld verwijderen
    kvkCma: IKvkCma;

    bedrijfsnaam: string;
    straatnaam: string;
    toevoeging: string;
    huisnummer: string;
    postcode: string;
    woonplaats: string;
    website: string;
    datumLaatsteWijziging: number;

    kvkOpmerkingen: string|null;

    derivedHuisletter: string|null;
    derivedToevoeging: string|null;
    derivedCaHuisletter: string|null;
    derivedCaToevoeging: string|null;
    info: string|null;
}

export interface IKvk extends IKvkBase {
    register: null|IRegister;
    kvkMutatie: null|IKvkMutatie;
    // dossiernummer: number;
    // subdossiernummer: number;
    // vestigingsnummer: number; // note that this kvk_vestigingnummer may NOT be the same the vestiging.vestigingNummer
    // kvkRedenIn: IKvkRedenIn;
    // kvkRedenUit: IKvkRedenUit;
    // registerletter: string; // not the same as 'register'
    // datumEinde: number|null;
    // registerletter: string; // not the same as 'register'
    datumStart: number|null;
    datumEinde: number|null;
    datumSbi: number|null;
    datumNaam: number|null;
    // data: string;
    datumStatusBeoordelen: number|null;
    status: KvkStatus;
    afgevoerd: boolean;
    // bedrijfsnaam: string;
    // straatnaam: string;
    // toevoeging: string;
    // huisnummer: string;
    // postcode: string;
    // woonplaats: string;
    // website: string;
    // datumLaatsteWijziging: number;
    datumOpname: number; // derived from kvk.dbf
    // kvkCma is transient; dertive from data field instead
    // kvkOpmerkingen: string|null;
}

export enum MutatieCategorie {
    A_NR = 1,
    B_HM = 2,
    C_WOPN = 3,
    D_OPN = 4,
}

export enum KvkStatus {
    RVR = 0,
    NRVR = 1,
    BEOORDELEN = 2,
}

export const kvkStatusOptions: Array<{ label: string, value: KvkStatus }> = [
    { label: 'Niet relevant', value: KvkStatus.NRVR },
    { label: 'Relevant', value: KvkStatus.RVR },
    { label: 'Beoordelen', value: KvkStatus.BEOORDELEN },
];

export const bevatDatumEindeOptions: Array<{ label: string, value: string }> = [
    { label: 'Ja', value: 'ne:0' },
    { label: 'Nee', value: 'eq:0' },
];

export const bevatWijzeEindeOptions: Array<{ label: string, value: string }> = [
    { label: 'Ja', value: 'isNotNull' },
    { label: 'Nee', value: 'isNull' },
];
