











































































































































































































































































































































































import {Component, Mixins, Model, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import {IVestiging} from '@/models/IVestiging';
import {IBagAdres} from '@/models/IBagAdres';
import {IAdres} from '@/models/IAdres';
import {cloneDeep} from 'lodash';
import {IKvk} from '@/models/IKvk';
import IdentificatieInfo from '@/views/vestigingen/components/IdentificatieInfo.vue';
import {Idable} from '@/models/Idable';
import {maxLength} from '@/utils/validation';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    components: {
        IdentificatieInfo,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class NietBagConformDialog extends Mixins(AuthorizationMixin) {
    // @Model('input')
    public vestiging: IVestiging|null = null;

    @Prop( {required: true} )
    public kvkMutatie!: IKvkMutatie;

    public adres: IAdres | null = null;
    public initialAdres: IAdres | null = null;

    public bagAdressen: IBagAdres[] | null = [];

    public selectedBagAdres: IBagAdres | null = null;
    public choiceConfirmed = false;
    public showConfirmSelectionDialog = false;

    public sbiOptions: Idable[] = [];
    public grootteklasseOptions: Idable[] = [];
    public vestigingTypeOptions: Idable[] = [];
    public gebruiksfunctieOptions: Idable[] = [];
    public gemeenteOptions: Idable[] = [];
    public typePandOptions: Idable[] = [];
    public plaatsnaamOptions: Idable[] = [];

    public canUpdate: boolean = false;

    public loading: boolean = false;
    public loadingBagAdressen: boolean = false;

    protected debouncedLoadBagAdressen = this.$_.debounce(() => {
        return this.loadBagAdressen();
    }, 1000);

    public get rules() {
        return {
            huisletter: [maxLength(1)],
        };
    }

    @Watch('kvkMutatie')
    public async loadVestiging() {
        if (this.kvkMutatie?.vestigingId) {
            const response = await this.$api.vestiging.get(this.kvkMutatie?.vestigingId);
            this.vestiging = response.data!;
        } else {
            this.vestiging = null;
        }
    }

    @Watch('adres', {deep: true})
    public async onAdresChange() {
        if (this.adres) {
            this.loadingBagAdressen = true;
            this.debouncedLoadBagAdressen();
        }
    }

    public async loadBagAdressen() {
        if (this.adres) {
            this.loadingBagAdressen = true;
            this.bagAdressen = (await this.$api.bagAdres.getBagAdresOptionsKvkByAdres(this.adres)).data;
            this.loadingBagAdressen = false;
        }
    }

    public get rsin() {
        const value = this.vestiging?.rsin;
        if (value && value.length >= 7) {
            return value.substr(0, 4) + '-' +  value.substr(4, 2) + '-' + value.substr(6);
        }
        return value;
    }

    public get kvkDossiernummer() {
        const value = this.vestiging?.kvkDossiernummer;
        if (value && value.length >= 3) {
            return value.substr(0, 2) + '-' +  value.substr(2);
        }
        return value;
    }


    public async created() {
        this.loading = true;
        await this.loadVestiging();
        this.bagAdressen = (await this.$api.bagAdres.getBagAdresOptionsKvkByAdres(this.vestiging!.adres)).data;
        this.adres = this.vestiging!.adres;
        this.initialAdres = cloneDeep(this.adres);
        this.sbiOptions = (await this.$api.sbi.listAll()).data?.content || [];
        this.grootteklasseOptions = (await this.$api.grootteklasse.listAll()).data?.content || [];
        this.vestigingTypeOptions = (await this.$api.vestigingType.listAll()).data?.content || [];
        this.gebruiksfunctieOptions = (await this.$api.gebruiksfunctie.listAll()).data?.content || [];
        this.gemeenteOptions = (await this.$api.gemeente.listAll()).data?.content || [];
        this.typePandOptions = (await this.$api.typePand.listAll()).data?.content || [];
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];
        this.loading = false;
    }

    public get sortedBagAdressen() {
        if (this.vestiging && this.vestiging.adres) {
            const validMatches = this.bagAdressen?.filter((bagAdres) =>
                bagAdres.huisnummer === this.vestiging!.adres.huisnummer
                && bagAdres.postcode === this.vestiging!.adres.postcode);
            if (validMatches?.length) {
                return validMatches;
            }
        }

        return this.bagAdressen?.sort((a: IBagAdres, b: IBagAdres) => {
            return Math.sign(b.huisnummer - a.huisnummer);
        });
    }

    public async selectBagAdres(bagAdres: IBagAdres) {
        if (!this.choiceConfirmed) {
            this.selectedBagAdres = bagAdres;
            this.showConfirmSelectionDialog = true;
        } else if (this.vestiging) {
            this.showConfirmSelectionDialog = false;
            this.vestiging.adres.huisnummer = bagAdres.huisnummer;
            this.vestiging.adres.huisletter = bagAdres.huisletter;
            this.vestiging.adres.postcode = bagAdres.postcode;
            this.vestiging.adres.toevoeging = bagAdres.toevoeging;
            this.vestiging.adres.straat = bagAdres.straatnaam;
            this.vestiging.adres.gebruiksfunctieSet = bagAdres.gebruiksfunctieSet;
            this.vestiging.adres.bagNummeraanduiding = '' + bagAdres.nummeraanduidingIdentificatie;
            this.vestiging.adres.bagOppervlak = bagAdres.oppervlak;
            this.vestiging.adres.bagVerblijfsobject = bagAdres.verblijfsobject;
            this.vestiging.adres.xCoord = bagAdres.xCoord;
            this.vestiging.adres.yCoord = bagAdres.yCoord;
            this.selectedBagAdres = null;
            this.showConfirmSelectionDialog = false;
            this.choiceConfirmed = false;
        }
    }

    public async revertInitial() {
        if (this.initialAdres && this.vestiging) {
            this.vestiging.adres.huisnummer = this.initialAdres.huisnummer;
            this.vestiging.adres.huisletter = this.initialAdres.huisletter;
            this.vestiging.adres.postcode = this.initialAdres.postcode;
            this.vestiging.adres.toevoeging = this.initialAdres.toevoeging;
            this.vestiging.adres.straat = this.initialAdres.straat;
            this.vestiging.adres.gebruiksfunctieSet = this.initialAdres.gebruiksfunctieSet;
            this.vestiging.adres.bagNummeraanduiding = '' + this.initialAdres.bagNummeraanduiding;
            this.vestiging.adres.bagOppervlak = this.initialAdres.bagOppervlak;
            this.vestiging.adres.bagVerblijfsobject = this.initialAdres.bagVerblijfsobject;
            this.vestiging.adres.xCoord = this.initialAdres.xCoord;
            this.vestiging.adres.yCoord = this.initialAdres.yCoord;
        }
    }

    public async confirmBagAdres() {
        if (this.vestiging) {
            await this.$api.vestiging.save(this.vestiging);
            await this.$api.kvkMutatie.updateProcessedCount(this.kvkMutatie);
            this.$emit('complete');
        }
    }

    public async cancelSelection() {
        this.revertInitial();
        this.$emit('close');
    }

    public async acceptWithoutBag() {
        await this.$api.kvkMutatie.acceptWithoutBag(this.kvkMutatie);
        this.$emit('accept');
    }

    public cancelConfirmDialog() {
        this.selectedBagAdres = null;
        this.showConfirmSelectionDialog = false;
        this.choiceConfirmed = false;
    }

    public confirmSelection() {
        this.showConfirmSelectionDialog = false;
        this.choiceConfirmed = true;
        this.selectBagAdres(this.selectedBagAdres!);
        this.confirmBagAdres();
    }
}
