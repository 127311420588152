import { Idable } from '@/models/Idable';
import { BagImportStatus } from '@/models/IBagImportRun';

export interface IShapefileImportRun extends Idable {
    filename: string;
    adresRows: number;
    adresRowsProcessed: number;
    type: ShapeType;
    status: ShapefileImportStatus;

    // non-server side; extra helper-property
    selectedStep: ShapeStep;
}

export enum ShapeStep {
    UPLOAD = 1,
    PARSING = 2,
    INSERT = 3,
    UPDATE = 4,
}

export enum ShapeType {
    GEMEENTE = 1,
    WIJK = 2,
    BUURT = 3,
    BEDRIJVENTERREIN = 4,
}

export enum ShapefileImportStatus {
    CANCELED = -1,
    UPLOAD_DONE = 0,
    PARSING_IN_PROGRESS = 10,
    PARSING_DONE = 20,
    INSERT_IN_PROGRESS = 30,
    INSERT_DONE = 40,
    POST_PROCESS_DONE = 50,
    UPDATE_ADRES_IN_PROGRESS = 60,
    UPDATE_ADRES_DONE = 70,
    DONE = 100,
}
