import {IVestiging} from '@/models/IVestiging';
import {IKvkCma} from '@/models/IKvkCma';

export function getKvkMutatieRules(vestiging: IVestiging|null, kvkCma: IKvkCma): KvkMutatieRule[] {
    const kvkMutatieRules: KvkMutatieRule[] = [];
    for (const kvkMutatieFieldName in kvkToVestigingMapping) {
        if (kvkToVestigingMapping.hasOwnProperty(kvkMutatieFieldName) && kvkToVestigingMapping[kvkMutatieFieldName]) {
            kvkMutatieRules.push(new KvkMutatieRule(vestiging, kvkCma, kvkMutatieFieldName));
        }
    }
    return kvkMutatieRules;
}

export class KvkMutatieRule {
    constructor(
        public vestiging: IVestiging|null,
        public kvkMutatieJson: any,
        public kvkMutatieFieldName: string,
    ) {
    }

    get kvkMutatieRsiFieldName(): string {
        if (this.kvkMutatieFieldName === 'woonplaatsNen') {
            return 'rsiWoonplaats';
        }
        if (this.kvkMutatieFieldName === 'caWoonplaatsNen') {
            return 'rsiCaWoonplaats';
        }
        const fieldName = this.kvkMutatieFieldName.charAt(0).toUpperCase() + this.kvkMutatieFieldName.slice(1);
        return 'rsi' + fieldName;
    }

    get vestigingFieldName(): string {
        return kvkToVestigingMapping[this.kvkMutatieFieldName] || '?';
    }

    get vestigingValue() {
        if (!this.vestiging) {
            return null;
        }
        if (this.vestigingFieldName.includes(',')) {
            const vestigingValues = [];
            for (const fieldName of this.vestigingFieldName.split(',')) {
                vestigingValues.push(this.getObjectValueByDottedKey(this.vestiging, fieldName.trim()));
            }
            return vestigingValues.join('<br>');
        }
        return this.getObjectValueByDottedKey(this.vestiging, this.vestigingFieldName);
    }

    get kvkValue() {
        return this.kvkMutatieJson[this.kvkMutatieFieldName] || '';
    }

    // source: https://stackoverflow.com/a/6491621/1370000
    private getObjectValueByDottedKey(o: any, s: string) {
        // s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        // s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (typeof o !== 'object' || !o) {
                return;
            }
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    /**
     *  The RSI value determines how mutation should happen:
     *  0 = do nothing
     *  1 = mutate (update vestiging with new value)
     *  2 = remove (remove value) - NOTE: we do NOT remove values, so effectively this is the same as 'do nothing'
     *  3 = new (only update vestiging value if the original value is empty)
     */
    get rsi(): number|string|null {
        const rsi = this.kvkMutatieJson[this.kvkMutatieRsiFieldName];
        if (!rsi && rsi !== 0) {
            return null;
        }
        if (Number.isNaN(rsi.toString())) {
            return rsi.toString();
        }
        return parseInt(rsi.toString(), 10);
    }

    get hasValidRsi(): boolean {
        // empty/no rsi is allowed if there's no value either.
        if (!this.rsi && !this.kvkValue) {
            return true;
        }
        return this.rsi !== null && ['0', '1', '2', '3'].includes(this.rsi.toString());
    }

    get isSuspiciousSbi() {
        if (this.kvkMutatieFieldName !== 'sbi08') {
            return false;
        }
        return this.kvkValue && [
            '6420', // Financiële holdings
            '64301', // Beleggingsinstellingen in financiële activa
            '64302', // Beleggingsinstellingen in vaste activa
            '64303', // Beleggingsinstellingen met beperkte toetreding
            '70101', // Concerndiensten binnen eigen concern
            '70102', // Holdings (geen financiële)
        ].includes(this.kvkValue.toString());
    }

    get operation() {
        if (this.isSuspiciousSbi) {
            return 'Negeren';
        }
        if (this.useKvkValue) {
            if (this.rsi === 1) {
                return 'Muteren';
            }
            if (this.rsi === 3) {
                return 'Nieuw';
            }
        }
        if (this.rsi === 2) {
            if (this.removeValue) {
                return 'Verwijderen';
            }
            return 'Verwijdering negeren';
        }
        return 'Negeren';
    }

    get useKvkValue() {
        // determine wether kvk-value should be used based on rsi
        let useKvkValue = this.rsi === 1 || (this.rsi === 3 && !this.vestigingValue && this.vestigingValue !== 0);
        // certain fields require extra logic (see https://jira.sst-labs.nl/browse/LISAV-242 )
        if (this.kvkMutatieFieldName === 'hashtag') {
            useKvkValue = false; // hashtag should only be used for NEW vestiging.
        }
        return useKvkValue;
    }

    get removeValue(): boolean {
        // only mobiel should be removed when rsi is 2.
        if (this.rsi === 2 && this.vestigingFieldName === 'contact.mobiel') {
            return true;
        }
        return false;
    }

    get newValue() {
        if (this.rsi !== null && (this.rsi < 0 || this.rsi > 3)) {
            console.error(this.kvkMutatieRsiFieldName + ' is invalid: value must be 0-3; got value: ' + this.rsi);
        }
        if (this.useKvkValue) {
            return this.getKvkValueByConditions();
        }
        // other RSI values are ignored, thus vestigingValue should be used.
        return this.vestigingValue;
    }

    get newValueIncludeVestigingOpgenomen() {
        if (!this.vestiging) { // Als de oude vestiging waarde null is, is alles nieuw opgenomen
            return this.getKvkValueByConditions();
        } else {
            return this.newValue;
        }
    }

    private getKvkValueByConditions() {
        if (this.kvkMutatieFieldName === 'indicatiehoofdnevenvesting') {
            if (this.kvkValue === 'H') {
                return '01';
            }
            if (this.kvkValue === 'N') {
                return '01';
            }
            return '?';
        }
        return this.kvkValue;
    }
}

// Important note: this mapping only indicates which fields are related.
// They do not provide information about how values should be converted.
// TODO: implement more relationships/mapping based on https://jira.sst-labs.nl/browse/LISAV-242
const kvkToVestigingMapping: any = {
    dossiernummer: '',
    subdossiernummer: '',
    registerletter: '',
    vestigingsnummer: '',  // opnemen (note: this is not the same as vestiging.vestigingsnummer)
    rsiVestigingsnummer: '',
    handelsnaam1x45: 'naam',
    rsiHandelsnaam1x45: '',
    handelsnaam1x2x30: '',
    handelsnaam2x2x30: '',
    rsiHandelsnaam2x30: '',
    handelsnaam1x30: '',
    rsiHandelsnaam1x30: '',
    straatnaamHuisnummerToevoeging: '',
    rsiStraatnaamHuisnummerToevoeging: '',
    straatnaam: '',
    rsiStraatnaam: '',
    huisnummer: '',
    rsiHuisnummer: '',
    toevoegingHuisnummer: '',
    rsiToevoegingHuisnummer: '',
    postkodeWoonplaats: '',
    rsiPostkodeWoonplaats: '',
    postkode: '',
    rsiPostkode: '',
    woonplaatsNen: '',
    rsiWoonplaats: '',
    gemeentecodeVa: '',
    rsiGemeentecodeVa: '',
    vsiVestigingsStatusIndicator: '', // opnemen (kvk-only)
    Correspondentieadres: '',
    caStraatnaamHuisnummerToevoeging: '',
    rsiCaStraatnaamHuisnummerToevoeging: '',
    caStraatnaam: 'correspondent.adres.straat',
    rsiCaStraatnaam: '',
    caHuisnummer: 'correspondent.adres.huisnummer',
    rsiCaHuisnummer: '',
    caToevoegingHuisnummer: 'correspondent.adres.huisletter, correspondent.adres.toevoeging',
    rsiCaToevoegingHuisnummer: '',
    caPostkodeWoonplaats: '',
    rsiCaPostkodeWoonplaats: '',
    caPostkode: 'correspondent.adres.postcode',
    rsiCaPostkode: '',
    caWoonplaatsNen: 'correspondent.adres.plaatsnaam.omschrijving',
    rsiCaWoonplaats: '',
    gegevens: '',
    rechtsvormFijn: 'rechtsvorm.id',
    rsiRechtsvormFijn: '',
    hoofdactiviteitencode: '', // opnemen (kvk-only)
    nevenactiviteitencode1: '', // opnemen (kvk-only)
    nevenactiviteitencode2: '', // opnemen (kvk-only)
    rsiActiviteitencodes: '',
    klasseWerkzamePersonenTotaal: '',  // TODO: dit veld moet worden opgenomen, maar vereist speciale logica
    rsiKlasseWerkzamePersonenTotaal: '',
    klasseWerkzamePersonenFulltime: '',  // TODO: dit veld moet worden opgenomen, maar vereist speciale logica
    rsiKlasseWerkzamePersonenFulltime: '',
    importFunctie: '',
    exportFunctie: '',
    rsiImExportFunctie: '',
    telefoonnummer: 'contact.telefoon',
    rsiTelefoonnummer: '',
    indicatieFaillissement: '',
    rsiIndicatieFaillissement: '',
    indicatieSurseanceVanBetaling: '',
    rsiIndicatieSurseanceVanBetaling: '',
    beherendKamernummer: '',
    rsiBeherendKamernummer: '',
    Contactpersoongegevens: '',
    functionarisCode: '',
    rsiFunctionarisCode: '',
    tenaamstelling1x2x30: '',
    tenaamstelling2x2x30: '',
    rsiTenaamstelling2x30: '',
    tenaamstelling1x45: 'wpContact.naam',
    rsiTenaamstelling1x45: '',
    briefaanhef: '',
    rsiBriefaanhef: '',
    aanspreektitel: '',
    rsiAanspreektitel: '',
    eersteTitel: '',
    tweedeTitel: '',
    voorletters: '',
    voorvoegsels: '',
    achternaam: '',
    rsiContactPersoon: '',
    sexeCode: '',
    rsiSexeCode: '',
    indicatieHoofdNevenvesting: 'type.code',
    rsiIndicatieHoofdNevenvesting: '',
    vennootschapsnaamHr: '',
    rsiVennootschapsnaamHr: '',
    domeinnaam: 'contact.website',
    rsiDomeinnaam: '',
    mobielTelefoonnummer: 'contact.mobiel',
    rsiMobielTelefoonnummer: '',
    datumInschrijving: 'sbiMutatieDatum',
    rsiDatumInschrijving: '',
    datumOpheffing: 'datumEinde',
    rsiDatumOpheffing: '',
    datumVoortzetting: '',
    rsiDatumVoortzetting: '',
    datumAanvangFaillissement: '',
    rsiDatumAanvangFaillissement: '',
    datumEindeFaillissement: '',
    rsiDatumEindeFaillissement: '',
    datumAanvangSurseance: '',
    rsiDatumAanvangSurseance: '',
    datumEindeSurseance: '',
    rsiDatumEindeSurseance: '',
    redenUitschrijving: '',
    rsiRedenUitschrijving: '',
    redenOpheffing: '',
    rsiRedenOpheffing: '',
    soortOntbinding: '',
    rsiSoortOntbinding: '',
    boekjaarDeponeringJaarstuk: '',
    rsiBoekjaarDeponeringJaarstuk: '',
    datumDeponeringJaarstuk: '',
    rsiDatumDeponeringJaarstuk: '',
    aantalDochters: '',
    rsiAantalDochters: '',
    indicatieEconomischActief: '', // opnemen (kvk only?)
    rsiIndicatieEconomischActief: '',
    indicatieOndernemingsstructuur: '',
    rsiIndicatieOndernemingsstructuur: '',
    nonMailingIndicator: '', // opnemen (kvk only?)
    rsiNonMailingIndicator: '',
    landcodeCorrespondentieAdres: '',
    rsiLandcodeCorrespondentieAdres: '',
    exactAantalWerkzamePersonenTotaal: '', // TODO: dit veld moet worden opgenomen, maar vereist speciale logica
    rsiExactAantalWerkzamePersonenTotaal: '',
    exactAantalWerkzamePersonenFulltime: '', // TODO: dit veld moet worden opgenomen, maar vereist speciale logica
    rsiExactAantalWerkzamePersonenFulltime: '',
    peildatumWerkzamePersonenVestiging: '', // TODO: dit veld moet worden opgenomen, maar vereist speciale logica
    rsiPeildatumWerkzamePersonenVestiging: '',
    datumVestiging: '', // openemen (kvk only?) - TODO: hoe verschilt dit tov datumOprichting?
    rsiDatumVestiging: '',
    datumOprichting: '', // we used 'datumStart' as mapped value, but that doesn't seem correct.
    rsiDatumOprichting: '',
    datumAkteOprichting: '',
    rsiDatumAkteOprichting: '',
    handelsnaamHrVolledig: '',
    rsiHandelsnaamHrVolledig: '',
    gestortKapitaal: '',
    rsiGestortKapitaal: '',
    geplaatstKapitaal: '',
    rsiGeplaatstKapitaal: '',
    statutaireZetel: '',
    rsiStatutaireZetel: '',
    redenInschrijving: '',
    rsiRedenInschrijving: '',
    statutairInschrijfnummer: '',
    rsiStatutairInschrijfnummer: '',
    datumVestigingHuidigAdres: 'datumVestigingHuidigAdres',
    rsiDatumVestigingHuidigAdres: '',
    gemeentecodeCa: '',
    rsiGemeentecodeCa: '',
    rsin: 'rsin',
    rsiRsin: '',
    provinciekode: '',
    rsiProvinciekode: '',
    aantalWerkzamePersonenTotaalOnderneming: '',
    rsiAantalWerkzamePersonenTotaalOnderneming: '',
    klasseWerkzamePersonenTotaalOnderneming: '',
    rsiKlasseWerkzamePersonenTotaalOnderneming: '',
    peildatumWerkzamePersonenTotaalOnderneming: '',
    rsiPeildatumWerkzamePersonenTotaalOnderneming: '',
    soortOrganisatie: 'vestigingType.code',
    rsiSoortOrganisatie: '',
    maatschappelijkeKapitaal: '',
    rsiMaatschappelijkeKapitaal: '',
    toegevoegde: '',
    hashtag: 'vestiging.opmerking',
    rsiHashtag: '',
    gemherkomst: '',
    rsiGemherkomst: '',
    gembestemming: '',
    rsiGembestemming: '',
    gemnrva: '',
    rsiGemnrva: '',
    redenin: 'wijzeStart.code',
    rsiRedenin: '',
    redenuit: 'wijzeEinde.code',
    rsiRedenuit: '',
    bagadres: '', // opnemen (kvk only?) -> TODO: indicatie bagadres? Wat houdt indicatie in?
    rsiBagadres: '',
    straat: 'adres.straat',
    rsiStraat: '',
    huisNr: 'adres.huisnummer',
    rsiHuisNr: '',
    toevoeging: 'adres.huisletter, adres.toevoeging',
    rsiToevoeging: '',
    postcode: 'adres.postcode',
    rsiPostcode: '',
    plaats: 'adres.plaatsnaam.omschrijving',
    rsiPlaats: '',
    gemnr: 'adres.gemeente.code',
    rsiGemnr: '',
    sbi08: 'sbi.code',
    rsiSbi08: '',
    controleGetal: '',
};
