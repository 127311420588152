

































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {min, required} from '@/utils/validation';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {buildEmptyImport, IImportRunCreate} from '@/models/IImportRunCreate';
import {IImportTemplate} from '@/models/IImportTemplate';
import {Pagination} from '@/utils/api-tools/pagination';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import ImportPreview from '@/views/imports/ImportPreview.vue';
import {IRegister} from '@/models/IRegister';

@Component({
    filters: {},
    components: {
        ImportPreview,
        SimpleConfirmDialog,
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class ImportRunCreate extends Mixins(ShowsMessages, AuthorizationMixin) {
    public registers: IRegister[] = [];

    public importRun: IImportRunCreate = buildEmptyImport();
    public importTemplates: IImportTemplate[] = [];
    public loadingData: boolean = true;
    public showPreviewDialog: boolean = false;
    public get rules() {
        return {
            description: [],
            file: [],
            importTemplate: [required],
            appendOnly: [],
            haltOnError: [],
            startRow: [min(1)],
        };
    }

    public get canSave() {
        return this.isAdmin;
    }

    public get showRegisterField() {
        const type = this.importRun?.importTemplate?.mappedObject?.valueOf() ?? 0;
        return type >= 100 && type <= 115;
    }

    public async beforeMount(): Promise<void> {
        const registerResponse = await this.$api.register.list(new Pagination(0, -1), []);
        if (registerResponse.success) {
            this.registers = registerResponse.data!.content;
        }

        const response = await this.$api.importTemplate.list(new Pagination(0, -1), []);
        if (response.success) {
            this.importTemplates = response.data!.content;
        }
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.importRun.file != null && result) {
            this.loadingData = true;
            try {
                const response = await this.$api.import.import(this.importRun!);
                this.showMessage('De import taak is aangemaakt.');
                this.$router.push({name: 'importList'});
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }
}
