import {GenericApi, IWrapperResponse} from '@/api/generic';
import {put, url} from '@/utils/http';
import {Idable} from '@/models/Idable';
import {IMeetperiode} from '@/models/constants/IMeetperiode';

export class WerknemerStatApi extends GenericApi<Idable> {

    constructor() {
        super('/api/v1/werknemer-stat');
    }

    public async freeze(meetperiode: IMeetperiode, registerId: number) {
        const uri = new URL(url(this.path + `/freeze`));
        const result = await put(uri.href, {meetperiode, registerId});
        return result.data as IWrapperResponse<boolean>;
    }
}
