
















import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import DateInput from '@/components/DateInput.vue';
import {buildEmptyMeetperiode, IMeetperiode} from '@/models/constants/IMeetperiode';
import {convertToDate} from '@/utils/filters';

@Component({
    filters: {},
    components: {
        DateInput,
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class MeetperiodeEdit extends Mixins(BasicCrudMixin) {
    public modelObject: IMeetperiode | null = null;

    public getApi(): GenericApi<Idable> {
        return this.$api.meetperiode;
    }

    public get rules() {
        return {
            naam: [required],
            peildatumStartOfDay: [required],
        };
    }

    public buildEmptyModelData(): IMeetperiode {
        return buildEmptyMeetperiode();
    }
}
