import {Idable} from '@/models/Idable';

export interface IMeetperiode extends Idable {
    naam: string;
    peildatumStartOfDay: string;
    peildatumEndOfDay: string;
    bevrorenOp: number;
}

export const FREEZE_ALL_REGISTER_ID = -1;
