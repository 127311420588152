










































































import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {buildEmptyExport, IExportRunCreate} from '@/models/IExportRunCreate';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';
import {buildAndFilter, buildExpressionFilter, buildOrFilter, IFilter} from '@/models/IFilter';
import {required} from '@/utils/validation';
import {filterTypeText} from '@/utils/filters';
import FilterBuilder from '@/views/exports/components/FilterBuilder.vue';
import TemplateRow from '@/views/exports/components/TemplateRow.vue';
import {IExportTemplateInput} from '@/models/IExportTemplateInput';

@Component({
    filters: {
        filterTypeText,
    },
    components: {
        TemplateRow,
        FilterTemplateInput: TemplateRow,
        BaseEditComponent,
        SimpleMessageWrapper,
        FilterBuilder,
    },
})
export default class ExportRunCreate extends Mixins(ShowsMessages, AuthorizationMixin) {
    public exportRun: IExportRunCreate = buildEmptyExport();
    public oldExportRun: IExportRunCreate | null = null;
    public loadingData: boolean = true;
    public templateInputs: IExportTemplateInput[] = [];

    public searchableFields: string[] = [];
    public exportableFields: string[] = [];
    public operators: Array<{ label: string, value: string }> = [
        {label: 'Gelijk (=)', value: 'eq'},
        {label: 'Bevat', value: 'contains'},
        {label: 'Niet gelijk (!=)', value: 'ne'},
        {label: 'Groter dan (>)', value: 'gt'},
        {label: 'Groter dan of gelijk aan (>=)', value: 'goe'},
        {label: 'Minder dan (<)', value: 'lt'},
        {label: 'Minder dan of gelijk aan (<=)', value: 'loe'},
        {label: 'Is leeg', value: 'isNull'},
        {label: 'Is niet leeg', value: 'isNotNull'},
        {label: 'Is lege verzameling', value: 'isEmptySet'},
        {label: 'In', value: 'in'},
    ];

    private isEditing: boolean = false;
    private deleteDialog: boolean = false;

    public get rules() {
        return {
            description: [required],
            fields: [required],
            filter: {
                field: [required],
                operator: [required],
                value: [required],
            },
        };
    }

    public get hasWerknemerStatField() {
        return this.exportRun.fields.find((field) => {
            return field.includes('werknemerStat');
        });
    }

    public get hasWerknemerStatInFilter() {
        return JSON.stringify(this.exportRun.filters).includes('werknemerStats.');
    }

    public get hasMeetperiodeField() {
        return this.exportRun.fields.find((field) => {
            return field.includes('meetperiode');
        });
    }

    public get api() {
        return this.$api.export;
    }

    public get canUpdate() {
        return this.isRoleAnalist || this.isRoleDatabeheer;
    }

    public get canCreate() {
        return this.isRoleAnalist || this.isRoleDatabeheer;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public async beforeMount(): Promise<void> {
        const fieldResponse = await this.api.searchableFields();
        if (fieldResponse.success) {
            this.searchableFields = fieldResponse.data!;
        }

        const exportFieldResponse = await this.api.exportableFields();
        if (exportFieldResponse.success) {
            this.exportableFields = exportFieldResponse.data!;
        }
        await this.loadData();
    }

    public async loadData() {
        this.loadingData = true;
        try {
            const response = await this.api.get(Number(this.$route.params.id));
            const exportRun = response.data! as any;
            exportRun.filters = JSON.parse(exportRun.filters);
            exportRun.fields = JSON.parse(exportRun.fields);
            if (!exportRun.filters) {
                exportRun.filters = buildAndFilter();
            } else {
                if (exportRun.filters.filters) {
                    exportRun.filters.filters =
                        this.removeFiltersWithNotExportableFieldnames(exportRun.filters.filters);
                }
            }
            if (exportRun.fields) {
                exportRun.fields = exportRun.fields.filter((field: string) => {
                    return this.exportableFields.includes(field);
                });
            }

            const allFilters: IFilter = exportRun.filters;
            const filteredFields: string[] = this.getAllNames(allFilters).filter((field) => field);
            this.templateInputs = (await this.$api.export.getExportTemplateInput(filteredFields)).data! || [];

            this.exportRun = exportRun;

            this.oldExportRun = this.$_.cloneDeep(this.exportRun);
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public getAllNames(obj: IFilter, names: string[] = []): string[] {
        names.push(obj.field);
        obj.filters.forEach((child) => {
            this.getAllNames(child, names);
        });
        return names;
    }

    public removeFiltersWithNotExportableFieldnames(filters: IFilter[]) {
        return filters.filter((filter: IFilter) => {
            const fieldExportable = !filter.field || this.exportableFields.includes(filter.field);
            const subFilters: IFilter[] = filter.filters || [];
            return fieldExportable && !this.containsNonExportableFieldName(subFilters);
        });
    }

    public containsNonExportableFieldName(filters: IFilter[]): boolean {
        if (!filters || filters.length === 0) {
            return false;
        } else {
            const filtersContainNonExportableFieldname = filters.find((filter: IFilter) => {
                const filterFieldname = filter.field;
                const isFilterFieldnameExportable =
                    !filterFieldname || this.exportableFields.includes(filterFieldname);
                const hasSubFilterNonExportableField =
                    filter.filters ? this.containsNonExportableFieldName(filter.filters) : false;
                return !isFilterFieldnameExportable || hasSubFilterNonExportableField;
            });
            return !!filtersContainNonExportableFieldname;
        }
    }

    public async save(close: boolean = true, isExcel: boolean = false) {
        const result = (this.$refs.form as any).validate();
        if (result) {
            this.loadingData = true;
            try {
                const exportRun: IExportRunCreate = {
                    fields: this.exportRun.fields,
                    filters: this.exportRun.filters,
                    description: this.exportRun.description,
                    isTemplate: false,
                    explanation: this.exportRun.explanation,
                } as any;
                if (isExcel) {
                    exportRun.isExcelExport = true;
                }
                const response = await this.$api.export.create(exportRun);
                this.showMessage('De export taak is aangemaakt.');
                if (close) {
                    this.$router.push({name: 'exportList'});
                }
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    public addVestigingFields() {
        // Only add first order vestiging fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => !x.includes('.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addAdresFields() {
        // Only add first order adres fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => x.startsWith('adres.') &&
                !x.replace('adres.', '').includes('.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addWerknemerStatFields() {
        // Only add first order werknemerStat fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => x.startsWith('werknemerStats.') &&
                !x.replace('werknemerStats.', '').includes('.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addCbFields() {
        // Add all cb fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => x.startsWith('cb.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addContactFields() {
        // Only add first order contact fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => x.startsWith('contact.') &&
                !x.replace('contact.', '').includes('.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addWpContactFields() {
        // Only add first order wpContact fields
        if (this.exportableFields) {
            this.exportableFields.filter((x) => x.startsWith('wpContact.') &&
                !x.replace('wpContact.', '').includes('.')).forEach((value) => {
                if (!this.exportRun.fields.includes(value)) {
                    this.exportRun.fields.push(value);
                }
            });
        }
    }

    public addFilter(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildExpressionFilter());
        }
    }

    public addAndFilterGroup(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildAndFilter());
        }
    }

    public addOrFilterGroup(filter: IFilter) {
        if (filter) {
            if (!filter.filters) {
                filter.filters = [];
            }
            filter.filters.push(buildOrFilter());
        }
    }

    public removeFilter(columnTemplate: IImportColumnTemplate) {
        if (this.exportRun.filters != null) {
            const columnTemplateIdx = this.exportRun.filters.filters.findIndex((t: any) => t === columnTemplate);
            if (columnTemplateIdx >= 0) {
                this.exportRun.filters.filters.splice(columnTemplateIdx, 1);
            }
        }
        this.deleteDialog = false;
    }
}
