import {GenericApi, IWrapperResponse} from '@/api/generic';
import {http, url} from '@/utils/http';
import {Idable} from '@/models/Idable';
import {IExportRunCreate} from '@/models/IExportRunCreate';
import {IExportTemplateInput} from '@/models/IExportTemplateInput';

export class ExportApi extends GenericApi<Idable> {

    constructor() {
        super('/api/v1/export');
    }

    public async searchableFields() {
        const uri = new URL(url(`/api/v1/export/searchableFields`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<string[]>;
    }

    public async exportableFields() {
        const uri = new URL(url(`/api/v1/export/exportableFields`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<string[]>;
    }

    public async getExportTemplates() {
        const uri = new URL(url(`/api/v1/export/templates`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IExportRunCreate[]>;
    }

    public async getExportTemplateInput(fields: string[]) {
        const uri = new URL(url(`/api/v1/export/template-input-fields`));
        const result = await http.post(uri.href, { fields });
        return result.data as IWrapperResponse<IExportTemplateInput[]>;
    }

    public async cancelAll() {
        const uri = new URL(url(`/api/v1/export/cancel-all`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

}
