







































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {Role} from '@/models/IGebruiker';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {$t} from '@/plugins/i18n';

@Component({
    components: {AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class UserList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Gebruikersnaam', value: 'gebruikersnaam'},
        {text: 'E-mail adres', value: 'email'},
        {text: 'Naam', value: 'naam'},
        {text: 'Rollen', value: 'rollen', sortable: false},
        {text: '2FA', value: 'mfa', sortable: false},
        {text: '', value: 'action', sortable: false},
    ];

    public readonly disabledOptions: Array<{ label: string, value: any }> = [
        {label: 'Uitgeschakeld', value: true},
        {label: 'Actief', value: false},
    ];

    public get api() {
        return this.$api.user;
    }

    public displayRoles(roles: Role[]) {
        return this.$_.map(roles, (x) => {
            return $t('roles.' + x);
        }).join(', ');
    }

    public displayBoolean(bool: boolean) {
        return bool ? 'Ja' : 'Nee';
    }
}
