import {GenericApi, IWrapperResponse} from '@/api/generic';
import {http, url} from '@/utils/http';

export class ConstantIdToMutationInfo extends GenericApi<string> {

    constructor() {
        super('/api/v1/constant-id-to-code');
    }

    public async getConstantInfo(constantType: string, constantId: number) {
        const uri = new URL(url(`/api/v1/constant-id-to-code/${constantType}/${constantId}`));
        const result = await http.get(uri.href);
        return result.data as string;
    }

}
