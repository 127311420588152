



















































































import {Component, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import {IKvkLinkOptions} from '@/models/IKvkLinkOptions';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {ListsObjects} from '@/mixins/ListsObjects';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IKvk} from '@/models/IKvk';
import {IGrootteklasse} from '@/models/constants/IGrootteklasse';
import {IGemeente} from '@/models/constants/IGemeente';
import {ISbi} from '@/models/constants/ISbi';

@Component({
    filters: {},
})
export default class KvkLinkToVestigingChanges extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({required: true})
    public kvk!: IKvk;

    @Prop({required: true})
    public kvkLinkOptions!: IKvkLinkOptions;

    public canUpdate: boolean = false;

    public refresher: number = 0;

    public kvkGrootteklasse: string = '';

    public kvkGemeente: string = '';

    public kvkSbi: string = '';


    @Watch('vestiging' , {immediate: true})
    public onChange() {
        this.refresher++;
        this.loadGrootteklasseNaam();
        this.loadGemeenteNaam();
        this.loadSbiNaam();
    }

    public async loadGemeenteNaam() {
        const gemeenteCode = this.kvk.kvkCma.gemnr;
        if (!gemeenteCode) {
            return '-';
        } else {
            const gemeente = (await this.$api.gemeente.getByCode(gemeenteCode)).data;
            if (gemeente) {
                this.kvkGemeente =  gemeenteCode + ' - ' + (gemeente as IGemeente).omschrijving;
            } else {
                this.kvkGemeente = '- (' + gemeenteCode + ' is onbekend)';
            }
        }
    }

    public async loadGrootteklasseNaam() {
        const code = this.kvk.kvkCma.klasseWerkzamePersonenTotaal;
        if (!code) {
            this.kvkGrootteklasse = '-';
        } else {
            const result = (await this.$api.grootteklasse.getByCode(code)).data;
            if (result) {
                this.kvkGrootteklasse =  code + ' - ' + (result as IGrootteklasse).omschrijving;
            } else {
                this.kvkGrootteklasse = code;
            }
        }
    }

    public getHuisletter(result: string) {
        if (!result) {
            return '-';
        } else if (this.isHuisletterOnly(result)) {
            return result;
        } else if (this.isStartingWithHuisletter(result)) {
            return result.charAt(0);
        }
    }

    public getToevoeging(result: string) {
        if (!result) {
            return '-';
        } else if (this.isHuisletterOnly(result)) {
            return '-';
        } else if (this.isStartingWithHuisletter(result)) {
            return result.slice(2);
        } else {
            // return result;
            return result;
        }
    }

    public isHuisletterOnly(text: string) {
        const isSingleChar = text.length === 1;
        const isNonNumber = this.isFirsCharNonNumeric(text);
        return isSingleChar && isNonNumber;
    }

    public isFirsCharNonNumeric(text: string) {
        return !/\d/.test(text.charAt(0));
    }

    public async loadSbiNaam() {
        const sbiCode = this.kvk.kvkCma.sbi08;
        if (!sbiCode) {
            return '-';
        } else {
            const sbi = (await this.$api.sbi.getByCode(sbiCode)).data;
            if (sbi) {
                this.kvkSbi = sbiCode + ' - ' + (sbi as ISbi).omschrijving;
            } else {
                this.kvkSbi = '- (' + sbiCode + ' is onbekend)';
            }
        }
    }

    public isStartingWithHuisletter(text: string) {
        const isLengthLongerThanOne = text.length > 1;
        if (!isLengthLongerThanOne) {
            return false;
        } else {
            const isFirstCharFollowedBySpace = /\s/.test(text.charAt(1));
            if (this.isFirsCharNonNumeric(text) && isFirstCharFollowedBySpace) {
                return true;
            } else {
                return false;
            }
        }
    }
}
