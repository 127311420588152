





















import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';

@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class UitzetCodeEdit extends Mixins(BasicCrudMixin) {
    public getApi(): GenericApi<Idable> {
        return this.$api.uitzetCode;
    }

    public get rules() {
        return {
            omschrijving: [required],
            id: [required],
        };
    }

    public buildEmptyModelData(): Idable {
        return {
            omschrijving: null,
        } as unknown as Idable;
    }
}
