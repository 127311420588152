







































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {mappedObjectText} from '@/utils/filters';

@Component({
    filters: {
        mappedObjectText,
    },
    components: {AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class ImportTemplateList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Naam', value: 'name'},
        {text: 'Type', value: 'mappedObject'},
        {text: '', value: 'action', sortable: false},
    ];

    public get api() {
        return this.$api.importTemplate;
    }
}
