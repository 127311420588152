import {GenericApi, IWrapperResponse} from '@/api/generic';
import {http, post, url} from '@/utils/http';
import {Idable} from '@/models/Idable';
import {IImportRun} from '@/models/IImportRun';

export class ImportApi extends GenericApi<IImportRun> {

    constructor() {
        super('/api/v1/freeform-import');
    }

    public async import(model: Idable) {
        const uri = new URL(url(this.path));
        const formData = new FormData();
        formData.append('file', (model as any).file);

        // Unset file as this is received separately in the backend (not in the model)
        delete (model as any).file;

        formData.append('importCreateRequest', new Blob([JSON.stringify(model)], {type: 'application/json'}));
        const result = await post(uri.href, formData);
        return result.data as IWrapperResponse<Idable>;
    }

    public async cancel(model: Idable) {
        const uri = new URL(url(`/api/v1/freeform-import/${model.id}/cancel`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async cancelAll() {
        const uri = new URL(url(`/api/v1/freeform-import/cancel-all`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }
}
