








































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {MutatieCode} from '@/models/IVestiging';
import {IMutatieSoort} from '@/models/IMutatieSoort';

@Component({
    components: {StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class MutatieList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'Naam', value: 'vestiging.naam'},
        {text: 'Mutatiesoort', value: 'mutatieSoort'},
        {text: 'Mutatie code', value: 'mutatieCode'},
        {text: 'Tijd', value: 'toegevoegdOp'},
        {text: 'Gebruiker', value: 'toegevoegdDoor'},
        {text: '', value: 'action', sortable: false},
    ];

    public mutatieSoorten: IMutatieSoort[] = [];

    public searchValue: any = {};
    public startDatum: string | null  = null;
    public eindDatum: string | null = null;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.mutatie;
    }

    public toEdit(object: string|number|any) {
        this.$router.push({name: 'vestigingEdit', params: { id: object.vestigingId.toString() }});
    }

    @Watch('startDatum')
    public onStartDateChanged() {
        if (this.startDatum) {
            this.searchValue['toegevoegdOp:goe'] = this.dateToTimestampSeconds(this.startDatum, false);
        }
    }

    @Watch('eindDatum')
    public onEndDateChanged() {
        if (this.eindDatum) {
            this.searchValue['toegevoegdOp:loe'] = this.dateToTimestampSeconds(this.eindDatum, true);
        }
    }

    public dateToTimestampSeconds(dateString: string, endOfDay = false): string {
        const date = new Date(dateString);
        if (endOfDay) {
            date.setHours(23, 59, 59, 999);
        }
        const amsterdamTimezoneOffset = -120; // Offset in minutes for GMT+02:00

        return Math.round((date.getTime() + amsterdamTimezoneOffset * 60 * 1000) / 1000).toString();
    }

    public get mutatieCodeOptions() {
        return Object.entries(MutatieCode)
            .filter(([key, value]) => isNaN(Number(key)))
            .map(([label, value]) => ({ label, value }));
    }

    public get mutatieSoortOptions() {
        return [
            'START',
            'ENV',
            'KVKNR',
            'OPV',
            'SBI',
            'ACT',
            'NAAM',
            'ADRES',
            'AFV',
            'BEHANDELING',
        ];
        // return this.mutatieSoorten.map((item) => {
        //     return {label: item.code, value: item.id};
        // });
    }

    public get rules() {
        return {
        };
    }

    public getMutatieSoortenString(mutatiesoorten: IMutatieSoort[]): string {
        return mutatiesoorten != null ? mutatiesoorten.map((mutatiesoort) => mutatiesoort.code).join(', ') : '';
    }

    public getMutatieCodeString(mutatieCode: MutatieCode) {
        return MutatieCode[mutatieCode];
    }

    public getDateTimeFromTimestamp(timestamp: number): string {
        return new Date(timestamp * 1000).toLocaleString();
    }

    public async created() {
        this.mutatieSoorten =  (await this.$api.mutatieSoort.listAll()).data?.content || [];
        this.search();
    }

    public async clear() {
        this.searchValue = {};
        this.search();
    }
}
