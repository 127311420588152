
























import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {get, url} from '@/utils/http';
import {IWrapperResponse} from '@/api/generic';

@Component({
    filters: {},
    components: {SimpleMessageWrapper, AutoLoadingButton},
})
export default class ResetData extends Mixins(ShowsMessages) {
    public async deleteAll() {
        const uri = new URL(url(`/test/delete-data`));
        const result = await get(uri.href);
        const data = result.data;
        if (data === 'done') {
            alert('De gegevens zijn nu verwijderd.');
        }
    }
}
