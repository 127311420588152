









































































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import { Component, Mixins } from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import { ShowsMessages } from '@/mixins/ShowsMessages';
import { AuthorizationMixin } from '@/mixins/AuthorizationMixin';
import { IImportTemplate } from '@/models/IImportTemplate';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import { BagImportStatus, BagStep, IBagImportRun } from '@/models/IBagImportRun';
import { dateTime } from '@/utils/filters';
import { IBagImportRunCreate } from '@/models/IBagImportRunCreate';

@Component({
    filters: {
        dateTime,
    },
    components: {
        AutoLoadingButton,
        SimpleConfirmDialog,
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class BagImport extends Mixins(ShowsMessages, AuthorizationMixin) {
    public files: any = null;
    public importTemplates: IImportTemplate[] = [];
    public loadingData: boolean = true;
    public BagImportStatus = BagImportStatus;
    public BagStep = BagStep;

    public model: IBagImportRun | null = null;

    public inProgress: boolean = true;
    public adresProgress: number = 0.0;
    public insertProgress: number = 0.0;

    public get rules() {
        return {
            file: [],
        };
    }

    public get mimeTypes() {
        return [
            'text/csv',
        ];
    }

    public get showLastRunInfo() {
        return !!this.model && this.model.status === BagImportStatus.DONE && !this.inProgress;
    }

    public get showLastRunError() {
        return !!this.model && this.model.status === BagImportStatus.CANCELED;
    }

    public get canSave() {
        return this.isAdmin && !this.inProgress;
    }

    public async beforeMount(): Promise<void> {
        await this.loadData();
    }

    public get fileOutOfFiles(): number {
        if (!this.inProgress) {
            return 0;
        }
        const index = this.model?.filenames.indexOf(this.model?.currentFilename);
        if (!index || index === -1) {
            return 0;
        }
        return index + 1;
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.files != null && result) {
            this.loadingData = true;
            try {
                const response = await this.$api.bag.import({
                    file: this.files.pop(),
                    more: this.files.length > 0,
                } as IBagImportRunCreate);

                // Support for run with multiple files
                if (response.data && response.success) {
                    const id = response.data.id;
                    while (this.files.length > 0) {
                        await this.$api.bag.import({
                            file: this.files.pop(),
                            more: this.files.length > 0, // sets more to false on last file, to trigger job to execute.
                            importRunId: id,
                        } as IBagImportRunCreate);
                    }
                }
                await this.loadData();
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    public async loadData() {
        this.loadingData = true;
        try {
            const response = await this.$api.bag.getImport();
            this.model = response.data;

            this.setStepByStatus();

            this.inProgress = !!this.model && this.model?.status !== null
                && (this.model?.status < 100 && this.model.status !== BagImportStatus.CANCELED);
            if (!!this.model) {
                if (this.model?.selectedStep === BagStep.UPDATE) {
                    this.adresProgress = (this.model.adresRowsProcessed / this.model.adresRows) * 100;
                }

                if (this.model?.selectedStep === BagStep.INSERT) {
                    const insertProgress = (this.model.insertRowsProcessed / this.model.insertRows) * 100;
                    if (insertProgress > 100) {
                        console.warn(
                            'Insert progress would be over 100%: ',
                            insertProgress,
                            this.model.insertRowsProcessed,
                            this.model.insertRows,
                        );
                    }
                    this.insertProgress = Math.min(insertProgress, 100);
                }
            }

            if (this.inProgress) {
                setTimeout(() => this.loadData(), 2500);
            }

        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public get status() {
        return this.model!.status;
    }

    public setStepByStatus() {
        switch (this.status) {
            case BagImportStatus.UPLOAD_DONE:
                this.model!.selectedStep = BagStep.UPLOAD;
                break;
            case BagImportStatus.PARSING_IN_PROGRESS:
            case BagImportStatus.PARSING_DONE:
            case BagImportStatus.POST_PROCESS_DONE:
                this.model!.selectedStep = BagStep.PARSING;
                break;
            case BagImportStatus.INSERT_IN_PROGRESS:
            case BagImportStatus.INSERT_DONE:
                this.model!.selectedStep = BagStep.INSERT;
                break;
            case BagImportStatus.UPDATE_ADRES_IN_PROGRESS:
            case BagImportStatus.UPDATE_ADRES_DONE:
                this.model!.selectedStep = BagStep.UPDATE;
                break;
        }
    }
}
