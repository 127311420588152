











import {Component, Model, Prop, Vue} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import {dateTime} from '@/utils/filters';
import VestigingKvkMutatieInfo from '@/views/vestigingen/components/VestigingKvkMutatieInfo.vue';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    filters: {
        dateTime,
    },
    components: {VestigingKvkMutatieInfo, ContactCard},
})
export default class VestigingKvkMutaties extends Vue {
    @Model('input')
    public vestiging!: IVestiging;

    private kvkMutaties: IKvkMutatie[] = [];

    public async created() {
        const id = this.vestiging.id;
        if (id) {
            this.kvkMutaties = (await this.$api.kvkMutatie.getByVestiging(id)).data || [];
        }
    }
}
