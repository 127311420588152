
































import {Component, Vue} from 'vue-property-decorator';
import {State} from 'vuex-class';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import { maxLength, minLength, required } from '@/utils/validation';

@Component({
    components: {AutoLoadingButton},
})
export default class MfaLogin extends Vue {
    public token: string = '';
    public showError: boolean = false;
    public error: string = '';

    @State((state: any) => state.authentication.authenticated)
    public isLoggedIn!: boolean;

    public tokenRules = [required, minLength(6), maxLength(6)];

    public created() {
        if (this.isLoggedIn) {
            this.$router.push({name: 'home'});
        }
    }

    public async login($event: Event, a: boolean) {
        if ((this.$refs.form as any).validate()) {
            try {
                await this.$api.auth.mfaLogin(this.token);
                await this.$router.push({name: 'home'});
            } catch (e) {
                this.showError = true;
                this.error = (e && e.response && e.response.status === 401) ?
                    'Token incorrect' : 'Er is een fout opgetreden.';
            }
        }
    }
}
