

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    filters: {},
    components: {},
})
export default class StatusColorChip extends Vue {
    @Prop()
    private active!: boolean;
    @Prop({default: 'Ja'})
    private activeText!: string;
    @Prop({default: 'Nee'})
    private inactiveText!: string;
}
