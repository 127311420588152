import {GenericApi, IWrapperResponse} from '@/api/generic';
import {mergeURLSearchParams, get, url, put} from '@/utils/http';
import {ISearchCriterion, SearchCriterion} from '@/utils/api-tools/search-criteria';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IVestiging} from '@/models/IVestiging';
import {IWerknemerStat} from '@/models/IWerknemerStat';
import {IKvk} from '@/models/IKvk';

export class VestigingApi extends GenericApi<IVestiging> {
    public async werknemerStats(id: string | number, search: ISearchCriterion[] = []) {
        const uri = new URL(url(this.path + `/${id}/werknemer-stats`));
        const searchParams = search.reduce((prev: URLSearchParams, curr: ISearchCriterion) => {
            return mergeURLSearchParams(prev, SearchCriterion.serialize(curr));
        }, new URLSearchParams());
        mergeURLSearchParams(uri.searchParams, searchParams);

        const result = await get(uri.href);
        return result.data as IWrapperResponse<IPaginationResponse<IWerknemerStat>>;
    }

    public async getByVestigingNummer(nr: string | number) {
        const uri = new URL(url(this.path + `/by-vestiging-nummer/${nr}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IVestiging>;
    }

    public async vestigingenByKvkRun(id: string |number) {
        const uri = new URL(url(this.path + `/by-kvk-upload-run-id/${id}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IPaginationResponse<IVestiging>>;
    }

    /**
     * Get vestiging by id or by vestigingNummer. These should never overlap:
     * - id is unsigned integer (max value 4294967295).
     * - vestigingnummer is 11 characters long (assuming zero-prefix, so from 01000000000 and up).
     */
    public async get(idOrNumber: string | number) {
        if (idOrNumber.toString().length === 11) {
            return this.getByVestigingNummer(idOrNumber);
        }
        const uri = new URL(url(this.path + `/${idOrNumber}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IVestiging>;
    }
}
