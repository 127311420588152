






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    filters: {},
    components: {},
})
export default class AutoLoadingButton extends Vue {
    @Prop({default: false}) public loading!: boolean;
    private pLoading: boolean = false;
    private pListeners: { [key: string]: any } | null = null;

    public get computedLoading() {
        return this.pLoading || this.loading;
    }

    public get modifiedListeners() {
        if (!this.pListeners) {
            const click = async (event: Event) => {
                this.pLoading = true;

                let clickHandlers = this.$listeners.click;
                if (!Array.isArray(clickHandlers)) {
                    clickHandlers = [clickHandlers];
                }

                try {
                    for (const handler of clickHandlers) {
                        await handler(event);
                    }
                } finally {
                    this.pLoading = false;
                }
            };

            this.pListeners = Object.assign({}, this.$listeners, {
                click,
            });
        }
        return this.pListeners;
    }
}
