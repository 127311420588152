














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ShowsMessagesDataObject} from '@/mixins/ShowsMessages';

@Component({
    components: {},
})
export default class SimpleMessageWrapper extends Vue {
    @Prop({default: []})
    public messageData!: ShowsMessagesDataObject[];

    public currentMessage: ShowsMessagesDataObject | null = null;
    public showMessage: boolean = false;
    public messageNumber: number = 0; // ensures snackbar gets re-rendered when value changes.

    @Watch('messageData')
    private onMessageDataChange() {
        if (!this.showMessage) {
            this.showNextMessage();
        } else if (this.currentMessage && !this.messageData.includes(this.currentMessage)) {
            this.showMessage = false;
            this.currentMessage = null;
            this.messageNumber = 0;
        }
    }

    @Watch('showMessage')
    private onCurrentMessageShowChange() {
        if (this.currentMessage && !this.showMessage) {
            this.currentMessage.done = true;
            this.showNextMessage();
        }
    }

    private showNextMessage() {
        const nextMessage = this.messageData.find((messageDataObject) => !messageDataObject.done);
        if (nextMessage) {
            // Use timeout for a slightly smoother transition
            setTimeout(() => {
                this.messageNumber++;
                this.currentMessage = nextMessage;
                this.showMessage = true;
            }, 200);
        }
    }
}
