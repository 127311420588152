import {Idable} from '@/models/Idable';
import {IImportTemplate} from '@/models/IImportTemplate';
import {IRegister} from '@/models/IRegister';

export interface IImportRunCreate extends Idable {
    description: string;
    importTemplate: IImportTemplate;
    register: IRegister|null;
    appendOnly: boolean;
    haltOnError: boolean;
    bagUpdate: boolean;
    file: any;
    startRow: number|null;
    endRow: number|null;
}

export function buildEmptyImport(): IImportRunCreate {
    return {
        description: '',
        importTemplate: null,
        appendOnly: true,
        haltOnError: false,
        file: null,
        startRow: 1,
    } as unknown as IImportRunCreate;
}
