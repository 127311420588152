import {GenericApi, IWrapperResponse} from '@/api/generic';
import {put, url} from '@/utils/http';
import {Idable} from '@/models/Idable';
import {IMeetperiode} from '@/models/constants/IMeetperiode';

export class MeetperiodeApi extends GenericApi<Idable> {

    constructor() {
        super('/api/v1/meetperiode');
    }
}
