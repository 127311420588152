











































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {max, maxDouble, min, minDouble, required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {sectionLetterOptions} from '@/models/constants/ISbi';

@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class SbiEdit extends Mixins(BasicCrudMixin) {
    public sectionLetterOptions: string[] = sectionLetterOptions;

    public getApi(): GenericApi<Idable> {
        return this.$api.sbi;
    }

    public get rules() {
        return {
            code: [required],
            omschrijving: [required],
            naam: [required],
            malePercentage: [required, minDouble(0), maxDouble(100)],
            sectionLetter: [required],
        };
    }

    public get femaleRatio() {
        const malePercentage = (this.modelObject as any)?.malePercentage || 0;
        return malePercentage >= 0 && malePercentage <= 100 ? 100 - malePercentage : null;
    }

    public buildEmptyModelData(): Idable {
        return {
            naam: null,
            omschrijving: null,
            code: null,
            malePercentage: null,
            sectie: null,
        } as unknown as Idable;
    }
}
