








































































































import { Component, Mixins } from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import { ShowsMessages } from '@/mixins/ShowsMessages';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import { mergeURLSearchParams } from '@/utils/http';
import QrcodeVue from 'qrcode.vue';
import { maxLength, minLength, required } from '@/utils/validation';
import { IGebruiker, Role } from '@/models/IGebruiker';
import { AuthorizationMixin } from '@/mixins/AuthorizationMixin';
import {Action, State} from 'vuex-class';
import settings from '@/utils/settings';

@Component({
    filters: {},
    components: {SimpleMessageWrapper, AutoLoadingButton, QrcodeVue},
})
export default class MfaManage extends Mixins(ShowsMessages, AuthorizationMixin) {
    public loadingData: boolean = true;
    public switchMfaEnabled: boolean = true;
    public error: string = '';

    public secret: string | null = null;
    public uri: string | null = null;
    public verificationToken: string | null = null;

    public tokenRules = [required, minLength(6), maxLength(6)];

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    @Action('logout')
    public logoutAction!: () => Promise<void>;

    get isAnalist(): boolean {
        return this.hasRole(Role.ANALIST) || this.isAdmin;
    }

    get userMfaEnabled(): boolean {
        return this.currentUser.mfa;
    }

    public get canSave() {
        return !(this.switchMfaEnabled && this.userMfaEnabled);
    }

    public async created() {
        await this.loadData();
    }

    public get api() {
        return this.$api.auth;
    }

    public async toMainScreen() {
        await this.$router.push({name: 'home'});
    }

    public async logOut() {
        await this.logoutAction();
        await this.$router.push('/login');
    }

    public async loadData(reset: boolean = false) {
        this.loadingData = true;
        // this.switchMfaEnabled = this.userMfaEnabled;

        try {
            const response = await this.api.mfaGenerate(reset);
            if (response.success && response.data != null) {
                this.secret = response.data!.secret;

                const params = new URLSearchParams();
                params.append('secret', this.secret);
                params.append('digits', '6');
                const uri = new URL('otpauth://totp/' + settings.MFA_NAME);
                mergeURLSearchParams(uri.searchParams, params);
                this.uri = uri.href;

                if (reset) {
                    this.currentUser.mfa = false;
                }
            }
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public async save() {
        const form = this.$refs.form as any;
        let result = true;
        if (form) {
            result = form.validate();
        }

        this.loadingData = true;
        try {
            if (this.switchMfaEnabled && !result) {
                return;
            }
            const response = await this.api.mfaManage(this.switchMfaEnabled, this.verificationToken!);
            if (response.success) {
                this.showMessage('2-Factor Authenticatie is succesvol ' +
                    (response.data?.mfaEnabled ? 'ingeschakeld.' : 'uitgeschakeld.'));
                this.currentUser.mfa =  this.switchMfaEnabled = response.data?.mfaEnabled || false;
                await this.loadData();
            }
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het opslaan van de data.');
            console.error('Save error: ', e);
        } finally {
            this.loadingData = false;
        }
    }
}
