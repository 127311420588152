







































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages, ShowsMessagesDataObject} from '@/mixins/ShowsMessages';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';

@Component({
    filters: {},
    components: {
        SimpleMessageWrapper,
    },
})
export default class BaseEditComponent extends Mixins(AuthorizationMixin) {

    @Prop()
    public messages!: ShowsMessagesDataObject[];

    @Prop()
    public canSave!: boolean;

    @Prop()
    public hasObject!: boolean;
}
