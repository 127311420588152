var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v(" Mutaties ")]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Datum / tijd")]),_c('th',[_vm._v("Gebruiker")]),_c('th',[_vm._v("Naam")]),_c('th',[_vm._v("SBI")]),_c('th',[_vm._v("Adres")]),_c('th',[_vm._v("Wijze"),_c('br'),_vm._v("start")]),_c('th',[_vm._v("Datum start")]),_c('th',[_vm._v("Wijze"),_c('br'),_vm._v("einde")]),_c('th',[_vm._v("Datum einde")]),_c('th',[_vm._v("In behandeling")]),_c('th',[_vm._v("KvK dosnr +"),_c('br'),_vm._v(" Kvk vestnr")]),_c('th',{staticStyle:{"max-width":"200px"}},[_vm._v("Toelichting")])])]),_c('tbody',_vm._l((_vm.mutaties),function(mutatie,index){return _c('tr',{key:mutatie.id},[_c('td',{staticStyle:{"max-width":"130px"}},[(mutatie.toegevoegdOp)?[_vm._v(" "+_vm._s(_vm._f("dateTime")(mutatie.toegevoegdOp))+" ")]:[_vm._v(" Initiëel ")]],2),_c('td',[(mutatie.toegevoegdDoor)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(mutatie.toegevoegdDoor.gebruikersnaam)+" ")])]}}],null,true)},[(mutatie.mutatieCode === _vm.MutatieCode.AUTOMATISCH)?_c('span',[_vm._v(" Automatisch (bijvoorbeeld via wijzigingen op basis van KvK) ")]):(mutatie.mutatieCode === _vm.MutatieCode.EXTERN)?_c('span',[_vm._v(" Extern ")]):_c('span',[_vm._v(" Handmatige wijziging ")])])]:(mutatie.mutatieCode === _vm.MutatieCode.AUTOMATISCH)?[_vm._v(" AUTO ")]:(mutatie.mutatieCode === _vm.MutatieCode.EXTERN)?[_vm._v(" EXTERN ")]:[_vm._v(" - ")]],2),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedName(index) },staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(mutatie.naam)+" ")]),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedSbi(index) }},[(mutatie.sbi)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(mutatie.sbi.code)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(mutatie.sbi.omschrijving))])]):_vm._e()],1),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedAdres(index) }},[(mutatie.adres)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(mutatie.adres.straat)+" "+_vm._s(mutatie.adres.huisnummer)+" "+_vm._s(mutatie.adres.huisletter)+" "+_vm._s(mutatie.adres.toevoeging)),_c('br'),_vm._v(" "+_vm._s(mutatie.adres.postcode)+" "+_vm._s(mutatie.adres.plaatsnaam.omschrijving)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Perceelnummer = "+_vm._s(mutatie.adres.perceelnummer)+" "),_c('br'),_vm._v(" Gemeente "+_vm._s(mutatie.adres.gemeente.code)+" = "+_vm._s(mutatie.adres.gemeente.omschrijving)+" ")])]):_vm._e()],1),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedWijzeStart(index) }},[(mutatie.wijzeStart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(mutatie.wijzeStart.code)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(mutatie.wijzeStart.omschrijving))])]):_vm._e()],1),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedDatumStart(index) },staticStyle:{"width":"110px"}},[_vm._v(" "+_vm._s(_vm._f("date")(mutatie.datumStart))+" ")]),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedWijzeEinde(index) }},[(mutatie.wijzeEinde)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(mutatie.wijzeEinde.code)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(mutatie.wijzeEinde.omschrijving))])]):_vm._e()],1),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedDatumEinde(index) },staticStyle:{"width":"110px"}},[_vm._v(" "+_vm._s(_vm._f("date")(mutatie.datumEinde))+" ")]),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedDatumBehandeling(index) },staticStyle:{"width":"110px"}},[(mutatie.datumBehandeling)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_vm._v(" "+_vm._s(_vm._f("date")(mutatie.datumBehandeling))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(mutatie.behandelingType.omschrijving))])]):_vm._e()],1),_c('td',{class:{ 'font-weight-bold' : _vm.hasChangedKvk(index) }},[_vm._v(" "+_vm._s(mutatie.kvkDossiernummer)),_c('br'),_vm._v(" "+_vm._s(mutatie.kvkVestigingNummer)+" ")]),_c('td',{staticStyle:{"max-width":"200px"}},[(mutatie.mutatieSoort)?[_vm._v(" Soort = "+_vm._s(mutatie.mutatieSoort.replaceAll(',', ', '))+" "),_c('br')]:_vm._e(),(_vm.showVerhuisDatum(mutatie))?_c('tempalte',[_vm._v(" Verhuisdatum = "+_vm._s(_vm._f("date")(mutatie.datumVestigingHuidigAdres))),_c('br')]):_vm._e(),(_vm.showNaamMutatieDatum(mutatie))?_c('tempalte',[_vm._v(" Datum naam = "+_vm._s(_vm._f("date")(mutatie.naamMutatieDatum))),_c('br')]):_vm._e(),(_vm.showSbiMutatieDatum(mutatie))?_c('tempalte',[_vm._v(" Datum SBI = "+_vm._s(_vm._f("date")(mutatie.sbiMutatieDatum))),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(mutatie.mutatieToelichting)+" ")],2)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }