import {Idable} from '@/models/Idable';
import {IImportTemplate} from '@/models/IImportTemplate';

export interface IImportRun extends Idable {
    description: string;
    filename: string;
    status: ExportJobStatus;
    type: ExportType;
    createOn: string;
    startedOn: string;
    stoppedOn: string;
}

export enum ExportJobStatus {
    CREATED = 1,
    BUSY = 2,
    HALTED = 3,
    ERROR = 4,
    FINISHED_SUCCESS = 5,
    FINISHED_ERROR = 6,
}

export enum ExportType {
    NOMRAL = 1,
    KVK = 2,
}
