
















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IKvkUploadRun} from '@/models/IKvkUploadRun';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import Cl1NietBagConform from '@/views/kvk-upload-run/controlelijst/Cl1NietBagConform.vue';
import SbiMutatieGroteVestigingDialog from '@/views/kvk-upload-run/controlelijst/SbiMutatieGroteVestigingDialog.vue';
import NietDoorgevoerdeAfvoeringGroteVestigingDialog from '@/views/kvk-upload-run/controlelijst/NietDoorgevoerdeAfvoeringGroteVestigingDialog.vue';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
    components: {
        NietDoorgevoerdeAfvoeringGroteVestigingDialog,
        SbiMutatieGroteVestigingDialog,
        NietBagConform: Cl1NietBagConform,
        KvkUploadRunTable,
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class Cl4NietDoorgevoerdeAfvoeringGroteVestiging extends Mixins(AuthorizationMixin) {

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun;

    public selectedKvkMutatie: IKvkMutatie | null = null;
    public openModal: boolean = false;
    public refresher: number = 0;

    public get api() {
        return this.$api.kvkMutatie;
    }

    public openInfoModal(id: number) {
        this.$emit('openInfoModal', id);
    }

    private onOpenModal(kvkMutatie: IKvkMutatie) {
        this.selectedKvkMutatie = kvkMutatie;
        this.openModal = true;
    }

    private onCloseModal() {
        this.selectedKvkMutatie = null;
        this.openModal = false;
        this.refresher++;
        this.$emit('updateSize');
    }

    private onSave() {
        this.onCloseModal();
    }
}
