







import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';

@Component({
    filters: {},
    components: {SimpleMessageWrapper, AutoLoadingButton},
})
export default class Home extends Mixins(ShowsMessages) {
}
