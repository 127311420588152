



































import {Component, Mixins, Model, Prop, Vue} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import IdentificatieInfo from '@/views/vestigingen/components/IdentificatieInfo.vue';
import StartEindeInfo from '@/views/vestigingen/components/StartEindeInfo.vue';
import {buildEmptyContact, ContactType} from '@/models/IContact';
import {hasAnyRole} from '@/utils/auth';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';

@Component({
    methods: {hasAnyRole},
    filters: {},
    components: {StartEindeInfo, IdentificatieInfo, ContactCard, AdresInfo},
})
export default class VestigingAlgemeneInfo extends Mixins(AuthorizationMixin) {
    @Model('input')
    public vestiging!: IVestiging;

    @Prop({default: null})
    public oldVestiging!: IVestiging | null;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop()
    public validationErrorsCount!: number;

    public created() {
        if (!this.vestiging.contact) {
            this.vestiging.contact = buildEmptyContact(ContactType.VESTIGING_GEBOUW);
        }
        if (!this.vestiging.wpContact) {
            this.vestiging.wpContact = buildEmptyContact(ContactType.CONTACT_PERSOON);
        }
    }

    public handleInvalidInput() {
        this.$emit('invalidInput');
    }
}
