

















































































































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validation';
import {IAdres} from '@/models/IAdres';
import {Idable} from '@/models/Idable';
import DateInput from '@/components/DateInput.vue';
import {ICorrespondent} from '@/models/ICorrespondent';

@Component({
    filters: {},
    components: {DateInput},
})
export default class CorrespondentieAdresInfo extends Vue {
    @Model('input')
    public correspondent!: ICorrespondent;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop({required: false, default: 0})
    public validationErrorsCount!: number;

    public gebruiksfunctieOptions: Idable[] = [];
    public gemeenteOptions: Idable[] = [];
    public typePandOptions: Idable[] = [];
    public xyKwaliteitOptions: Idable[] = [];
    public plaatsnaamOptions: Idable[] = [];

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    public get rules() {
        return {
            naam: [required],
        };
    }

    @Watch('correspondent')
    public loadCorrespondentContact() {
        if (!this.correspondent.contact) {
            this.correspondent.contact = {
                id: '',
                email: null,
                mobiel: null,
                toestel: null,
                type: null,
                website: null,
                naam: null,
                telefoon: null,
            };
        }
    }

    public async created() {
        this.loadCorrespondentContact();
        this.gebruiksfunctieOptions = (await this.$api.gebruiksfunctie.listAll()).data?.content || [];
        this.gemeenteOptions = (await this.$api.gemeente.listAll()).data?.content || [];
        this.typePandOptions = (await this.$api.typePand.listAll()).data?.content || [];
        this.xyKwaliteitOptions = (await this.$api.xyKwaliteit.listAll()).data?.content || [];
        this.plaatsnaamOptions = (await this.$api.plaatsnaam.listAll()).data?.content || [];
    }
}
